import React, { useEffect, useState } from "react";
import SSONavigationContent from "organisms/ssoNavigationContent/SSONavigationContent";
import { t } from "i18next";
import { LINK } from "utils/constants/Constants";
import { useAuth } from "providers";
import { useEmployerEnrollInBenefit } from "@sentara/sentara-api-hooks-core";

const EmployerBenefitsEnrollment = () => {
  const { user } = useAuth();
  const { getEmployerEnrollInBenefit } = useEmployerEnrollInBenefit();
  const [benefitEnrollmentSSO, setbenefitEnrollmentSSO] = useState("");

  useEffect(() => {
    document.title = t("pageTitleADA.employerBenefitsEnrollment");
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID) {
      const response = await getEmployerEnrollInBenefit({
        loginId: user?.loginID,
      });
      if (response?.data) {
        setbenefitEnrollmentSSO(response?.data?.ssoUrl);
      }
    }
  };
  return (
    <div id="enrollInBenefitsContent">
      <SSONavigationContent
        header={t("sso.header.employerBenefitsEnrollment")}
        ssoUrl={benefitEnrollmentSSO}
        dataTestId="ssoLinkEmployerBenefitsEnrollment"
        targetName="employerPortalEmployerBenefitsEnrollment"
      />
    </div>
  );
};

export default EmployerBenefitsEnrollment;
