import { useEffect, useState } from "react";
import { t } from "i18next";
import { useAuth, useGlobalState } from "providers";
import { GlobalApiError } from "organisms/form";
import DuoComponent from "./DuoComponent";
import { useEmployerDeviceManagement } from "@sentara/sentara-api-hooks-core";

export const DeviceManagement = () => {
  const { setDmTitle } = useGlobalState();
  const { user } = useAuth();

  useEffect(() => {
    document.title = t("pageTitleADA.deviceManagement");
    setDmTitle(t("deviceManagement.deviceManagementHeading"));
  }, [setDmTitle]);

  const { getEmployerDeviceManagement } = useEmployerDeviceManagement();
  const [duoVisible, setDuoVisible] = useState(false);
  const [duoHost, setDuoHost] = useState("");
  const [duoSignRequest, setSignRequest] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    if (user) {
      deviceManagementSSO(user?.loginID);
    }
  }, [user]);

  const deviceManagementSSO = async (loginid: string) => {
    const response = await getEmployerDeviceManagement({ loginID: loginid });

    if (response?.data?.Result) {
      setDuoHost(response?.data?.Result?.host);
      setSignRequest(response?.data.Result.signRequest);
      setDuoVisible(true);
      setError({});
    } else if (response?.error) {
      setError(response);
    }
  };

  return (
    <div>
      <div>
        <div className="mb-4">{t("deviceManagement.deviceManagementDesc")}</div>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="d-flex flex-column p-4  mb-4 ">
              <GlobalApiError apiError={error} />
              {duoVisible && (
                <DuoComponent host={duoHost} signInRequest={duoSignRequest} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeviceManagement;
