import { ErrorMessage } from "@hookform/error-message";
import { ApiErrorResponse } from "@sentara/sentara-api-hooks-core";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";

type Props = {
  name: string;
  tabIndex?: number;
};

export const FormError = (props: Props) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <ErrorMessage
      errors={errors}
      name={props.name}
      render={({ message }) => (
        <div className="pt-1">
          <span className="visually-hidden">{t("error")} </span>
          <small className="text-error">{message}</small>
        </div>
      )}
    />
  );
};

export const GlobalError = (props: Props) => {
  const {
    formState: { errors, isSubmitted },
  } = useFormContext();
  return (
    <ErrorMessage
      errors={errors}
      name={props.name}
      render={({ message }) => (
        <>
          {isSubmitted && (
            <div role="alert" className="global-alert no-focus" tabIndex={props.tabIndex}>
              {message}
            </div>
          )}
        </>
      )}
    />
  );
};

export const GlobalApiError = ({
  apiError,
}: {
  apiError: ApiErrorResponse | null;
}) => {
  const refEle = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (apiError && refEle) {
      refEle.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [apiError]);
  if (apiError?.errorCode?.toString() === "404") {
    apiError.error = t("noResult");
  }
  return apiError?.error ? (
    <div
      ref={refEle}
      id="GlobalApiError"
      data-testid="errMsgGlobalApiError"
      role="alert"
      className="global-alert"
    >
      {apiError.error}
    </div>
  ) : null;
};

export const ErrorBy = ({
  isShow,
  message,
}: {
  isShow: boolean;
  message: string;
}) => {
  const refEle = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isShow && message && refEle) {
      refEle.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isShow]);

  return (
    <>
      {isShow && (
        <div
          ref={refEle}
          role="alert"
          className="alert alert-danger global-alert"
        >
          {message}
        </div>
      )}
    </>
  );
};
