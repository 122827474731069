import { t } from "i18next";
import { Headings } from "@sentaraui/optimahealth_web";

import { toPascalCase } from "../../utils/helpers/TextFormaters";
import { ProfileFirstLetter } from "../../organisms/profile";

import { MenuAccordion } from "./";
import { useAuth } from "providers";

/**
 * Sides menu
 * @returns
 */
export const SideMenu = () => {
  const { user } = useAuth();

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="heading-with-bg mb-3 mt-3" data-testid="txtProfileCardHeading">
            <strong>{t("employerLink")}</strong>
          </div>
          <div className="d-flex flex-no-wrap align-items-center mb-3">
            <div className="profile-letter-wrapper">
              <ProfileFirstLetter
                firstName={toPascalCase(user?.person?.firstName ?? "")}
                lastName={toPascalCase(user?.person?.lastName ?? "")}
              />
            </div>
            <div
              className="flex-1 profile-name-text text-break ps-4 ms-3"
              data-testid="txtUserFullName"
            >
              {toPascalCase(user?.person?.firstName ?? "")}{" "}
              {toPascalCase(user?.person?.middleName ?? "")}{" "}
              {toPascalCase(user?.person?.lastName ?? "")}
            </div>
          </div>
          <hr />

          {/* TODO: AVMED */}
          {user?.groups && (
            <div className="font-eighteen line-ht28 mb-3">
              <strong className="me-2" data-testid="lblServiceLabel">
                {user?.groups[0]?.groupName}
              </strong>
            </div>
          )}

          <MenuAccordion id="acc_side_menu" />
        </div>
      </div>
    </div>
  );
};
