import { useEffect, useState } from "react";
import { PayMonthlyInvoiceFormProps } from "../interface";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AcceptNumeric, AcceptNumericNine } from "utils/constants/Regex";

export const usePayMonthlyInvoiceForm = ({
  EFTAmount,
}: PayMonthlyInvoiceFormProps) => {
  const { t } = useTranslation();
  const { formState, getValues, watch } = useFormContext();
  const [btnEnabled, setBtnEnabled] = useState(true);
  const watchAllFields = watch();
  const { accountNumber, bankName, routingNumber } = getValues();

  useEffect(() => {
    if (
      parseFloat(EFTAmount) <= 0 ||
      parseFloat(EFTAmount) < 1 ||
      EFTAmount === "" ||
      bankName === "" ||
      accountNumber === "" ||
      routingNumber === ""
    ) {
      setBtnEnabled(false);
    } else {
      setBtnEnabled(true);
    }
  }, [EFTAmount, getValues, formState, watchAllFields]);

  const searchByValidate = () => {
    if (
      isNaN(parseFloat(EFTAmount)) ||
      parseFloat(EFTAmount) <= 0 ||
      parseFloat(EFTAmount) < 1 ||
      bankName === "" ||
      accountNumber === "" ||
      routingNumber === ""
    ) {
      setBtnEnabled(false);
      return t("payMonthlyInvoice.globalEftAmountError");
    } else {
      setBtnEnabled(true);
    }
  };

  const validateRoutingNumber = (value: string) => {
    const regex = AcceptNumericNine;

    if (!regex.test(value)) {
      return t("payMonthlyInvoice.invalidRoutingNumber");
    }
  };

  const validateBankNumber = (value: string) => {
    const regex = AcceptNumeric;
    if (!regex.test(value)) {
      return t("payMonthlyInvoice.invalidBankNumber");
    }
  };

  const errorClass = (name: string) => {
    return formState.errors[name] ? "" : "d-none";
  };

  const validClass = (name: string, type: "input" | "select") => {
    const baseClass = type === "input" ? "form-input" : "form-select";
    const errorClass = formState.errors[name] ? "error-border" : "";
    return `${baseClass} ${errorClass}`.trim();
  };

  return {
    btnEnabled,
    errorClass,
    searchByValidate,
    setBtnEnabled,
    validClass,
    validateRoutingNumber,
    validateBankNumber,
  };
};
