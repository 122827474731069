import { FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PrimaryButton, SecondaryButton } from "@sentaraui/optimahealth_web";

interface RequestMemberIDCardSubmitProps {
  handleClearForm: Function;
  formState?: FormState<any>;
}

const RequestMemberIDCardSubmit = ({
  handleClearForm,
  formState,
}: RequestMemberIDCardSubmitProps) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4">
      <PrimaryButton
        data-testid="btnRequestMemberIdCardSearch"
        id="primary"
        type="submit"
        className={
          formState?.dirtyFields["memberId"] ||
            formState?.dirtyFields["lastName"] ||
            formState?.dirtyFields["firstName"] ||
            formState?.dirtyFields["subscriberSecurityNumber"] ||
            formState?.dirtyFields["checkboxAllMembers"]
            ? "primary_btn btn_block_mob"
            : "primary_btn disabled_btn btn_block_mob"
        }
        aria-label={
          formState?.dirtyFields["memberId"] ||
            formState?.dirtyFields["lastName"] ||
            formState?.dirtyFields["firstName"] ||
            formState?.dirtyFields["subscriberSecurityNumber"] ||
            formState?.dirtyFields["checkboxAllMembers"]
            ? t("enrollmentHistoryForm.btnSearch")
            : t("enrollmentHistoryForm.btnSearchDisabled")
        }
      >
        {t("enrollmentHistoryForm.btnSearch")}
      </PrimaryButton>
      <SecondaryButton
        type="button"
        data-testid="btnRequestMemberIdCardClear"
        className={"secondary_btn btn_block_mob"}
        onClick={handleClearForm}
      >
        {t("enrollmentHistoryForm.btnClear")}
      </SecondaryButton>
    </div>
  );
};
export default RequestMemberIDCardSubmit;
