import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { DeviceTypeProps, ENVTYPES } from "../../utils/models/CommonTypes"
import { DeviceTypes } from "../../utils/constants/Constants";

interface StateContextType {
  dmTitle: string,
  setDmTitle: (v: string) => void,
  ENVTYPES: ENVTYPES,
  deviceType: DeviceTypeProps | "",
  setPageLoader: (name: string, running: boolean) => void,
  loader: Array<string> | null,
  inActiveSignOut: boolean,
  setInActiveSignOut: (v: boolean) => void,
  refreshKey: number,
  setRefreshKey: (v: number) => void,
  refreshAccordion: () => void,
}

export const GlobalStateContext = createContext<StateContextType>({} as any);
interface Prop {
  children: React.ReactNode
}

export const GlobalStateProvider = (prop: Prop) => {
  const [deviceType, setDeviceType] = useState<DeviceTypeProps | ''>('');
  const [loader, setLoader] = useState<Array<string> | null>(null)
  const [inActiveSignOut, setInActiveSignOut] = useState(false)
  const [dmTitle, setDmTitle] = useState<string>('')
  const [refreshKey, setRefreshKey] = useState(0);
  const ENVTYPES = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    // FUTURE: Update this once APIM URLs is ready for EMployer master details API.
    // BASE_URL: "https://virtserver.swaggerhub.com/PCHINTAMANENI/EmployerPortalAPI/1.0.0",
    OPC_APIM_SUB_KEY: process.env.REACT_APP_OPC_APIM_SUB_KEY
  } as ENVTYPES;

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setDeviceType(DeviceTypes.DESKTOP);
    } else if (window.innerWidth >= 768) {
      setDeviceType(DeviceTypes.TABLET);
    } else {
      setDeviceType(DeviceTypes.MOBILE);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setPageLoader = (name: string, running: boolean) => {
    const _loaders = loader ? [...loader] : []
    if (running) {
      _loaders.push(name)
    } else {
      _loaders.splice(_loaders.indexOf(name), 1);
    }
    setLoader(_loaders.length ? _loaders : null)
  }

  const refreshAccordion = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  const value = useMemo(() => (
    {
      dmTitle,
      setDmTitle,
      ENVTYPES,
      deviceType,
      setPageLoader,
      loader,
      inActiveSignOut,
      setInActiveSignOut,
      refreshKey,
      setRefreshKey,
      refreshAccordion,
    }
  ), [ENVTYPES, loader, deviceType, inActiveSignOut])

  return <GlobalStateContext.Provider value={value}>{prop.children}</GlobalStateContext.Provider>;
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
