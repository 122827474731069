import { useTranslation } from "react-i18next";
import {
  SelectDropdown,
  InputField,
  PrimaryButton,
} from "@sentaraui/optimahealth_web";
import { FormError } from "../../organisms/form";
import { useFormContext } from "react-hook-form";
import { PayMonthlyInvoiceFormProps } from "./interface";
import { usePayMonthlyInvoiceForm } from "./hooks/usePayMonthlyInvoiceForm";
import { RemoveSpace } from "utils/helpers/HelperFn";

const PayMonthlyInvoiceForm = (props: PayMonthlyInvoiceFormProps) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const {
    btnEnabled,
    errorClass,
    searchByValidate,
    validClass,
    validateRoutingNumber,
    validateBankNumber,
  } = usePayMonthlyInvoiceForm(props);
  const required = (
    <strong className="text-danger ps-1" aria-label={t("required")}>
      *
    </strong>
  );

  register("searchInput", { validate: searchByValidate });
  return (
    <div>
      <div className="mt-3 mb-3">
        <strong className="text-danger ps-1">*</strong> Indicates Required
        Fields
      </div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="id_name_on_bank_account"
              data-testid="labelBankName"
            >
              {t("payMonthlyInvoice.bankName")}
              {required}
            </label>
            <div>
              <InputField
                type="text"
                {...register("bankName", {
                  required: t("payMonthlyInvoice.validationBankName"),
                  onChange(event) {
                    RemoveSpace(event);
                  },
                })}
                className={validClass("bankName", "input")}
                dataTestId="inputBankName"
                id="id_bankName"
                aria-describedby="bankName_error"
                placeholder={t("payMonthlyInvoice.plcBankName")}
              />
              <div className={errorClass("bankName")}>
                <div id="bankName_error" aria-live="polite" aria-atomic="true">
                  <FormError name="bankName" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="id_routingNumber"
              data-testid="lblroutingNumber"
            >
              {t("payMonthlyInvoice.bankRoutingNumber")}
              {required}
            </label>
            <div>
              <InputField
                type="text"
                {...register("routingNumber", {
                  required: t("payMonthlyInvoice.validationRoutingNumber"),
                  validate: (value: string) => validateRoutingNumber(value),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                  },
                })}
                className={validClass("routingNumber", "input")}
                dataTestId="inputroutingNumber"
                id="id_routingNumber"
                aria-describedby="routingNumber_error"
                placeholder={t("payMonthlyInvoice.plcRoutingNumber")}
              />
              <div className={errorClass("routingNumber")}>
                <div
                  id="routingNumber_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="routingNumber" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="id_accountNumber"
              data-testid="lblaccountNumber"
            >
              {t("payMonthlyInvoice.accountNumber")}
              {required}
            </label>
            <div>
              <InputField
                type="text"
                {...register("accountNumber", {
                  required: t("payMonthlyInvoice.validationAccountNumber"),
                  validate: (value: string) => validateBankNumber(value),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                  },
                })}
                className={validClass("accountNumber", "input")}
                dataTestId="inputaccountNumber"
                id="id_accountNumber"
                aria-describedby="accountNumber_error"
                placeholder={t("payMonthlyInvoice.plcAccountNumber")}
              />
              <div className={errorClass("accountNumber")}>
                <div
                  id="accountNumberr_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="accountNumber" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="id_accountType"
              data-testid="lblaccountType"
            >
              {t("payMonthlyInvoice.accountType")}
              {required}
            </label>
            <div>
              <SelectDropdown
                className={validClass("accountType", "select")}
                dataTestId="optaccountType"
                id="accountType"
                {...register("accountType", {})}
              >
                <option value={"Checking"}>
                  {t("payMonthlyInvoice.checking")}
                </option>
                <option value={"Savings"}>
                  {t("payMonthlyInvoice.savings")}
                </option>
              </SelectDropdown>
              <div className={errorClass("accountType")}>
                <div
                  id="accountType_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="accountType" />
                </div>
              </div>
            </div>
          </div>
          <hr className=" mb-4" />

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="id_totalEFTDraftAmount"
              data-testid="lbltotalEFTDraftAmount"
            >
              {t("payMonthlyInvoice.totalEFTAmount")}
            </label>
            <div>${props.EFTAmount}</div>
          </div>

          <div className="mb-4">
            <label className="pb-1" htmlFor="id_note" data-testid="lblnote">
              {t("payMonthlyInvoice.note")}
            </label>
            <div>
              <textarea
                className={validClass("note", "input")}
                rows={9}
                maxLength={100}
                id="inputNote"
                {...register("note", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                  },
                })}
                data-testid="inputnote"
                aria-describedby="question_error"
                placeholder={t("payMonthlyInvoice.plcNote")}
              />
              <div className={errorClass("note")}>
                <div id="note_error" aria-live="polite" aria-atomic="true">
                  <FormError name="note" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4 pb-2">
        <PrimaryButton
          data-testid="btnPaymentContinue"
          id="primary"
          type="submit"
          className={
            !btnEnabled || props.isInvalidInputErrorExist
              ? "primary_btn disabled_btn btn_block_mob"
              : "primary_btn btn_block_mob"
          }
          aria-label={
            !btnEnabled || props.isInvalidInputErrorExist
              ? t("account.label.continueDisabled")
              : t("account.label.continue")
          }
          aria-describedby="eft_amount_zero eft_draft_amount_error eft_draft_amount_error_mob"
        >
          {t("payMonthlyInvoice.continueBtn")}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default PayMonthlyInvoiceForm;
