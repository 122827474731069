import { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_THEME, ThemeProvider } from "@sentaraui/optimahealth_web";

import { AVMED_THEME, BRAND_CONFIG } from "../../utils/constants/BrandConfig";
import { LOCALETYPE, BrandType } from "../../utils/models/CommonTypes";

export enum Brands {
  AVMED = "AVMED",
  DEFAULT = "DEFAULT",
}

type BrandProviderType = {
  brand: Brands;
  brandConfig: BrandType;
};

interface Props {
  children?: React.ReactNode;
}

export const BrandingContext = createContext<BrandProviderType>({
  brand: Brands.DEFAULT,
  brandConfig: {},
} as any);

const isBrand = (brand: Brands) => {
  const hostName = window.location.hostname;
  return BRAND_CONFIG[brand].hostNames?.includes(hostName);
};

export const BrandingProvider = ({ children }: Props) => {
  const { i18n } = useTranslation();
  const theme = isBrand(Brands.AVMED) ? AVMED_THEME : DEFAULT_THEME;
  const brand = isBrand(Brands.AVMED) ? Brands.AVMED : Brands.DEFAULT;
  const brandConfig = BRAND_CONFIG[brand];
  i18n.changeLanguage(
    isBrand(Brands.AVMED) ? LOCALETYPE.ENAVMED : LOCALETYPE.ENSENTARA
  );

  const value = useMemo(
    () => ({
      brandConfig,
      brand,
    }),
    [brand, brandConfig]
  );

  const themeValue = useMemo(
    () => ({
      theme,
    }),
    [theme]
  );

  return (
    <BrandingContext.Provider value={value}>
      <ThemeProvider theme={themeValue.theme}>{children}</ThemeProvider>
    </BrandingContext.Provider>
  );
};

export const useBranding = () => {
  return useContext(BrandingContext);
};
