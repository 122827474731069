import React from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { LINK, TilesIds } from 'utils/constants/Constants';
import {
    GroupInfoIcon,
    EnrollmentHistoryIcon,
    IdCardIcon,
    ClaimsIcon,
    BillingStatementIcon,
    SupportIcon,
} from "@sentaraui/optimahealth_web";
import { BILLING_STATEMENTS, ENROLLMENT_HISTORY, GROUP_INFORMATION, PAY_MONTHLY_INVOICE, REQUEST_MEMBER_ID_CARD } from 'routes';
import { useBranding, useGlobalState } from 'providers';
interface Tile {
    name: string;
    icon: React.JSX.Element;
    id: string;
    route?: string;
    link?: string;
}

export const HomeTiles = () => {
    const { brandConfig } = useBranding();
    const { refreshAccordion } = useGlobalState();
    const navigate = useNavigate();

    const tileActions = (tile: Tile) => {
        if (tile.route) {
            navigate(`/app/${tile.route}`);
            refreshAccordion();
        }
        if (tile.link) window.open(tile.link, '_blank');
    };

    const allTiles: Tile[] = [
        {
            id: TilesIds.Title1,
            name: t('nav.tile.groupInformation'),
            icon: <GroupInfoIcon />,
            route: GROUP_INFORMATION,
        },
        {
            id: TilesIds.Title2,
            name: t('nav.tile.enrollmentHistory'),
            icon: <EnrollmentHistoryIcon />,
            route: ENROLLMENT_HISTORY,
        },
        {
            id: TilesIds.Title3,
            name: t('nav.tile.memberIdCard'),
            icon: <IdCardIcon />,
            route: REQUEST_MEMBER_ID_CARD,
        },
        {
            id: TilesIds.Title4,
            name: t('nav.tile.payMonthlyInvoice'),
            icon: <ClaimsIcon />,
            route: PAY_MONTHLY_INVOICE,
        },
        {
            id: TilesIds.Title5,
            name: t('nav.tile.billingStatement'),
            icon: <BillingStatementIcon />,
            route: BILLING_STATEMENTS,
        },
        {
            id: TilesIds.Title6,
            name: t('nav.tile.support'),
            icon: <SupportIcon />,
            link: brandConfig.link.supportTileLink,
        },
    ];

    return (
        <>
            {allTiles?.length > 0 &&
                allTiles.map((item: Tile) => {
                    return (
                        <button
                            onClick={() => tileActions(item)}
                            data-testid={`${item.id}`}
                            className="grid-col d-flex flex-column justify-content-center align-items-center bg-transparent home-tile-link"
                            key={item.id}
                        >
                            <span>{item.icon}</span>
                            {item.name}
                        </button>
                    );
                })}
        </>
    );
};

export default HomeTiles;