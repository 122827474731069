import { useTranslation } from "react-i18next";
import { InputField, AccordionTable } from "@sentaraui/optimahealth_web";
import PayMonthlyInvoiceForm from "./PayMonthlyInvoiceForm";
import { Form, ErrorBy } from "organisms/form";
import PayMonthlyInvoiceNotes from "./PayMonthlyInvoiceNotes";
import { PayMonthlyInvoicePaymentProps } from "./interface";
import { usePayMonthlyInvoicePayment } from "./hooks/usePayMonthlyInvoicePayment";
import { useGlobalState } from "providers";
import {
  DeviceTypes,
  PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE,
} from "utils/constants/Constants";

const PayMonthlyInvoicePayment = (props: PayMonthlyInvoicePaymentProps) => {
  const { t } = useTranslation();
  const {
    defaultValues,
    eftAmount,
    handleInputChange,
    onSubmit,
    isInvalidInputErrorExist,
    formatOnBlur,
  } = usePayMonthlyInvoicePayment(props);

  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <div>
      <div id="eft_amount_zero" aria-live="polite">
        <ErrorBy
          isShow={+eftAmount < 1}
          message={t("payMonthlyInvoice.globalEftAmountError")}
        />
      </div>

      <div className="table-responsive">
        {!isMobile && (
          <table
            className="table table-custom-style align-middle payment-invoice-table"
            data-testid="tblPayMonthlyInvoice"
          >
            <thead>
              <tr>
                <th scope="col">{t("payMonthlyInvoice.paymentMonth")}</th>
                <th scope="col">{t("payMonthlyInvoice.groupNumber")}</th>
                <th scope="col">{t("payMonthlyInvoice.groupName")}</th>
                <th scope="col">{t("payMonthlyInvoice.invoiceNumber")}</th>
                <th scope="col">{t("payMonthlyInvoice.totalDue")}</th>
                <th scope="col">{t("payMonthlyInvoice.outstandingBalance")}</th>
                <th scope="col">{t("payMonthlyInvoice.eftDraftAmt")}</th>
              </tr>
            </thead>
            <tbody>
              {props.selectedRecordsTopLevel?.map((selectedRecord, indx) => (
                <tr key={selectedRecord.sequenceNo + indx}>
                  <td>{selectedRecord.invoiceDate}</td>
                  <td>{selectedRecord.groupID}</td>
                  <td>{selectedRecord.groupName}</td>
                  <td>{selectedRecord.invoice}</td>
                  <td>
                    {+selectedRecord.invoiceAmt < 0
                      ? `($${selectedRecord.invoiceAmt.replace("-", "")})`
                      : `$${selectedRecord.invoiceAmt}`}
                  </td>
                  <td>
                    {+selectedRecord.invoiceBal < 0
                      ? `($${selectedRecord.invoiceBal.replace("-", "")})`
                      : `$${selectedRecord.invoiceBal}`}
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-1">
                      $
                      <InputField
                        // id={`chkOrderMemberIdCardTable-${1}`}
                        className={
                          selectedRecord.inputValidationError
                            ? "form-input error-border"
                            : "form-input"
                        }
                        type="text"
                        dataTestId={"chkPayMonthlyInvoiceTableRowSearchData"}
                        disabled={!selectedRecord.isInvoicePayable || parseInt(selectedRecord.invoiceBal, 10) === 0}
                        defaultValue={selectedRecord.etfDraftAmount}
                        onChange={(evt: any) => {
                          handleInputChange(evt, indx);
                        }}
                        onBlur={(evt: any) => {
                          formatOnBlur(evt);
                        }}
                        aria-describedby="eft_draft_amount_error"
                      />
                    </div>

                    <div className="ps-2 ms-1 pt-1 text-danger" id="eft_draft_amount_error" aria-live="polite">
                      {selectedRecord.inputValidationError ===
                        PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.INVALID_INPUT && (
                          <div>{t("payMonthlyInvoice.paymentAmountNotValid")}</div>
                        )}
                      {selectedRecord.inputValidationError ===
                        PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.OVER_PAYMENT && (
                          <div>{t("payMonthlyInvoice.overPaymentsNotPermitted")}</div>
                        )}
                      {selectedRecord.inputValidationError ===
                        PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.VALUE_LESS_THAN_0 && (
                          <div>{t("payMonthlyInvoice.paymentAmountNotValid")}</div>
                        )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {isMobile && (
          <div className="mb-4">
            <AccordionTable
              initialActiveIndex={0}
              id="pay_monthly_invoice_payment_detail_table"
              items={props.selectedRecordsTopLevel?.map(
                (selectedRecord, indx) => ({
                  title: (
                    <div>
                      {selectedRecord.invoiceDate ? selectedRecord.invoiceDate : t("payMonthlyInvoice.noOpenInv")}
                    </div>
                  ),
                  id: "enrollment_history_view_detail_table_data",
                  content: (
                    <>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.groupNumber")}</strong></div>
                        <div>{selectedRecord.groupID}</div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.groupName")}</strong></div>
                        <div>{selectedRecord.groupName}</div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.invoiceNumber")}</strong></div>
                        <div>{selectedRecord.invoice}</div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.totalDue")}</strong></div>
                        <div>
                          {+selectedRecord.invoiceAmt < 0
                            ? `($${selectedRecord.invoiceAmt.replace("-", "")})`
                            : `$${selectedRecord.invoiceAmt}`}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.outstandingBalance")}</strong></div>
                        <div>
                          {+selectedRecord.invoiceBal < 0
                            ? `($${selectedRecord.invoiceBal.replace("-", "")})`
                            : `$${selectedRecord.invoiceBal}`}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.eftDraftAmt")}</strong></div>
                        <div className="mt-2">
                          <InputField
                            // id={`chkOrderMemberIdCardTable-${1}`}
                            className={
                              selectedRecord.inputValidationError
                                ? "form-input error-border"
                                : "form-input"
                            }
                            type="text"
                            dataTestId={
                              "chkPayMonthlyInvoiceTableRowSearchData"
                            }
                            disabled={
                              !selectedRecord.isInvoicePayable || parseInt(selectedRecord.invoiceBal, 10) === 0
                            }
                            defaultValue={selectedRecord.etfDraftAmount}
                            onChange={(evt: any) => {
                              handleInputChange(evt, indx);
                            }}
                            onBlur={(evt: any) => {
                              formatOnBlur(evt);
                            }}
                            aria-describedby="eft_draft_amount_error_mob"
                          />
                        </div>
                        <div className="text-danger mt-1" id="eft_draft_amount_error_mob" aria-live="polite">
                          {selectedRecord.inputValidationError ===
                            PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.INVALID_INPUT && (
                              <div>{t("payMonthlyInvoice.paymentAmountNotValid")}</div>
                            )}
                          {selectedRecord.inputValidationError ===
                            PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.OVER_PAYMENT && (
                              <div>{t("payMonthlyInvoice.overPaymentsNotPermitted")}</div>
                            )}
                          {selectedRecord.inputValidationError ===
                            PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.VALUE_LESS_THAN_0 && (
                              <div>{t("payMonthlyInvoice.paymentAmountNotValid")}</div>
                            )}
                        </div>
                      </div>
                    </>
                  ),
                })
              )}
            />
          </div>
        )}
      </div>
      <Form defaultValues={defaultValues} onSubmit={onSubmit}>
        <PayMonthlyInvoiceForm
          EFTAmount={eftAmount}
          isInvalidInputErrorExist={isInvalidInputErrorExist}
        />
      </Form>
      <PayMonthlyInvoiceNotes />
    </div>
  );
};
export default PayMonthlyInvoicePayment;
