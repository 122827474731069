import { Headings } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";

const PayMonthlyInvoiceHeader = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Headings
        className="fw-600 mb-4"
        level={1}
        text={t("payMonthlyInvoice.header")}
        dataTestId="txtPayMonthlyInvoicePageHeading"
      />
    </div>
  );
};
export default PayMonthlyInvoiceHeader;
