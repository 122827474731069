import { useEffect } from "react";

interface DuoProps {
  host: any;
  signInRequest: any;
}

const DuoComponent = ({ host, signInRequest }: DuoProps) => {
  useEffect(() => {
    const scriptDuo = document.createElement("script");
    scriptDuo.src =
      "https://api.duosecurity.com/frame/hosted/Duo-Web-v2.min.js";
    scriptDuo.async = true;
    scriptDuo.onload = () => {
      if ((window as any).Duo) {
        (window as any).Duo.init({
          host: host,
          sig_request: signInRequest,
        });
      }
    };

    document.body.appendChild(scriptDuo);

    return () => {
      document.body.removeChild(scriptDuo);
    };
  }, []);

  return (
    <iframe
      title="device_management"
      id="duo_iframe"
      data-testid="duoIframe"
      className="duo-iframe"
    ></iframe>
  );
};

export default DuoComponent;
