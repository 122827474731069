import { SelectDropdown, InputField } from "@sentaraui/optimahealth_web";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormError, GlobalError } from "../../organisms/form";

import {
  NameRegex,
  NumericAsterisks,
  SSNRegex,
  SubscriberIDRegex,
} from "utils/constants/Regex";
import { NameChange, ssnOncheck } from "utils/helpers/HelperFn";
import { useAuth } from "providers";
import { subscriberMinLength } from "utils/constants/Constants";
import { useNavigate } from "react-router";
import { REQUEST_MEMBER_ID_CARD } from "routes";
import RequestMemberIDCardSubmit from "./RequestMemberIDCardSubmit";
import { useState } from "react";

interface RequestMemberIDCardFormProps {
  setError: Function;
}

function RequestMemberIDCardForm({ setError }: RequestMemberIDCardFormProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedGroupID, setSelectedGroupID] = useState('');

  const { register, reset, trigger, formState, getValues, resetField, setFocus } =
    useFormContext();
  //For reseting the form
  const handleClearForm = () => {
    reset({
      groupId: "",
      memberId: "",
      subscriberSecurityNumber: "",
      firstName: "",
      lastName: "",
      checkboxAllMembers: "",
    });
    setError(false);
    navigate(`/app/${REQUEST_MEMBER_ID_CARD}`);
    setSelectedGroupID('');
  };
  const validateFirstName = () => {
    if (getValues("firstName") && getValues("lastName") === "") {
      return t("requestMemberIDCard.validationLastname");
    }
  };

  register("lastName", { validate: validateFirstName });

  const searachByValdate = () => {
    if (
      !getValues("memberId") &&
      !getValues("subscriberSecurityNumber") &&
      !getValues("lastName") &&
      !getValues("checkboxAllMembers")
    ) {
      return t("requestMemberIDCard.atleastOneCriteria");
    }
  };

  register("searchInput", { validate: searachByValdate });

  return (
    <div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="mb-3">
            <GlobalError name="searchInput" tabIndex={-1} />
          </div>
          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_group_id"
              data-testid="lblOrderMemberIdCard"
            >
              {t("requestMemberIDCard.selectGroup")}
            </label>
            <div>
              <SelectDropdown
                {...register("groupId", {
                  onChange: () => {
                    trigger("searchInput");
                  },
                })}
                className={
                  formState.errors.groupId?.message
                    ? "form-select error-input-bdr"
                    : "form-select"
                }
                value={selectedGroupID}
                onChange={(e: any) => setSelectedGroupID(e.target.value)}
                dataTestId="optOrderMemberIdCardGroupList"
                id="order_id_group_id"
                aria-describedby="order_id_group_id_error"
              >
                <option value="">{t("requestMemberIDCard.allgroups")}</option>
                {user?.groups?.map((item) => {
                  return (
                    <option key={`${item.groupID}`} value={item.groupID}>
                      {item.groupID} {item.groupName}
                    </option>
                  );
                })}
              </SelectDropdown>
              <div
                className={formState.errors.groupId?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_group_id_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="groupId" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_member_id"
              data-testid="lblOrderMemberIdCardSubscriberId"
            >
              {t("requestMemberIDCard.memberIDNumber")}
            </label>
            <div>
              <InputField
                maxLength={20}
                pattern={NumericAsterisks}
                {...register("memberId", {
                  minLength: {
                    value: subscriberMinLength,
                    message: t("requestMemberIDCard.minimumMemberID"),
                  },

                  onChange: (e) => {
                    trigger("searchInput");
                    resetField("subscriberSecurityNumber");
                    resetField("firstName");
                    resetField("lastName");

                    setError(false);
                  },
                  pattern: {
                    value: SubscriberIDRegex,
                    message: t("requestMemberIDCard.validationSubscriberID"),
                  },
                })}
                className={
                  formState.errors.memberId?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("enrollmentHistoryForm.plcHolderMemberId")}
                dataTestId="inputOrderMemberIdCardSubscriberId"
                id="order_id_member_id"
                aria-describedby="order_id_member_id_error"
              />
              <div
                className={formState.errors.memberId?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_security_no_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="memberId" />
                </div>
              </div>
            </div>
          </div>

          <div className="pos-rel pt-2">
            <hr className="m-0" />
            <strong className="or-separator">
              {t("requestMemberIDCard.or")}
            </strong>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_security_no"
              data-testid="lblOrderMemberIdCardSubscriberSSN"
            >
              {t("requestMemberIDCard.sssn")}
            </label>
            <div>
              <InputField
                maxLength={9}
                type="text"
                {...register("subscriberSecurityNumber", {
                  setValueAs: (v) => ssnOncheck(v),
                  onChange: (e) => {
                    e.target.value = ssnOncheck(e.target.value);
                    trigger("searchInput");
                    resetField("memberId");
                    resetField("firstName");
                    resetField("lastName");
                    setError(false);
                  },
                  pattern: {
                    value: SSNRegex,
                    message: t("requestMemberIDCard.validationSSN"),
                  },
                })}
                forceNumber={true}
                className={
                  formState.errors.subscriberSecurityNumber?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("requestMemberIDCard.plcHolderSSN")}
                dataTestId="inputOrderMemberIdCardSubscriberSSN"
                id="order_id_security_no"
                aria-describedby="order_id_security_no_error"
              />
              <div
                className={
                  formState.errors.subscriberSecurityNumber?.message
                    ? ""
                    : "d-none"
                }
              >
                <div
                  id="order_id_security_no_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="subscriberSecurityNumber" />
                </div>
              </div>
            </div>
          </div>

          <div className="pos-rel pt-2">
            <hr className="m-0" />
            <strong className="or-separator">
              {t("requestMemberIDCard.or")}
            </strong>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_last_name"
              data-testid="lblOrderMemberIdCardSubscriberLastName"
            >
              {t("requestMemberIDCard.memberLastName")}
            </label>
            <div>
              <InputField
                pattern={NameRegex}
                maxLength={20}
                {...register("lastName", {
                  onChange: (e) => {
                    NameChange(e);
                    trigger("lastName");
                    trigger("searchInput");
                    resetField("memberId");
                    resetField("subscriberSecurityNumber");
                    setError(false);
                  },
                })}
                className={
                  formState.errors.lastName?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("requestMemberIDCard.plcLastName")}
                dataTestId="inputOrderMemberIdCardSubscriberLastName"
                id="order_id_last_name"
                aria-describedby="order_id_last_name_error"
              />
              <div
                className={formState.errors.lastName?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_last_name_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="lastName" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_first_name"
              data-testid="lblOrderMemberIdCardSubscriberFirstName"
            >
              {t("requestMemberIDCard.memberFirstName")}
            </label>
            <div>
              <InputField
                pattern={NameRegex}
                maxLength={20}
                type="text"
                forceChar={true}
                {...register("firstName", {
                  onChange: (e) => {
                    NameChange(e);
                    trigger("lastName");
                    resetField("memberId");
                    resetField("subscriberSecurityNumber");
                    setError(false);
                  },
                })}
                className={
                  formState.errors.firstName?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("requestMemberIDCard.plcFirstName")}
                dataTestId="inputOrderMemberIdCardSubscriberFirstName"
                id="order_id_first_name"
                aria-describedby="order_id_first_name_error"
              />
              <div
                className={formState.errors.firstName?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_first_name_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="firstName" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="d-flex align-items-center mb-2">
          <InputField
            className="form-check-input mt-0 p-0"
            type="checkbox"
            id={"checkboxid"}
            {...register("checkboxAllMembers", {
              onChange: (e) => {
                trigger("searchInput");
              },
            })}
            data-testid={"chkOrderMemberIdCard100orLess"}
          />
          <label
            className="check-label-text check-label-pad"
            htmlFor={"checkboxid"}
          >
            {t("requestMemberIDCard.note")}
          </label>
        </div>
      </div>
      <hr className="mt-0 mb-4" />
      <RequestMemberIDCardSubmit
        handleClearForm={handleClearForm}
        formState={formState}
      />
    </div>
  );
}

export default RequestMemberIDCardForm;
