import React, { ChangeEvent, useEffect, useState } from "react";
import { t } from "i18next";
import {
  Headings,
  SelectDropdown,
  PrimaryButton,
} from "@sentaraui/optimahealth_web";
import { Link } from "react-router-dom";
import { useAuth, useGlobalState } from "providers";
import {
  EmployerGroupInformationRequest,
  useEmployerGroupInformation,
  useBenefitSummaryUSBC,
  useEmployerBenefitSummaryEOC,
  EmployerBenefitSummaryEOCRequest,
  EmployerInsuranceGroupFileType,
  useEmployerBenefitSummarySOB,
  BenefitSummaryUSBCResponseItem,
} from "@sentara/sentara-api-hooks-core";
import {
  formatISODateToMDY,
  formatISODateToISO,
} from "utils/helpers/DateFormate";
import { useGroupInformation } from "./hooks/useGroupInformation";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";

const GroupInformation = () => {
  const { user } = useAuth();
  const { getEmployerGroupInformation } = useEmployerGroupInformation();
  const { getBenefitSummaryUSBC } = useBenefitSummaryUSBC();
  const { getEmployerBenefitSummarySOB } = useEmployerBenefitSummarySOB();

  const [dropdownValue, setDropdownValue] = useState<string>(
    user?.groups?.[0]?.groupID ?? ""
  );
  const [groupInformation, setGroupInformation] = useState<any>(null);

  const {
    handleViewButtonClick,
    groupPolicyInfoFileURL,
    handleBtnBeneficiaryUSBCFileClick,
    setGroupPolicyInfoFileURL,
    showBtnGroupPolicyInfoFile,
    setShowBtnGroupPolicyInfoFile,
    contractAmendmentFileURL,
    setContractAmendmentFileURL,
    showBtnContractAmendment,
    setShowBtnContractAmendment,
    visibleSOB,
    setVisibleSOB,
    sobFileUrl,
    setSobFileUrl,
    openInfo,
    setOpenInfo,
  } = useGroupInformation();

  // FUTURE: May be rename this function to be a common name as its used for G/GA/SOB
  const { getEmployerBenefitSummaryEOC } = useEmployerBenefitSummaryEOC();
  const [visibleUSBC, setVisibleUSBC] = useState(false);
  const [effDateUSBC, setEffDateUSBC] = useState<
    BenefitSummaryUSBCResponseItem[]
  >([]);

  const { setPageLoader } = useGlobalState();

  useEffect(() => {
    document.title = t("pageTitleADA.groupInformation");
    fetchGroupInformation();
  }, [dropdownValue]);

  const fetchGroupInformation = async () => {
    setPageLoader("getEmployerGroupInformation", true);
    const request: EmployerGroupInformationRequest = {
      groupID: dropdownValue,
    };
    if (dropdownValue) {
      try {
        const response = await getEmployerGroupInformation(request);
        if (response.data) {
          setGroupInformation(response.data);
        }
        setPageLoader("getEmployerGroupInformation", false);
      } catch (error) {
        console.error("Error fetching employer group information:", error);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDropdownValue(e.target.value);
  };

  const setValueUSBC = () => {
    setVisibleUSBC(false);
    setEffDateUSBC([]);
  };
  useEffect(() => {
    const paramsBenefitSummaryUSBC = {
      groupID: groupInformation?.groupID.trim(),
      effDate: formatISODateToISO(groupInformation?.effectiveDate),
    };

    const paramsBenefitSummarySOB = {
      ...paramsBenefitSummaryUSBC,
      url: groupInformation?.summaryOfBenefitsURL,
    };

    if (groupInformation?.groupID) {
      setPageLoader("getEmployerBenefitSummarySOB", true);
      getBenefitSummaryUSBC(paramsBenefitSummaryUSBC).then((response) => {
        if (response.errorCode === "400") {
          setValueUSBC();
        } else if (response?.data?.length) {
          setEffDateUSBC(response?.data);
          setVisibleUSBC(true);
        } else {
          setValueUSBC();
        }
      });
      getEmployerBenefitSummarySOB(paramsBenefitSummarySOB).then((response) => {
        setPageLoader("getEmployerBenefitSummarySOB", false);
        if (response?.data?.fileExists) {
          setVisibleSOB(true);
          setSobFileUrl(response.data.fileURL);
        } else {
          setVisibleSOB(false);
          setSobFileUrl("");
        }
      });

      const beniSummEOCParams: EmployerBenefitSummaryEOCRequest = {
        fileType: EmployerInsuranceGroupFileType.G,
        groupID: groupInformation?.groupID.trim(),
      };
      setPageLoader("getEmployerBenefitSummaryEOC", true);
      getEmployerBenefitSummaryEOC(beniSummEOCParams).then((response) => {
        setPageLoader("getEmployerBenefitSummaryEOC", false);
        if (response?.data?.fileExists) {
          // set file url
          setGroupPolicyInfoFileURL(response.data.fileURL);
          // show insurance group file btn
          setShowBtnGroupPolicyInfoFile(true);
        } else {
          // Do not set file path
          setGroupPolicyInfoFileURL("");
          // Hide insurance group file btn
          setShowBtnGroupPolicyInfoFile(false);
        }
      });

      const contractAmendmentEOCParams: EmployerBenefitSummaryEOCRequest = {
        fileType: EmployerInsuranceGroupFileType.GA,
        groupID: groupInformation?.groupID.trim(),
      };
      setPageLoader("getCcontractAmendmentEOC", true);
      getEmployerBenefitSummaryEOC(contractAmendmentEOCParams).then(
        (response) => {
          setPageLoader("getCcontractAmendmentEOC", false);
          if (response?.data?.fileExists) {
            // set file url
            setContractAmendmentFileURL(response.data.fileURL);
            // show insurance group file btn
            setShowBtnContractAmendment(true);
          } else {
            // Do not set file path
            setContractAmendmentFileURL("");
            // Hide insurance group file btn
            setShowBtnContractAmendment(false);
          }
        }
      );
    }
  }, [groupInformation]);

  return (
    <div id="groupInfoContent">
      <ModalOverlayWithOutClose
        open={openInfo.open}
        callBack={() => setOpenInfo({ open: false })}
        title={openInfo.title}
        content={openInfo.content}
      />
      <Headings
        className="mb-4"
        level={1}
        text={t("groupInformation.header")}
        dataTestId="txtMemberEligibilityPageHeading"
      />
      {user?.groups && user.groups.length > 1 && (
        <div className="row mb-4">
          <div className="col-lg-7 col-md-8 col-sm-12 col-12">
            <label
              className="redhat_bold pb-1"
              htmlFor="selectAGroup"
              data-testid="lblGroupNumber"
            >
              {t("groupInformation.selectAnotherGroup")}
            </label>
            <div>
              <SelectDropdown
                dataTestId="optSelectAGroup"
                className="form-select"
                id="selectAGroup"
                onChange={handleChange}
              >
                {user.groups.map((item) => (
                  <option key={`${item.groupID}`} value={item.groupID}>
                    {item.groupID} {item.groupName}
                  </option>
                ))}
              </SelectDropdown>
            </div>
          </div>
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-custom-style group-info-table table-column-50 align-middle">
          <tbody>
            <tr>
              <td>
                <strong>{t("groupInformation.employerGroupName")}:</strong>
              </td>
              <td>{groupInformation?.name}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("groupInformation.employerGroupNumber")}:</strong>
              </td>
              <td>{groupInformation?.groupID}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("groupInformation.healthPlanType")}:</strong>
              </td>
              <td>{groupInformation?.lOBType}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("groupInformation.beginningCoverageDate")}:</strong>
              </td>
              <td>{formatISODateToMDY(groupInformation?.effectiveDate)}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("groupInformation.renewalDate")}:</strong>
              </td>
              <td>{formatISODateToMDY(groupInformation?.renewalDate)}</td>
            </tr>
            {visibleSOB && (
              <tr>
                <td>
                  <strong>{t("groupInformation.benefitSummary")}:</strong>
                </td>
                <td className="mt-2 mt-lg-0 mt-md-0">
                  <PrimaryButton
                    data-testid={"benefitSummaryView"}
                    type="button"
                    className={"primary_btn btn-medium btn-w-134 btn_block_mob"}
                    onClick={() => {
                      if (sobFileUrl) {
                        handleViewButtonClick(
                          EmployerInsuranceGroupFileType.SOB,
                          sobFileUrl
                        );
                      } else {
                        setOpenInfo({
                          open: true,
                          content: t("featureUnavailable"),
                        });
                      }
                    }}
                  >
                    <span className="visually-hidden pe-2">
                      {t("groupInformation.benefitSummary")}
                    </span>
                    {t("enrollmentHistoryForm.view")}
                  </PrimaryButton>
                </td>
              </tr>
            )}
            {showBtnGroupPolicyInfoFile && (
              <tr>
                <td>
                  <strong>{t("groupInformation.groupPolicyInfo")}:</strong>
                </td>
                <td className="mt-2 mt-lg-0 mt-md-0">
                  <PrimaryButton
                    data-testid={"benefitSummaryView"}
                    type="button"
                    className={"primary_btn btn-medium btn-w-134 btn_block_mob"}
                    onClick={() => {
                      if (groupPolicyInfoFileURL) {
                        handleViewButtonClick(
                          EmployerInsuranceGroupFileType.G,
                          groupPolicyInfoFileURL
                        );
                      } else {
                        setOpenInfo({
                          open: true,
                          content: t("featureUnavailable"),
                        });
                      }
                    }}
                  >
                    <span className="visually-hidden pe-2">
                      {t("groupInformation.groupPolicyInfo")}
                    </span>
                    {t("enrollmentHistoryForm.view")}
                  </PrimaryButton>
                </td>
              </tr>
            )}
            {visibleUSBC && (
              <tr>
                <td>
                  <strong>
                    {t("groupInformation.uniformSummaryOfBenefitsAndCoverage")}:
                  </strong>
                </td>
                <td>
                  <div className="d-flex flex-column gap-2">
                    {effDateUSBC.map((item: any, index) => (
                      <div key={index - item.groupID}>
                        <Link
                          to=""
                          onClick={() =>
                            handleBtnBeneficiaryUSBCFileClick(item)
                          }
                        >
                          <span className="visually-hidden pe-2">
                            {t(
                              "groupInformation.uniformSummaryOfBenefitsAndCoverage"
                            )}
                          </span>
                          {t("enrollmentHistoryForm.effective")}
                          <span className="visually-hidden ps-2">
                            {t("groupInformation.date")}
                          </span>
                          : {formatISODateToMDY(item.effDate)}
                        </Link>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            )}
            {showBtnContractAmendment && (
              <tr>
                <td>
                  <strong>{t("groupInformation.contractAmendment")}:</strong>
                </td>
                <td className="mt-2 mt-lg-0 mt-md-0">
                  <PrimaryButton
                    data-testid={"benefitSummaryView"}
                    type="button"
                    className={"primary_btn btn-medium btn-w-134 btn_block_mob"}
                    onClick={() => {
                      if (contractAmendmentFileURL) {
                        handleViewButtonClick(
                          EmployerInsuranceGroupFileType.GA,
                          contractAmendmentFileURL
                        );
                      } else {
                        setOpenInfo({
                          open: true,
                          content: t("featureUnavailable"),
                        });
                      }
                    }}
                  >
                    <span className="visually-hidden pe-2">
                      {t("groupInformation.contractAmendment")}
                    </span>
                    {t("enrollmentHistoryForm.view")}
                  </PrimaryButton>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupInformation;
