import { useTranslation } from "react-i18next";
import {
  SecondaryButton,
  PrimaryButton,
  Headings,
} from "@sentaraui/optimahealth_web";
import PayMonthlyInvoiceNotes from "./PayMonthlyInvoiceNotes";
import { GlobalApiError } from "organisms/form";
import { PayMonthlyInvoiceReviewProps } from "./interface";
import { usePayMonthlyInvoiceReview } from "./hooks/usePayMonthlyInvoiceReview";

const PayMonthlyInvoiceReview = (props: PayMonthlyInvoiceReviewProps) => {
  const { t } = useTranslation();
  const {
    apiError,
    componentProps,
    reviewedAndSubmit,
    showAPIError,
    goToPrevScreen,
  } = usePayMonthlyInvoiceReview(props);

  return (
    <div>
      <Headings
        className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
        level={2}
        text={t("payMonthlyInvoice.monthlyInvoiceSubHeader")}
      />

      <div className="mb-4 mt-4">
        {showAPIError && <GlobalApiError apiError={apiError} />}
      </div>

      <p>{t("payMonthlyInvoice.subText1")}</p>
      <p>{t("payMonthlyInvoice.subText2")}</p>

      <div className="table-responsive">
        <table className="table table-custom-style group-info-table table-column-50 mb-4">
          <thead>
            <tr>
              <th colSpan={2}>{t("payMonthlyInvoice.paymentInformation")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{t("payMonthlyInvoice.bankName")}:</strong>
              </td>
              <td>{componentProps.bankAccount}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("payMonthlyInvoice.bankRoutingNumber")}:</strong>
              </td>
              <td>{componentProps.routingNumber}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("payMonthlyInvoice.accountNumber")}:</strong>
              </td>
              <td>{componentProps.accountNumber}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("payMonthlyInvoice.accountType")}:</strong>
              </td>
              <td>{componentProps.accountType}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("payMonthlyInvoice.totalEFTAmount")}:</strong>
              </td>
              <td>${componentProps.eftAmmount}</td>
            </tr>
            <tr>
              <td>
                <strong>{t("payMonthlyInvoice.note")}:</strong>
              </td>
              <td>
                <textarea
                  className="form-input w-100"
                  disabled
                  defaultValue={componentProps.note}
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4 pb-2">
        <PrimaryButton
          data-testid="btnOrderMemberIdCardSearch"
          id="primary"
          type="submit"
          className={"primary_btn btn_block_mob"}
          onClick={reviewedAndSubmit}
        >
          {t("payMonthlyInvoice.submitBtn")}
        </PrimaryButton>
        <SecondaryButton
          type="button"
          data-testid="btnOrderMemberIdCardClear"
          className={"secondary_btn btn_block_mob"}
          onClick={goToPrevScreen}
        >
          {t("payMonthlyInvoice.backBtn")}
        </SecondaryButton>
      </div>
      <PayMonthlyInvoiceNotes />
    </div>
  );
};
export default PayMonthlyInvoiceReview;
