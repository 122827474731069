import { t } from "i18next";

import { listPerTable } from "utils/constants/Constants";
import { InputField, AccordionTable } from "@sentaraui/optimahealth_web";
import { useGlobalState } from "providers";

import {
  MemberList,
  MemberListItem,
  SelectedDepType,
} from "utils/models/CommonTypes";

type Props = {
  isMobile?: boolean;
  pageList?: MemberList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
};

const SearchListItem = ({
  isMobile,
  pageList,
  page,
  selectedDep,
  setSelectedDep,
}: Props) => {
  const handleCheckboxMultipleChange = (
    id: string,
    ids: string[],
    checked: any
  ) => {
    let updated = selectedDep ?? {};
    if (updated && ids.length > 1) {
      ids.forEach((item) => {
        const { [item]: omited, ...rest } = updated;
        updated = rest;
      });
    }
    if (checked) {
      updated = { ...updated, [id]: ids };
    } else {
      const { [id]: omited, ...rest } = updated;
      updated = rest;
    }
    setSelectedDep(updated);
  };

  const listDom = () => {
    if (!pageList) {
      return false;
    }
    let keyArray = Object.keys(pageList);
    keyArray.sort((a, b) => a.localeCompare(b));
    const start = (page - 1) * listPerTable;
    keyArray = keyArray.slice(start, start + listPerTable);

    return keyArray?.map((key) => {
      const member: MemberListItem = pageList[key];
      const dom = (
        <tr key={key}>
          {Array.isArray(member) ? (
            <>
              <td className="checkbox-only text-center">
                <InputField
                  id={`chkSelectSubsriberAndAllDependent_${key}}`}
                  className="form-check-input mt-0 p-0"
                  type="checkbox"
                  disabled={false}
                  data-testid={`chkRequestMemberIdCardTableRowSelectSubsriberAndAllDependent_${key}}`}
                  checked={
                    selectedDep
                      ? !!selectedDep[key.split("+")[0]]?.length
                      : false
                  }
                  onChange={(e: any) => {
                    handleCheckboxMultipleChange(
                      key.split("+")[0],
                      member,
                      e.target.checked
                    );
                  }}
                />
              </td>
              <td colSpan={5}>
                <label htmlFor={`chkSelectSubsriberAndAllDependent_${key}}`}>
                  {t("requestMemberIDCard.selectDependent")}
                  {" " + key.split("+")[0]}
                </label>
              </td>
            </>
          ) : (
            <>
              <td className="checkbox-only text-center">
                <InputField
                  id={`chkRequestMemberIdCardTable-${key}`}
                  className="form-check-input mt-0 p-0"
                  type="checkbox"
                  disabled={
                    selectedDep
                      ? !!selectedDep[key.split("*")[0]]?.includes(key)
                      : false
                  }
                  data-testid={`chkEnrollmentTableRowSearchData-${key}`}
                  checked={
                    selectedDep
                      ? selectedDep[key]?.length > 0 ||
                        !!selectedDep[key.split("*")[0]]?.includes(key)
                      : false
                  }
                  onChange={(e: any) => {
                    handleCheckboxMultipleChange(key, [key], e.target.checked);
                  }}
                />
              </td>
              <td>{key}</td>
              <td>{member.groupName}</td>
              <td className="text-uppercase">{`${member.name}`}</td>
              <td>{member.relationshipCode}</td>
            </>
          )}
        </tr>
      );
      return dom;
    });
  };

  const listDomMobile = () => {
    if (!pageList) {
      return [];
    }
    let keyArray = Object.keys(pageList);
    keyArray.sort((pageKeyA, pageKeyB) => pageKeyA.localeCompare(pageKeyB));
    const start = (page - 1) * listPerTable;
    keyArray = keyArray.slice(start, start + listPerTable);

    const list = keyArray?.map((key) => {
      const member: MemberListItem = pageList[key];

      return {
        title: (
          <div>
            {Array.isArray(member) ? (
              <div className="d-flex align-items-center flex-no-wrap gap-3">
                <InputField
                  id={`orderMemberIdCardAllChk-${key}`}
                  className="form-check-input mt-0 p-0"
                  type="checkbox"
                  disabled={false}
                  data-testid="chkRequestMemberIdCardTableRowSelectSubsriberAndAllDependent"
                  checked={
                    selectedDep
                      ? !!selectedDep[key.split("+")[0]]?.length
                      : false
                  }
                  onChange={(e: any) => {
                    handleCheckboxMultipleChange(
                      key.split("+")[0],
                      member,
                      e.target.checked
                    );
                  }}
                />
                <label htmlFor={`orderMemberIdCardAllChk-${key}`}>
                  {t("requestMemberIDCard.selectDependent")}
                  {" " + key.split("+")[0]}
                </label>
              </div>
            ) : (
              <div className="d-flex align-items-center flex-no-wrap gap-3">
                <div>
                  <InputField
                    id={`orderMemberIdCardChk-${key}`}
                    className="form-check-input mt-0 p-0"
                    type="checkbox"
                    disabled={
                      selectedDep
                        ? !!selectedDep[key.split("*")[0]]?.includes(key)
                        : false
                    }
                    data-testid={`chkRequestMemberIdCardTableRowMobile-${key}`}
                    checked={
                      selectedDep
                        ? selectedDep[key]?.length > 0 ||
                          !!selectedDep[key.split("*")[0]]?.includes(key)
                        : false
                    }
                    onChange={(e: any) => {
                      handleCheckboxMultipleChange(
                        key,
                        [key],
                        e.target.checked
                      );
                    }}
                  />
                </div>
                <label htmlFor={`orderMemberIdCardChk-${key}`}>
                  <strong>{key}</strong>
                </label>
              </div>
            )}
          </div>
        ),
        id: "order_id_card_table_data",
        content: Array.isArray(member) ? (
          ""
        ) : (
          <div className="d-flex flex-column gap-3 pb-4">
            {/* <th scope="col">{t("requestMemberIDCard.selectColumnHeader")}</th>
             <th scope="col">{t("requestMemberIDCard.memberID")}</th> */}
            <div>
              <div>{t("enrollmentHistoryForm.groupNumber")}</div>
              <div>{member.groupName}</div>
            </div>
            <div>
              <div>{t("enrollmentHistoryForm.name")}</div>
              <div className="text-uppercase">{`${member.name}`}</div>
            </div>
            <div>
              <div>{t("enrollmentHistoryForm.relationship")}</div>
              <div>{member.relationshipCode}</div>
            </div>
          </div>
        ),
      };
    });
    return list;
  };
  return (
    <>
      {" "}
      {isMobile ? (
        <AccordionTable
          initialActiveIndex={0}
          id="order_id_card_member_table"
          items={listDomMobile()}
        />
      ) : (
        <>{listDom()}</>
      )}
    </>
  );
};

export default SearchListItem;
