import { InputField, PrimaryButton } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";
import PayMonthlyInvoiceTable from "./PayMonthlyInvoiceTable";
import { PayMonthlyInvoiceProps } from "./interface";
import { usePayMonthlyInvoiceDetails } from "./hooks/usePayMonthlyInvoiceDetails";
import { ErrorBy } from "organisms/form";
import { useEffect } from "react";

const PayMonthlyInvoiceDetails = (props: PayMonthlyInvoiceProps) => {
  const { t } = useTranslation();
  const {
    empGroupARDetailResponseData,
    handleBtnContinueClick,
    handleChkSelectAllClick,
    isSelectAllChecked,
    selectedRecords,
    setIsSelectAllChecked,
    setSelectedRecords,
    isShowMinRecordsNeedsToBeSelectedError,
  } = usePayMonthlyInvoiceDetails(props);
  useEffect(() => {
    if (isShowMinRecordsNeedsToBeSelectedError) {
      document.getElementById("checkboxAllOutStandAmount")?.focus();
    }
  }, [isShowMinRecordsNeedsToBeSelectedError]);

  const filteredItems = empGroupARDetailResponseData?.filter(item => item.isInvoicePayable) ?? [];

  return (
    <div>
      <div>
        <ErrorBy
          isShow={isShowMinRecordsNeedsToBeSelectedError}
          message={t("payMonthlyInvoice.minimumSelectionRequiredErrorMsg")}
        />
      </div>

      <div className="d-flex flex-column gap-3 p-3 border border-radius10 mb-4 light-grey-bg">
        {t("payMonthlyInvoice.subtext1")}

        <p className="mb-0">{t("payMonthlyInvoice.subtext2")}</p>
      </div>

      <PayMonthlyInvoiceTable
        selectedRecords={selectedRecords}
        setSelectedRecords={setSelectedRecords}
        isSelectAllChecked={isSelectAllChecked}
        setIsSelectAllChecked={setIsSelectAllChecked}
        empGroupARDetailResponseData={empGroupARDetailResponseData}
      />
      {empGroupARDetailResponseData.length > 0 && (
        <div className="mb-4">
          <div className="d-flex align-items-center pb-2 pt-3">
            <InputField
              className="form-check-input mt-0 p-0"
              type="checkbox"
              id={"checkboxAllOutStandAmount"}
              dataTestId={"chkSelectAllGroups"}
              checked={
                selectedRecords.length !== filteredItems.length
                  ? false
                  : true
              }
              onChange={handleChkSelectAllClick}
            />
            <label
              className="check-label-text check-label-pad"
              htmlFor={"checkboxAllOutStandAmount"}
            >
              {t("payMonthlyInvoice.chkSelectAllGroups")}
            </label>
          </div>
        </div>
      )}

      <div className="mb-5">
        <PrimaryButton
          data-testid="btnContinue"
          id="primary"
          type="submit"
          onClick={handleBtnContinueClick}
          className={
            selectedRecords.length
              ? "primary_btn btn_block_mob"
              : "primary_btn disabled_btn btn_block_mob"
          }
          aria-label={selectedRecords.length ? t("account.label.continue") : t("account.label.continueDisabled")}
        >
          {t("payMonthlyInvoice.continueBtn")}
        </PrimaryButton>
      </div>

      <div className="d-flex flex-column gap-5 p-3 border border-radius10 mb-4 light-grey-bg">
        <div className="d-flex flex-column gap-3">
          {t("payMonthlyInvoice.paymentsDue")}
          <ul className="list-disc d-flex flex-column gap-2 m-0 ps-4">
            <li>{t("payMonthlyInvoice.paymentDueNote1")}</li>
            <li>{t("payMonthlyInvoice.paymentDueNote2")}</li>
            <li>{t("payMonthlyInvoice.paymentDueNote3")}</li>
          </ul>
        </div>
        <div className="d-flex flex-column gap-3">
          {t("payMonthlyInvoice.partialBalance")}
          <ul className="list-disc d-flex flex-column gap-2 m-0 ps-4">
            <li>{t("payMonthlyInvoice.partialBalanceNote1")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default PayMonthlyInvoiceDetails;
