import { Headings } from "@sentaraui/optimahealth_web";
import EnrollmentHistoryForm from "./EnrollmentHistoryForm";
import { useTranslation } from "react-i18next";
import { Form } from "organisms/form/Form";
import EnrollmentHistoryHeader from "./EnrollmentHistoryHeader";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchEnrollmentHistory from "./SearchEnrollmentHistory";
import { useAuth, useGlobalState } from "providers";
import { useEmployerEnrollmentHistory } from "@sentara/sentara-api-hooks-core";
import { ErrorBy } from "organisms/form";

export interface EmployerEnrollmentHistoryResponseItem {
  memberID: string;
  firstName: string;
  midName: string;
  lastName: string;
  suffix: string | null;
  familyID: string;
  groupID: string;
  relationCode: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  memberFullName: string;
  memberRelationship: string;
  memberIDandGroupID: string;
}
const EnrollmentHistory = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const { setPageLoader } = useGlobalState();
  const { getEmployerEnrollmentHistory } = useEmployerEnrollmentHistory();
  const { user } = useAuth();
  const [memberList, setMemberList] = useState<
    EmployerEnrollmentHistoryResponseItem[]
  >([]);
  const [error, setError] = useState<boolean>(false);

  const defaultValues = {
    groupID: searchParams.get("groupID") ?? "",
    familyID: searchParams.get("familyID") ?? "",
    memberID: searchParams.get("memberID") ?? "",
    ssn: searchParams.get("ssn") ?? "",
    firstName: searchParams.get("firstName") ?? "",
    lastName: searchParams.get("lastName") ?? "",
  };
  useEffect(() => {
    document.title = t("pageTitleADA.enrollmentHistory");
    if (
      searchParams.get("lastName") ||
      searchParams.get("familyID") ||
      searchParams.get("memberID") ||
      searchParams.get("ssn")
    ) {
      const params: any = {};
      searchParams.forEach((item: string, key: string) => {
        params[key] = item;
      });

      if (!params.groupID) {
        const groups: Array<string> = [];
        user?.groups?.forEach((element) => {
          groups.push(element.groupID.toString());
        });
        params.groupID = groups.join(",");
      }
      if (params.ssn) {
        params.ssn = params.ssn.replaceAll("-", "");
      }

      setPageLoader("eligilitySearch", true);

      getEmployerEnrollmentHistory(params).then((res) => {
        setMemberList([]);
        setPageLoader("eligilitySearch", true);

        if (res.data) {
          let memberlistdata = res.data.slice(0, 100);
          setMemberList(memberlistdata);
          setError(false);
        } else if (res.errorCode == "404") {
          setMemberList([]);
          setError(true);
        }

        setIsSubmited(false);
        setPageLoader("eligilitySearch", false);
      });
    } else {
      setIsSubmited(false);
    }
  }, [searchParams]);

  const onSubmit = (formData: any) => {
    const { searchInput, ...rest } = formData;
    const search = { ...rest };
    for (const i in search) {
      if (searchParams.get(i) != search[i]) {
        setIsSubmited(true);
        setSearchParams(search);
        break;
      }
    }
  };
  return (
    <div id="enrollmentHistoryContent">
      <EnrollmentHistoryHeader />
      {memberList?.length &&
        (searchParams.get("memberID") ||
          searchParams.get("ssn") ||
          searchParams.get("lastName") ||
          searchParams.get("familyID")) &&
        !isSubmited ? (
        <SearchEnrollmentHistory memberListItem={memberList} />
      ) : (
        <div>
          <Headings
            className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
            level={2}
            text={t("enrollmentHistoryForm.subHeader")}
          />
          <div className="p-4 border border-radius10 mb-4 light-grey-bg">
            {t("enrollmentHistoryForm.subHeaderContent")}
            <p className="pt-3 mb-0">
              <strong>{t("enrollmentHistoryForm.subHeaderContent1")}</strong>
            </p>
          </div>
          <ErrorBy
            isShow={!!(!isSubmited && !memberList?.length && error)}
            message={t("unableToLocate")}
          />
          <Form defaultValues={defaultValues} onSubmit={onSubmit}>
            <EnrollmentHistoryForm setError={setError} />
          </Form>
          <p className="mt-3 mb-5" data-testid="txtSearchResultsNote">
            {t("enrollmentHistoryForm.matchCriteriaText")}
          </p>
        </div>
      )}
    </div>
  );
};

export default EnrollmentHistory;
