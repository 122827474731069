import { useEffect, useState } from "react";
import { 
  EmployerGroupInvoiceItem, 
  EmployerGroupInvoiceItems, 
  PayMonthlyInvoiceTableProps 
} from "../interface";
import BigNumber from "bignumber.js";

export const usePayMonthlyInvoiceTable = ({
  selectedRecords,
  setSelectedRecords,
  isSelectAllChecked,
  empGroupARDetailResponseData,
}: PayMonthlyInvoiceTableProps) => {
  const [totalDueAmount, setTotalDueAmount] = useState(new BigNumber(0));
  const [totalOutStandingBalance, setTotalOutStandingBalance] = useState(
    new BigNumber(0)
  );

  const handleCheckboxChange = (
    employerGroupARDetailResponseItem: EmployerGroupInvoiceItem
  ) => {
    if (!employerGroupARDetailResponseItem.isInvoicePayable) {
      return;
    }

    setSelectedRecords((prevSelectedRecords: EmployerGroupInvoiceItems) => {
      if (
        prevSelectedRecords.some(
          (record: EmployerGroupInvoiceItem) =>
            record.uniqueIndexNumber ===
            employerGroupARDetailResponseItem.uniqueIndexNumber
        )
      ) {
        // Remove the item if it is already selected
        return prevSelectedRecords.filter(
          (record: EmployerGroupInvoiceItem) =>
            record.uniqueIndexNumber !==
            employerGroupARDetailResponseItem.uniqueIndexNumber
        );
      } else {
        // Add the item if it is not already selected
        return [...prevSelectedRecords, employerGroupARDetailResponseItem];
      }
    });
  };

  useEffect(() => {
    if (empGroupARDetailResponseData?.length) {
      let totalDueAmount = new BigNumber(0);
      let totalOutStandingBalance = new BigNumber(0);

      empGroupARDetailResponseData?.map((rowData) => {
        const invoiceAmt = new BigNumber(rowData.invoiceAmt || "0");
        const invoiceBal = new BigNumber(rowData.invoiceBal || "0");
        const etf = new BigNumber(rowData.invoiceBal || "0");
        rowData.etfDraftAmount = etf.toFixed(2);

        totalDueAmount = totalDueAmount.plus(invoiceAmt);
        totalOutStandingBalance = totalOutStandingBalance.plus(invoiceBal);
      });

      setTotalDueAmount(totalDueAmount);
      setTotalOutStandingBalance(totalOutStandingBalance);
    }
  }, [empGroupARDetailResponseData]);

  return {
    empGroupARDetailResponseData,
    handleCheckboxChange,
    isSelectAllChecked,
    selectedRecords,
    totalDueAmount,
    totalOutStandingBalance,
  };
};
