import { useState } from "react";
import {
  ApiErrorResponse,
  EmployerPaymentConfirmationRequest,
  EmployerPaymentRequest,
  useEmployerPayment,
  useEmployerPaymentConfirmation,
} from "@sentara/sentara-api-hooks-core";
import { useGlobalState } from "providers";
import { PayMonthlyInvoiceReviewProps } from "../interface";

export const usePayMonthlyInvoiceReview = ({
  setVisible,
  componentProps,
  selectedRecordsTopLevel,
  setPaymentConfirmationNumber,
}: PayMonthlyInvoiceReviewProps) => {
  const { getEmployerPaymentConfirmation } = useEmployerPaymentConfirmation();
  const { getEmployerPayment } = useEmployerPayment();
  const { setPageLoader } = useGlobalState();
  const [apiError, setApiError] = useState({} as ApiErrorResponse);
  const [showAPIError, setShowAPIError] = useState(false);

  /** START: Payment API: getEmployerPayment */
  const makePaymentAPICall = (paymentConfirmationNumber: string) => {
    // Prepare rowdata in loop by iterating toplevel selected rows.
    const payload: EmployerPaymentRequest[] = [];

    selectedRecordsTopLevel.map((rowData) => {
      const invoicePaidAmount = rowData.etfDraftAmount?.toString() || "";
      payload.push({
        groupID: rowData.groupID,
        invoiceNumber: rowData.invoice,
        invoiceAmount: rowData.invoiceAmt,
        invoicePaidAmount: invoicePaidAmount,
        confirmationNumber: paymentConfirmationNumber,
        invoiceDT: rowData.invoiceDate,
        userID: "string",
      });
    });

    getEmployerPayment(payload).then((responseData) => {
      if (responseData.data && responseData.data.status === true) {
        // Show Screen 4 (Payment Confirmation Screen)
        setVisible("4");
      } else {
        // Dont show screen 4. But show the payment failure message.
        const errorMessage: ApiErrorResponse = {
          error: responseData.errorDetails,
        };
        setApiError(errorMessage);
        setShowAPIError(true);
      }
    });
  };
  /** END: Payment API: getEmployerPayment */

  /** START: Confirm Payment API */
  const makeConfirmPaymentAPICall = () => {
    const payload: EmployerPaymentConfirmationRequest = {
      accountName: componentProps.bankAccount,
      accountNumber: componentProps.accountNumber,
      accountType: componentProps.accountType.charAt(0),
      draftAmount: componentProps.eftAmmount,
      note: componentProps.note,
      routingNumber: componentProps.routingNumber,
    };

    setPageLoader("getEmployerPaymentConfirmation", true);
    getEmployerPaymentConfirmation(payload).then((responseData) => {
      setPageLoader("getEmployerPaymentConfirmation", false);
      /** If the confirmationMessage length is less than 14, consider as successfull. Else Failure */
      if (
        responseData &&
        responseData.data?.confirmationMessage &&
        responseData.data.confirmationMessage.length < 14
      ) {
        setPaymentConfirmationNumber(responseData.data.confirmationMessage);
        // Since Payment went successfull, we need to insert record to table so this API needs to be called.
        makePaymentAPICall(responseData.data.confirmationMessage);
      } else if (
        responseData.data?.confirmationMessage &&
        !responseData.error
      ) {
        // May be Invalid Data Error.
        const errorMessage: ApiErrorResponse = {
          error: responseData.data?.confirmationMessage,
        };
        setApiError(errorMessage);
        setShowAPIError(true);
      } else {
        // API Error
        const errorMessage: ApiErrorResponse = {
          errorCode: responseData.errorCode,
          error: responseData.errorDetails,
        };
        setApiError(errorMessage);
        setShowAPIError(true);
      }
    });
  };
  /** END: Confirm Payment API */

  const reviewedAndSubmit = () => {
    setShowAPIError(false);
    makeConfirmPaymentAPICall();
  };

  const goToPrevScreen = () => {
    setVisible("2");
  }

  return {
    apiError,
    componentProps,
    reviewedAndSubmit,
    setVisible,
    showAPIError,
    goToPrevScreen,
  };
};
