import { useEffect, useRef } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { Headings, Image } from "@sentaraui/optimahealth_web";
import LogoImg from "../../assets/images/sentara_logo.svg";
import AvMedLogoImg from "../../assets/images/avmed_logo.svg";
import DuoImg from "../../assets/images/dmp/duoLogo-web.png";
import {
  DEVICE_MANAGEMENT,
  DEVICE_MANAGEMENT_FAQ,
  ENROLLMENT,
  OVERVIEW,
} from "routes";
import DeviceManagementSupportInfo from "pages/deviceManagementPortal/DeviceManagementSupportInfo";
import { Brands, useBranding, useGlobalState } from "providers";

export const DeviceManagementLayout = () => {
  const { brandConfig } = useBranding();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const { dmTitle } = useGlobalState();
  const logoImagePath =
    brandConfig.brandName === Brands.AVMED ? AvMedLogoImg : LogoImg;

  useEffect(() => {
    // Set focus on the main content element when the page load
    if (mainContentRef.current) {
      mainContentRef.current.focus();
    }
  }, []);

  return (
    <div
      className="d-flex flex-column h-100 no-focus"
      tabIndex={-1}
      ref={mainContentRef}
    >
      {/* Skip to Main Content link */}
      <a href="#main-content" className="skip-link">
        {t("accessibility.message.skipToMainContent")}
      </a>
      {/* Header section */}
      <div>
        <header>
          {/* Container for the header */}
          <div className="container-fluid">
            <div>
              <nav className="d-flex flex-column flex-lg-row flex-md-row  align-items-lg-center align-items-md-center justify-content-between pt-3 mb-3">
                <div>
                  {/* Component for the header logo */}
                  <Link to={`${OVERVIEW}`} className="logo-img">
                    <Image
                      dataTestId={"imgBrokerPortalLogo"}
                      path={logoImagePath}
                      alttext={t("appLogo")}
                      className="img-responsive"
                    />
                  </Link>
                </div>
                <div className="dark-gray-color pt-4 pt-lg-0 pt-md-0">
                  <strong>
                    {t("deviceManagement.twoStepLoginDuoSecurity")}
                  </strong>
                </div>
              </nav>
            </div>
          </div>
          {/* Horizontal line separating the header from the rest of the content */}
          <hr className="border-w-2 mb-0" />
        </header>

        {/* Main content container */}
        <main id="main-content" className="container-fluid">
          <div className="row pt-4">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
              <Headings
                className="mb-4"
                level={1}
                dataTestId="deviceManagementHeading"
                text={dmTitle}
              />
              <div className="mb-3" data-testid="helpfulDocumentationHeading">
                <strong>{t("deviceManagement.helpfulDocumentation")}</strong>
              </div>
              <div className="d-flex flex-column gap-3 mb-4">
                <div>
                  <Link data-testid="lnkOverview" to={OVERVIEW}>
                    {t("deviceManagement.overviewLink")}
                  </Link>
                </div>
                <div>
                  <Link data-testid="lnkEnrollment" to={ENROLLMENT}>
                    {t("deviceManagement.enrollmentLink")}
                  </Link>
                </div>
                <div>
                  <Link
                    data-testid="lnkDeviceManagement"
                    to={DEVICE_MANAGEMENT}
                  >
                    {t("deviceManagement.deviceManagementHeading")}
                  </Link>
                </div>
                <div>
                  <Link data-testid="lnkFAQ" to={DEVICE_MANAGEMENT_FAQ}>
                    {t("deviceManagement.faqLink")}
                  </Link>
                </div>
              </div>
              <div className="mb-4">
                <DeviceManagementSupportInfo />
                <div>{t("deviceManagement.employerGroupSupportInfo")}</div>
              </div>
              <div className="mb-4">
                <Image
                  dataTestId={"imgDuoLogo"}
                  path={DuoImg}
                  alttext={t("duoLogo")}
                  className="img-responsive duo-img"
                />
              </div>
            </div>
            <div
              className="col-lg-8 col-md-8 col-sm-12 col-12 mb-4 pt-3"
              aria-live="polite"
            >
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
