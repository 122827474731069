import { useState, ChangeEvent, useEffect } from "react";
import { Form } from "organisms/form/Form";
import { t } from "i18next";
import { Headings, Link } from "@sentaraui/optimahealth_web";
import ContactUsForm from "./ContactUsForm";
import { LINK } from "utils/constants/Constants";
import { useNavigate } from "react-router";
/**
 * Renders the Contact Us page.
 *
 * @returns JSX.Element
 */
import { Brands, useAuth, useBranding, useGlobalState } from "providers";
import { CONTACTUS_SUCCESS } from "routes/RouteURIs";
import {
  useEmployerContactUs,
  useEmployerContactUsInsuranceGroup,
} from "@sentara/sentara-api-hooks-core";
import { formatISODateToMDY } from "utils/helpers/DateFormate";

const ContactUsPage = () => {
  const { user } = useAuth();

  const navigate = useNavigate();
  const { brandConfig } = useBranding();
  const { submitContactUs } = useEmployerContactUs();
  const [renewalDates, setRenewalDates] = useState("");

  const rawPostalCode = user?.addresses?.[0]?.postalCode ?? "";
  const cleanedPostalCode = rawPostalCode.replace(/\s+/g, ""); // Remove all spaces

  // Insert a hyphen after the fifth character
  const formattedPostalCode =
    cleanedPostalCode.length > 5
      ? cleanedPostalCode.slice(0, 5) + "-" + cleanedPostalCode.slice(5)
      : cleanedPostalCode;

  const defaultValues = {
    firstName: user?.person?.firstName ?? "",
    lastName: user?.person?.lastName ?? "",
    email: user?.emailAddresses?.[0]?.emailAddress ?? "",
    isEmailOptIn: "isEmailOptIn",
    IsPhoneOptIn: "",
    isMailOptIn: "",
    address1: user?.addresses?.[0]?.address1 ?? "",
    address2: user?.addresses?.[0]?.address2 ?? "",
    cityState: user?.addresses?.[0]?.city ?? "",
    postalCode: formattedPostalCode,
    groupNumbers: user?.groups?.map((group) => group.groupID).join(",") ?? "",
    renewalDate: renewalDates,
  };

  const [dropdownValue, setDropdownValue] = useState<string>("");
  const { setPageLoader } = useGlobalState();
  const { getEmployerContactUsInsuranceGroupDetail } =
    useEmployerContactUsInsuranceGroup();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDropdownValue(e.target.value);
  };
  const onSubmit = async (formData: any) => {
    //navigate(`/app/${CONTACTUS_SUCCESS}`);
    const isHealthProductsInformation =
      formData?.isHealthProductsInformation === "isHealthProductsInformation";
    const isBehavioralHealthProductsInformation =
      formData?.isBehavioralHealthProductsInformation ===
      "isBehavioralHealthProductsInformation";
    const isOtherInformation =
      formData?.isOtherInformation === "isOtherInformation";

    const isEmailOptIn = formData?.isEmailOptIn === "isEmailOptIn";
    const isMailOptIn = formData?.isMailOptIn === "isMailOptIn";
    const IsPhoneOptIn = formData?.IsPhoneOptIn === "IsPhoneOptIn";

    let object = {
      firstName: defaultValues.firstName,
      lastName: defaultValues.lastName,
      organization: formData.organization,
      groupSize: dropdownValue,
      email: formData?.email,
      //...formData,
      address1: formData?.address1,
      address2: formData?.address2,
      cityState: formData?.cityState,

      postalCode: formData?.postalCode,
      phone:
        formData.phone.length > 0
          ? "+1" + formData.phone.replaceAll("-", "")
          : "",

      renewals: formData?.renewalDate,
      groupNumbers: formData?.groupNumber,
      additionalCarriers: formData?.additionalCarriers,

      isHealthProductsInformation: isHealthProductsInformation,
      isBehavioralHealthProductsInformation:
        isBehavioralHealthProductsInformation,
      isOtherInformation: isOtherInformation,

      question: formData?.question,

      IsPhoneOptIn: IsPhoneOptIn,
      isMailOptIn: isMailOptIn,
      isEmailOptIn: isEmailOptIn,

      fileAttachment: formData.fileAttachment[0],
    };
    setPageLoader("getEmployerContactUsInsuranceGroupDetail", true);
    const response = await submitContactUs(object);

    if (response?.data?.status) {
      setPageLoader("getEmployerContactUsInsuranceGroupDetail", false);
      navigate(`/app/${CONTACTUS_SUCCESS}`);
    }
  };
  useEffect(() => {
    document.title = t("pageTitleADA.contactUs");
  }, []);

  const getLoggedInUsersGroupId = (): string[] => {
    const groupIDs: string[] = [];

    if (user?.loginID && user.groups) {
      user.groups.map((group) => {
        groupIDs.push(group.groupID);
      });
    }
    return groupIDs;
  };
  useEffect(() => {
    const selectedGroupIds: string[] = getLoggedInUsersGroupId();
    setPageLoader("getEmployerContactUsInsuranceGroupDetail", true);

    if (selectedGroupIds.length > 0) {
      getEmployerContactUsInsuranceGroupDetail({
        groupID: selectedGroupIds,
      }).then((responseData: any) => {
        setPageLoader("getEmployerContactUsInsuranceGroupDetail", false);

        if (responseData.data) {
          const renewalDates = responseData.data
            .filter((item: any) => item.renewalDate)
            .map((item: any) => formatISODateToMDY(item.renewalDate));
          const renewalDatesString = renewalDates.join(", ");
          setRenewalDates(renewalDatesString);
        }
      });
    }
  }, []);

  return (
    <div className="pb-3" id="contactUsContent">
      <Headings
        className="mb-4"
        level={1}
        text={t("contactus.title")}
        dataTestId="txtContactUsHeading"
      />

      <div className="border-bottom  mb-4">
        {Brands.AVMED !== brandConfig.brandName && (
          <>
            <div className="mb-2 font-eighteen fw-600 redhat_semibold">
              {t("contactus.subContent")}{" "}
              <Link
                to={LINK.FREQUENTLY_ASKED_QUESTION}
                target="_blank"
                className="font-eighteen fw-600 redhat_semibold text-decoration-underline"
                data-testid="InkFAQLink"
              >
                {t("contactus.freqAskedQus")}
              </Link>
              .
            </div>
            <div className="mb-3 pb-1">{t("contactus.fillForm")}</div>
          </>
        )}
        <div className="mb-4">
          <strong className="text-danger ps-1">*</strong>{" "}
          {t("contactus.indicatesRequired")}
        </div>
      </div>
      <Headings
        className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
        level={2}
        text={"Personal Information"}
        dataTestId="txtPersonalInfoHeading"
      />

      <Form defaultValues={defaultValues} onSubmit={onSubmit}>
        <ContactUsForm
          handleChange={handleChange}
          dropdownValue={dropdownValue}
          renewals={renewalDates}
          formattedPostalCode={formattedPostalCode}
        />
      </Form>
    </div>
  );
};

export default ContactUsPage;
