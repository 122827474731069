import { useState, useEffect, useRef } from "react";
import { t } from "i18next";
import {
  PrimaryButton,
  SecondaryButton,
  Headings,
  ModalOverlayWithoutClose,
} from "@sentaraui/optimahealth_web";
import { useGlobalState, useOidcAuth, useAuth } from "providers";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router";
import { IDLETIMER } from "utils/constants/Constants";

function IdleTimeoutContainer() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { signOut } = useAuth();
  const { setInActiveSignOut } = useGlobalState();
  const auth = useOidcAuth();
  const location = useLocation();
  const { pathname, search } = location;
  const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onIdle = () => {
    setModalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(() => {
      if (sessionTimeoutRef.current) {
        clearTimeout(sessionTimeoutRef.current);
      }
      alertTo("logout", true);
    }, IDLETIMER.timeout);
  };

  useIdleTimer({
    onIdle,
    timeout: IDLETIMER.delay,
    name: "idle-timer",
  });

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    return auth.events.addSilentRenewError(() => {
      alertTo();
    });
  }, [auth.events, auth.signinSilent]);

  const alertTo = (to = "/", setInactive = false) => {
    sessionStorage.setItem("redirectTo", `${pathname}${search}`);
    if (setInactive) {
      setInActiveSignOut(setInactive);
    }
    setModalIsOpen(false);
    auth.removeUser();
    signOut(to);
  };

  return (
    <div>
      <ModalOverlayWithoutClose
        isOpen={modalIsOpen}
        overlayInfo="Modal information"
        className="modal-w-medium"
      >
        <div data-testid="timeOut-title">
          <Headings
            className="redhat_medium font-twenty fw-500 mb-2 me-4"
            level={3}
            text={t("session.label.modalTitle")}
          />
          <p className="mb-4" data-testid="sessionModalDescription">
            {t("session.label.modalDescription")}
          </p>
          <div className="d-flex flex-column flex-lg-row flex-md-row gap-3">
            <PrimaryButton
              data-testid={"modalOkButton"}
              className={"primary_btn w-100 btn_block_mob"}
              type="button"
              aria-label={t("session.label.modalOk")}
              onClick={() => {
                sessionStorage.setItem("redirectTo", `${pathname}${search}`);
                auth.removeUser();
                auth.signinRedirect();
              }}
            >
              {t("session.label.modalOk")}
            </PrimaryButton>
            <SecondaryButton
              data-testid={"cancelButton"}
              className={"secondary_btn w-100 btn_block_mob"}
              type="button"
              aria-label={t("session.label.modalCancel")}
              onClick={() => {
                if (sessionTimeoutRef.current) {
                  clearTimeout(sessionTimeoutRef.current);
                }
                alertTo("logout", false);
              }}
            >
              {t("session.label.modalCancel")}
            </SecondaryButton>
          </div>
        </div>
      </ModalOverlayWithoutClose>
    </div>
  );
}

export default IdleTimeoutContainer;
