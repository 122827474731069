import SendMePushImg from "../../assets/images/dmp/sTwelve.png";
import DuoAppApprove from "../../assets/images/dmp/sThirteen.png";
import DuoMobilePushApple from "../../assets/images/dmp/aOne.png";
import DuoMobilePushAndroid from "../../assets/images/dmp/aTwo.png";
import KeyIconAppleExperience from "../../assets/images/dmp/aThree.png";
import KeyIconAndroidExperience from "../../assets/images/dmp/aFour.png";
import EnterPassCode from "../../assets/images/dmp/aFive.png";
import TextMeNewCode from "../../assets/images/dmp/aSix.png";
import EnterFirstCode from "../../assets/images/dmp/aEight.png";
import ReceivePhoneCall from "../../assets/images/dmp/aNine.png";
import PressOneOnKeypad from "../../assets/images/dmp/aTen.png";
import SignInForEnroll from "../../assets/images/dmp/SignIn.png";
import StartSetUp from "../../assets/images/dmp/eTwo.png";
import TypeOfDevice from "../../assets/images/dmp/eThree.png";
import S_One from "../../assets/images/dmp/sOne.png";
import S_Two from "../../assets/images/dmp/sTwo.png";
import S_Three from "../../assets/images/dmp/sThree.png";
import S_Four from "../../assets/images/dmp/sFour.png";
import S_Five from "../../assets/images/dmp/sFive.png";
import S_Six from "../../assets/images/dmp/sSix.png";
import S_Seven from "../../assets/images/dmp/sSeven.png";
import S_Eight from "../../assets/images/dmp/sEight.png";
import S_Nine from "../../assets/images/dmp/sNine.png";
import S_Ten from "../../assets/images/dmp/sTen.png";
import S_Eleven from "../../assets/images/dmp/sEleven.png";
import S_Fourteen from "../../assets/images/dmp/sFourteen.png";
import S_Fifteen from "../../assets/images/dmp/sFifteen.png";
import T_One from "../../assets/images/dmp/tOne.png";
import T_Two from "../../assets/images/dmp/tTwo.png";
import T_Three from "../../assets/images/dmp/tThree.png";
import T_Four from "../../assets/images/dmp/tFour.png";
import T_Five from "../../assets/images/dmp/tFive.png";
import T_Six from "../../assets/images/dmp/tSix.png";
import P_One from "../../assets/images/dmp/pOne.png";
import P_Two from "../../assets/images/dmp/pTwo.png";
import P_Six from "../../assets/images/dmp/pSix.png";
import P_Seven from "../../assets/images/dmp/pSeven.png";
import P_Eight from "../../assets/images/dmp/pEight.png";
import P_Nine from "../../assets/images/dmp/pNine.png";
import P_Ten from "../../assets/images/dmp/pTen.png";
import P_Eleven from "../../assets/images/dmp/pEleven.png";
import P_Twelve from "../../assets/images/dmp/pTwelve.png";
import aOne_AvMed from "../../assets/images/dmp/aOne_AvMed.png";
import aTwo_AvMed from "../../assets/images/dmp/aTwo_AvMed.png";
import aThree_AvMed from "../../assets/images/dmp/aThree_AvMed.png";
import aFour_AvMed from "../../assets/images/dmp/aFour_AvMed.png";
import sThirteen_AvMed from "../../assets/images/dmp/sThirteen_AvMed.png";
import StartSetUp_AvMed from "../../assets/images/dmp/eTwo_AvMed.png";

import { DeviceTypeProps } from "../models/CommonTypes";

export const APPLICATION_TYPE = "EMPLOYER";

export const DeviceTypes = {
  DESKTOP: "desktop" as DeviceTypeProps,
  TABLET: "tablet" as DeviceTypeProps,
  MOBILE: "mobile" as DeviceTypeProps,
};

export const SERVICE_NUMBER = {
  GROUP_BUSINESS_NUMBER: "800-835-6131",
  INDIVIDUAL_MEDICARE_NUMBER: "800-461-2950",
  EMPLOYER_SERVICE_NUMBER: "1-800-229-1199",
  EMPLOYER_SERVICE_NUMBER_1: "757-552-7110",
};

export const PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE = {
  INVALID_INPUT: "INVALID_INPUT",
  VALUE_LESS_THAN_0: "VALUE_LESS_THAN_0",
  VALUE_LESS_THAN_1: "VALUE_LESS_THAN_1",
  OVER_PAYMENT: "OVER_PAYMENT",
};

export const VALIDATION_LENGTH = {
  POLICY_ID_MAX_LENGTH: 9,
  MEMBER_ID_MAX_LENGTH: 20,
  SSN_MIN_LENGTH: 9,
  NAME_MAX_LENGTH: 20,
  ORGANIZATION_MAX_LENGTH: 200,
  EMAIL_MAX_LENGTH: 100,
  ADDRESS_MAX_LENGTH: 60,
  CITY_MAX_LENGTH: 40,
  POSTALCODE_MAX_LENGTH: 15,
  PHONENUMBER_MAX_LENGTH: 10,
  PASSWORD_MAX_LENGTH: 64,
  RENEWAL_DATE_MAX_LENGTH: 80,
  MEMBER_NUMBER_LENGTH: 6,
};

export const groupSizeItems = [
  {
    id: 1,
    value: "2-99",
  },
  {
    id: 2,
    value: "100+",
  },
];

export const LINK = {
  PRIVACY_POLICY_AVMED: "https://www.avmed.com/your-privacy",
  AGENTHOME_LINK: "",
  EMPLOYERHOME_LINK: "https://www.sentarahealthplans.com/employers",
  PRIVACY_POLICY:
    "https://www.sentarahealthplans.com/company/policies/privacy-statement",
  EMPLOYER_GROUP_REPORTING:
    "https://my.medeanalytics.com/fssologin.aspx?idp=Optimahealth&action=HandleIdpResponse",
  EMPLOYER_ENROLL_BENEFITS: "https://sentarahealth.my.site.com/benefit/",
  FREQUENTLY_ASKED_QUESTION:
    "https://www.sentarahealthplans.com/employers/frequently-asked-questions",
  EMPLOYER_BILLING_STATEMENT:
    "https://reports3.optimahealth.com/r2w/land?c=r2w_employers ",
  LEARN_MORE_LINK_DASHBOARD:
    "https://www.sentarahealthplans.com/employers/updates",
  LEARN_MORE_LINK_DASHBOARD_BRAND: "https://www.avmed.com/news/",
  CONTACT_US_DASHBOARD:
    "https://www.sentarahealthplans.com/employers/contact-us",
  CONTACT_US_DASHBOARD_BRAND:
    "https://www.avmed.com/contact-us/#8775bdaf-6d43-4eea-9fa3-7da13689d929",
};

export const IDLETIMER = {
  delay: 840 * 1000,
  timeout: 60000,
};
export const listPerTable = 10;

export const DEVICE_MANAGEMENT_IMAGE = {
  image1: SendMePushImg,
  image2: DuoAppApprove,
  image3: DuoMobilePushApple,
  image4: DuoMobilePushAndroid,
  image5: KeyIconAppleExperience,
  image6: KeyIconAndroidExperience,
  image7: EnterPassCode,
  image8: TextMeNewCode,
  image9: EnterFirstCode,
  image10: ReceivePhoneCall,
  image11: PressOneOnKeypad,
  image12: SignInForEnroll,
  image13: StartSetUp,
  image14: TypeOfDevice,
  image15: S_One,
  image16: S_Two,
  image17: S_Three,
  image18: S_Four,
  image19: S_Five,
  image20: S_Six,
  image21: S_Seven,
  image22: S_Eight,
  image23: S_Nine,
  image24: S_Ten,
  image25: S_Eleven,
  image28: S_Fourteen,
  image29: S_Fifteen,
  image30: T_One,
  image31: T_Two,
  image32: T_Three,
  image33: T_Four,
  image34: T_Five,
  image35: T_Six,
  image36: P_One,
  image37: P_Two,
  image38: P_Six,
  image39: P_Seven,
  image40: P_Eight,
  image41: P_Nine,
  image42: P_Ten,
  image43: P_Eleven,
  image44: P_Twelve,
  image45: aOne_AvMed,
  image46: aTwo_AvMed,
  image47: aThree_AvMed,
  image48: aFour_AvMed,
  image49: sThirteen_AvMed,
  image50: StartSetUp_AvMed,
};

export const subscriberMinLength = 6;

export const MemberRelationship = {
  D: "Dependent",
  P: "Subscriber",
  S: "Spouse",
};

export const MEMBERSTATUS = {
  E: "Enrolled",
  D: "Disenrolled",
};

// Employer Relationship Code = 3
export const EMPLOYER_RELATIONSHIP_CODE = 3;

// Dashboard Tiles
export const TilesIds = {
  Title1: "tile1",
  Title2: "tile2",
  Title3: "tile3",
  Title4: "tile4",
  Title5: "tile5",
  Title6: "tile6",
};
