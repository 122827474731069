import {
  Carrier,
  EmailAddresses,
  Lobs,
  Person,
  RelationshipCode,
  UserGroup,
  EmployerAddress,
} from "@sentara/sentara-api-hooks-core";

export type DeviceTypeProps = "desktop" | "tablet" | "mobile";

export type ENVTYPES = {
  BASE_URL: string;
  OPC_APIM_SUB_KEY: string;
};

export type LinkListType = {
  title: string;
  to: string;
  id?: string;
  ariaLabel?: any;
  target?: string;
  ariaDescribedBy?: string;
};

export type FooterNavType = {
  dataTestId: string;
  label: string;
  url: string;
  target?: string;
};

export type BrandType = {
  brandName: string;
  hostNames?: Array<string>;
  footerNavigation: { [x: string]: FooterNavType };
  topNavLinks: { [x: string]: LinkListType };
  profileMenuLinks: { [x: string]: any };
  memberBrokerContactUs: string;
  homeLink: string;
  link: { [x: string]: any };
  oidcConfig: { [x: string]: any };
  image?: { [x: string]: any };
};

export type BrandsType = {
  [x: string]: BrandType;
};

export enum LOCALETYPE {
  ENAVMED = "enAvMed",
  ENSENTARA = "enSentara",
}

export type UserType = {
  loginID: string;
  isUnAuthorizedUser: boolean;
  isSORCurrent?: boolean;
  //hasR2WAccess?: HasR2WAccess;
  groupReportingDynamicLinkEnabled?: boolean;
  carrier?: Carrier;
  person?: Person;
  groups?: Array<UserGroup>;
  lobs?: Array<Lobs>;
  emailAddresses?: Array<EmailAddresses>;
  addresses?: Array<EmployerAddress>;
};

export type OrderIDListItem = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  midName: string;
  relationshipCode: RelationshipCode;
};

export type OrderIDList = {
  [x: string]: OrderIDListItem;
};

export type MemberListItem = {
  groupName: string;
  name: string;
  relationshipCode: string;
};
export type MemberList = {
  [x: string]: MemberListItem;
};

export type SelectedDepType = {
  [x: string]: string[];
};

export type IdRequestType = {
  success: Array<any>;
  pending: Array<string>;
  failed: Array<string>;
};
export const DeviceTypes = {
  DESKTOP: "desktop" as DeviceTypeProps,
  TABLET: "tablet" as DeviceTypeProps,
  MOBILE: "mobile" as DeviceTypeProps,
};
