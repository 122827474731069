import { t } from "i18next";
import {
  Accordion,
  QuoteEnrollManageIcon,
  CustomerSupportIcon,
  SettingsIcon,
} from "@sentaraui/optimahealth_web";
import { useState } from "react";

import { LeftNavigations } from "../../utils/constants/Navigation";
import { LinkListType } from "../../utils/models/CommonTypes";
import MenuLinks from "./MenuLinks";
import { useAuth, useGlobalState } from "providers";
import { GROUP_REPORTING } from "routes";

type Props = {
  setIsOpen?: (v: boolean) => void;
  id: string;
};

export const MenuAccordion = (props: Props) => {
  const [initialActiveIndex, setInitialActiveIndex] = useState(0);
  const { user } = useAuth();
  const { refreshKey } = useGlobalState();
  const { setIsOpen = () => { } } = props;
  const handleAccordionToggleItem = (index: number) => {
    return index;
  };
  const MenuIconName = (item: { title: string }) => {
    if (item.title === "nav.managementBenefit") {
      return <QuoteEnrollManageIcon />;
    } else if (item.title === "nav.paymentsBilling") {
      return <CustomerSupportIcon />;
    } else {
      return <SettingsIcon />;
    }
  };

  const menuItems: any = [];
  LeftNavigations.forEach((navItem) => {
    let access = false;

    let groupReportingLink: string = `/app/${GROUP_REPORTING}`;

    const filteredList = navItem.list?.filter((linkItem: LinkListType) => {
      // Exclude the group reporting link if the flag is false
      if (
        !user?.groupReportingDynamicLinkEnabled &&
        linkItem.to === groupReportingLink
      ) {
        return false;
      }
      return true;
    });

    access = filteredList.length > 0;

    if (access) {
      menuItems.push({
        accIcon: MenuIconName(navItem),
        id: "member_profile",
        content: (
          <MenuLinks
            no={menuItems.length}
            setInitialActiveIndex={setInitialActiveIndex}
            setIsOpen={setIsOpen}
            list={filteredList}
          />
        ),
        title: t(navItem.title),
      });
    }
  });

  return (
    <Accordion
      key={refreshKey}
      onToggleItem={handleAccordionToggleItem}
      initialActiveIndex={initialActiveIndex}
      id={props.id}
      items={menuItems}
    />
  );
};
