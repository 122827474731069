import {
  Headings,
  SecondaryButton,
  PrimaryButton,
} from "@sentaraui/optimahealth_web";

import { useGlobalState } from "providers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ENROLLMENT_HISTORY, ENROLLMENT_HISTORY_DETAILS } from "routes";
import {
  DeviceTypes,
  MemberList,
  SelectedDepType,
} from "utils/models/CommonTypes";
import { EmployerEnrollmentHistoryResponseItem } from "./EnrollmentHistory";

import SearchTableDeskTop from "./SearchTableDeskTop";
import Pagination from "organisms/pagination/pagination";
import { listPerTable } from "utils/constants/Constants";
import SearchTableMobile from "./SearchTableMobile";
import { ModalOverlayPopUp } from "organisms/popup/ModalOverlayPopUp";
import { useEmployerEnrollmentHistoryDetails } from "@sentara/sentara-api-hooks-core";

interface SearchEnrollmentHistoryProps {
  memberListItem: EmployerEnrollmentHistoryResponseItem[];
}
const SearchEnrollmentHistory = ({
  memberListItem,
}: SearchEnrollmentHistoryProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  let [searchParams] = useSearchParams();
  const [searchFields, setSearchFields] = useState([]);
  const [pageList, setPageList] = useState<MemberList>();
  const [selectedDep, setSelectedDep] = useState<SelectedDepType>();
  const [page, setPage] = useState<number>(1);
  const [openPopup, setOpenPopup] = useState(false);
  const { getEmployerEnrollmentHistoryDetails } =
    useEmployerEnrollmentHistoryDetails();

  const printParamValue = (key: string) => {
    return ["firstName", "lastName"].includes(key)
      ? "'" + searchParams.get(key) + "'"
      : searchParams.get(key);
  };

  useEffect(() => {
    const list: any = {};
    memberListItem?.forEach((item: EmployerEnrollmentHistoryResponseItem) => {
      if (item.memberID) {
        const id = `${item.memberID.split("*")[0]}+dep`;
        list[item.memberID] = {
          groupName: item.groupID,

          name: item.memberFullName,
          relationshipCode: item.memberRelationship,
        };
        if (!list[id]) {
          list[id] = [];
        }
        list[id].push(item.memberID);
      }
    });
    Object.keys(list).forEach((key) => {
      if (Array.isArray(list[key]) && list[key].length < 2) {
        delete list[key];
      }
    });
    setPageList(list);
  }, [memberListItem]);
  useEffect(() => {
    const params: any = [];

    // Check and add the lastName first if it exists
    if (searchParams.has("lastName")) {
      const lastNameValue = searchParams.get("lastName");
      if (lastNameValue) {
        params.push(
          `${t("enrollmentHistoryForm.memberWith")} ${t("enrollmentHistoryForm.searchKey.lastName")} ${printParamValue("lastName")}`
        );
      }
    }

    // Check and add the firstName next if it exists
    if (searchParams.has("firstName")) {
      const firstNameValue = searchParams.get("firstName");
      if (firstNameValue) {
        params.push(
          `${t("enrollmentHistoryForm.searchKey.firstName")} ${printParamValue("firstName")}`
        );
      }
    }

    // Add other keys except groupId and page
    searchParams.forEach((item: string, key: string) => {
      if (
        key !== "groupID" &&
        key !== "page" &&
        key !== "lastName" &&
        key !== "firstName" &&
        item
      ) {
        params.push(
          `${["lastName"].includes(key) ? t("enrollmentHistoryForm.memberWith") : ""} ${t("enrollmentHistoryForm.searchKey." + key)} ${printParamValue(key)}`
        );
      }
    });

    setSearchFields(params);
  }, [searchParams]);

  const submitMember = () => {
    if (!selectedDep || !Object.keys(selectedDep).length) {
      setOpenPopup(true);
    } else {
      let selectedIds = Object.keys(selectedDep).map(
        (key) => key.split("*")[0]
      );

      const groupid: any[] = [];
      const memberid: any[] = [];
      selectedIds.map((id: any) =>
        memberListItem.filter((member: any) => {
          if (member.memberID.includes(id)) {
            groupid.push(member.groupID);
            memberid.push(member.memberID);
          }
        })
      );

      let uniqueGroupId = Array.from(new Set(groupid));
      let uniqueMemberID = Array.from(new Set(memberid));

      getEmployerEnrollmentHistoryDetails({
        memberID: uniqueMemberID,
        groupID: uniqueGroupId,
      }).then((res) => {
        if (res.data) {
          navigate(`/app/${ENROLLMENT_HISTORY_DETAILS}`, {
            state: { data: res.data },
          });
        }
      });
    }
  };

  return (
    <div>
      <Headings
        className="redhat_semibold black-color mt-5 mb-4"
        level={3}
        text={t("enrollmentHistoryForm.selectMember")}
        dataTestId="txtEnrollmentHistorySelectMember​"
      />
      <div className="mb-4" data-testid="lblYourSearchKeywordDetails​">
        <strong>{t("enrollmentHistoryForm.searchFor")}</strong>{" "}
        <span>{searchFields.join(" and ")}.</span>
      </div>
      <SecondaryButton
        data-testid="btnEnrollmentHistoryNewSearch"
        className={"secondary_btn btn_block_mob mb-4"}
        onClick={() => navigate(`/app/${ENROLLMENT_HISTORY}`)}
      >
        {t("enrollmentHistoryForm.btnNewSearch")}
      </SecondaryButton>
      <div
        className="border border-radius10 light-grey-bg p-3 mb-4"
        data-testid="enrollmentHistoryDetails​"
      >
        <strong>{t("enrollmentHistoryForm.instructionTitle")}</strong>{" "}
        <span>{t("enrollmentHistoryForm.instructionDesc")}</span>
      </div>
      <ModalOverlayPopUp
        open={openPopup}
        title={
          selectedDep && Object.keys(selectedDep).length
            ? t("requestMemberIDCard.request.modalTitle")
            : ""
        }
        displayButton={true}
        content={t("enrollmentHistoryForm.popupMember")}
        callBack={() => setOpenPopup(false)}
      />
      <div className="row mb-5">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {isMobile ? (
            <SearchTableMobile
              {...{
                pageList,
                selectedDep,
                page,
                setSelectedDep,
              }}
            />
          ) : (
            <SearchTableDeskTop
              {...{
                pageList: pageList,
                selectedDep,
                setSelectedDep,

                page,
              }}
            />
          )}
          <div className="d-flex align-items-center pt-2 pb-2 ps-2">
            {pageList && (
              <Pagination
                total={Object.keys(pageList).length ?? 0}
                records={listPerTable}
                current={page ?? 1}
                callBack={(page: any) => {
                  setPage(page);
                  const ele = document.querySelector(
                    ".table-responsive"
                  ) as HTMLDivElement;
                  ele?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            )}
          </div>
          <PrimaryButton
            data-testid={"continueButton"}
            className={
              selectedDep && Object.keys(selectedDep).length
                ? "primary_btn btn_block_mob"
                : "primary_btn disabled_btn btn_block_mob"
            }
            aria-label={
              selectedDep && Object.keys(selectedDep).length
                ? t("account.label.continue")
                : t("account.label.continueDisabled")
            }
            type="button"
            onClick={() => submitMember()}
          >
            {t("account.label.continue")}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default SearchEnrollmentHistory;
