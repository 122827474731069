import { format, parseISO } from "date-fns";

const dateFormats = {
  dateFormatMDY: "MM/dd/yyyy",
  dateTimeFormatMDY: "MM/dd/yyyy HH:mm:ss",
  dateFormatISO: "yyyy-MM-dd",
  dayMonth: "MM/dd",
  withAMorPM: "MM/dd/yy hh:mm:ss a",
};

export const formatDate = (dateString: string, formatType: string) => {
  if (!dateString) {
    return "";
  }
  let date;
  try {
    date = parseISO(dateString);

    const [year, month, day] = dateString.split("T")[0].split("-");
    switch (formatType) {
      case dateFormats.dateFormatMDY:
        return `${month}/${day}/${year}`;
      case dateFormats.dateFormatISO:
        return `${year}-${month}-${day}`;
      default:
        return format(date, dateFormats.dateFormatMDY);
    }
  } catch (error) {
    return "";
  }
};

export const formatISODateToMDY = (dateString: string) => {
  return formatDate(dateString, dateFormats.dateFormatMDY);
};

export const formatISODateToISO = (dateString: string) => {
  return formatDate(dateString, dateFormats.dateFormatISO);
};
