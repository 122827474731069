import { Route, Routes } from "react-router-dom";

import {
  GROUP_INFORMATION,
  PAY_MONTHLY_INVOICE,
  GROUP_REPORTING,
  ENROLL_IN_BENEFITS,
  DEVICE_MANAGEMENT_PORTAL,
  CONTACTUS,
  BILLING_STATEMENTS,
  REQUEST_MEMBER_ID_CARD,
  CONTACTUS_SUCCESS,
  ENROLLMENT_HISTORY,
  ENROLLMENT_HISTORY_DETAILS,
} from "..";
import { AppLayout } from "../../organisms/layouts/AppLayout";
import GroupInformation from "../../pages/groupInformation/GroupInformation";
import PayMonthlyInvoice from "../../pages/payMonthlyInvoice/PayMonthlyInvoice";
import { DeviceManagementLayout } from "organisms/layouts/DeviceManagementLayout";
import EmployerGroupReporting from "pages/employerGroupReporting/EmployerGroupReporting";
import EmployerBenefitsEnrollment from "pages/employerBenefitsEnrollment/EmployerBenefitsEnrollment";
import ContactUsPage from "pages/contactUs/ContactUsPage";
import EmployerBillingStatement from "pages/employerBillingStatement/EmployerBillingStatement";
import RequestMemberIDCard from "pages/requestMemberIDCard/RequestMemberIDCard";
import ContactUsSuccess from "pages/contactUs/ContactUsSuccess";
import EnrollmentHistory from "pages/enrollmentHistory/EnrollmentHistory";
import EnrollmentHistoryInfo from "pages/enrollmentHistory/EnrollmentHistoryInfo";
import Dashboard from "pages/dashboard/Dashboard";

export const SecuredRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<AppLayout />}>
        <Route path={GROUP_INFORMATION} element={<GroupInformation />} />
        <Route path={GROUP_REPORTING} element={<EmployerGroupReporting />} />

        <Route
          path={ENROLL_IN_BENEFITS}
          element={<EmployerBenefitsEnrollment />}
        />
        <Route
          path={REQUEST_MEMBER_ID_CARD}
          element={<RequestMemberIDCard />}
        />
        <Route path={PAY_MONTHLY_INVOICE} element={<PayMonthlyInvoice />} />
        <Route
          path={DEVICE_MANAGEMENT_PORTAL}
          element={<DeviceManagementLayout />}
        />
        <Route path={CONTACTUS} element={<ContactUsPage />} />
        <Route path={CONTACTUS_SUCCESS} element={<ContactUsSuccess />} />
        <Route
          path={BILLING_STATEMENTS}
          element={<EmployerBillingStatement />}
        />
        <Route path={ENROLLMENT_HISTORY} element={<EnrollmentHistory />} />
        <Route
          path={ENROLLMENT_HISTORY_DETAILS}
          element={<EnrollmentHistoryInfo />}
        />

        <Route path="*" element={<Dashboard />}></Route>
      </Route>
    </Routes>
  );
};
