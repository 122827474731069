import { memo, useEffect, useRef } from "react";
import { Headings, Link } from "@sentaraui/optimahealth_web";
import { t } from "i18next";

interface SSONavigationContentProps {
  header: string;
  ssoUrl?: string;
  dataTestId: string;
  targetName: string;
}

const SSONavigationContent = ({
  header,
  ssoUrl,
  dataTestId,
  targetName
}: SSONavigationContentProps) => {
  const linEle = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (ssoUrl) {
      linEle?.current?.click();
    }
  }, [ssoUrl]);

  return (
    <div>
      <Headings
        dataTesId="ssoPageHeading"
        className="mb-4"
        level={1}
        text={header}
      />
      <div className="mb-5">
        {t("sso.linkContent")}
        <Link
          ref={linEle}
          to={ssoUrl ?? "/"}
          target={targetName}
          className="text-decoration-underline ms-1"
          data-testid={dataTestId}
        >
          {t("sso.clickHere")}
        </Link>
        .
      </div>
    </div>
  );
};

export default memo(SSONavigationContent);
