import { t } from "i18next";

import SearchListItem from "./SearchListItem";
import { MemberList, SelectedDepType } from "utils/models/CommonTypes";

type Props = {
  pageList?: MemberList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
};

const SearchTableMobile = ({
  pageList,
  selectedDep,
  page,
  setSelectedDep,
}: Props) => {
  return (
    <>
      {pageList ? (
        <SearchListItem
          {...{
            isMobile: true,
            pageList,
            selectedDep,
            page,
            setSelectedDep,
          }}
        />
      ) : (
        <div className="text-center">{t("noResult")}</div>
      )}
    </>
  );
};

export default SearchTableMobile;
