import { WebStorageStateStore } from "oidc-client-ts";

/** ADFS Meta Data: Used to in Login integration with MS ADFS/OIDC */
/** This metadata is placed here locally to fix the CORS issue we have in ADFS server 2016 */
/** Once the ADFS server upgraded to v2019 we may test and remove this */
import * as adfsMetaData from "./adfsMetaData.json";

/** Use TOKEN PROXY API Built by us to fix the CORS */
const _adfsMetaData = {
  ...adfsMetaData,
  token_endpoint:
    process.env.REACT_APP_OIDC_PROXY_BASE_URL +
    "/v2/adfs-token?subscription-key=" +
    process.env.REACT_APP_OPC_APIM_SUB_KEY,
};

export const oidcConfig = {
  authority: "https://sts.sentara.com/adfs/",
  redirect_uri: `${window.location.origin}/`,
  // redirect_uri: `https://dev.employer.avmed.com/`,
  // redirect_uri: `https://dev.employer.sentarahealthplans.com/`,
  // redirect_uri: `https://qa.employer.sentarahealthplans.com/`,
  response_type: "code",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  metadata: _adfsMetaData,
};
