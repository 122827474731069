import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserType } from "utils/models/CommonTypes";

interface AuthContextType {
  user?: UserType | null;
  token?: string;
  signOut: (to?: string) => void;
  updateUser: (v: object) => void;
  updateToken: (v: string) => void;
}

export const AuthContext = createContext<AuthContextType>({} as any);

interface Props {
  children?: React.ReactNode;
  user?: UserType;
}

export const AuthProvider = ({ children, user: puser }: Props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserType | null>(puser ?? null);
  const [token, setToken] = useState<string>("");

  const signOut = (to = "/logout") => {
    updateToken("");
    setUser(null);
    navigate(to);
  };

  const updateUser = (updatedInfo = {}) => {
    const _user = user ?? { loginID: "", isUnAuthorizedUser: false };
    setUser({ ..._user, ...updatedInfo });
  };

  const updateToken = (token: string) => {
    if (token) {
      setToken(token);
    } else {
      setToken("");
    }
  };

  const value = useMemo(
    () => ({
      user,
      token,
      updateUser,
      updateToken,
      signOut,
    }),
    [user, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
