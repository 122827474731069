import { useState, useEffect } from "react";
import { t } from "i18next";

import {
  useEmployerGroupARDetail,
} from "@sentara/sentara-api-hooks-core";

import PayMonthlyInvoiceDetails from "./PayMonthlyInvoiceDetails";
import PayMonthlyInvoicePayment from "./PayMonthlyInvoicePayment";
import PayMonthlyInvoiceHeader from "./PayMonthlyInvoiceHeader";
import PayMonthlyInvoiceConformation from "./PayMonthlyInvoiceConformation";
import PayMonthlyInvoiceReview from "./PayMonthlyInvoiceReview";
import { useAuth, useGlobalState } from "providers";
import { EmployerGroupInvoiceItem, EmployerGroupInvoiceItems } from "./interface";

interface ComponentProps {
  bankAccount: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  eftAmmount: string;
  note: string;
}

const PayMonthlyInvoice = () => {
  const { user } = useAuth();
  const [visible, setVisible] = useState("1");
  const [paymentConfirmationNumber, setPaymentConfirmationNumber] =
    useState("");
  const [selectedRecordsTopLevel, setSelectedRecordsTopLevel] =
    useState<EmployerGroupInvoiceItems>([]);
  const [
    employerGroupARDetailResponseData,
    setEmployerGroupARDetailResponseData,
  ] = useState<EmployerGroupInvoiceItems>([]);

  const { getEmployerGroupARDetail } = useEmployerGroupARDetail();
  const { setPageLoader } = useGlobalState();

  useEffect(() => {
    document.title = t("pageTitleADA.payMonthlyInvoice");
  }, []);

  const [componentProps, setComponentProps] = useState<ComponentProps>({
    bankAccount: "",
    routingNumber: "",
    accountNumber: "",
    accountType: "",
    eftAmmount: "",
    note: "",
  });

  const getLoggedInUsersGroupId = (): string[] => {
    const groupIDs: string[] = [];

    if (user?.loginID && user.groups) {
      user.groups.map((group) => {
        groupIDs.push(group.groupID);
      });
    }
    return groupIDs;
  };


  /**
 * Configures the invoice item with normalized values and determines if the invoice is payable.
 * 
 * This function handles invoice balance logic based on the following table:
 * 
 * | Outstanding Balance Amount (cr = >0, Dr = <0) | Invoice Number          | UI Display | Filtered by API | Select Option   |
 * |-----------------------------------------------|-------------------------|------------|-----------------|-----------------|
 * | Zero / -Ve                                    | Regular Invoice Number   | Yes        | No              | Disabled        |
 * | Credit                                        | Regular Invoice Number   | Yes        | No              | Enabled         |
 * | Zero                                          | "No Open Inv"            | No         | Yes             | Not Applicable  |
 * | Zero                                          | "OA*%"                   | No         | Yes             | Not Applicable  |
 * | -Ve                                           | "OA*%"                   | Yes        | No              | Disabled        |
 * | Credit                                        | "OA*%"                   | Yes        | No              | Disabled        |
 * | Zero / -Ve                                    | "M%"                     | Yes        | No              | Disabled        |
 * | Credit                                        | "M%"                     | Yes        | No              | Disabled        |
 * 
 * Notes:
 * - Outstanding Balance Amount: cr (>0) for credits, Dr (<0) for debits.
 * - UI Display: Determines if the UI should show this invoice number.
 * - Filtered by API: Indicates if this invoice number is filtered by the API.
 * - Select Option: Status of the select option (Enabled, Disabled, Not Applicable).
 * 
 * - cr = Credit (invoiceAmt), Dr = Debit (invoiceBal).
 * - InvoiceBalance = outstandingBalance
 * - InvoiceAmt = totalDue
 * - Credit is identified when `invoiceAmt` is positive; otherwise, use `invoiceBal`.
 * - See Ticket: EPP-1425 for more details.
 * 
 * @param {EmployerGroupInvoiceItem} item - The invoice item to be configured.
 * @param {number} index - The index of the invoice item in the list.
 * @returns {EmployerGroupInvoiceItem} - The updated invoice item with additional properties.
 */
  const configureInvoiceItem = (
    item: EmployerGroupInvoiceItem, 
    index: number
  ): EmployerGroupInvoiceItem => {
    
    // Utility function to normalize amounts
    const normalizeAmount = (amount: string): string => {
      if (amount == null || amount === "" || isNaN(Number(amount))) {
        return "0.00"; // Default to zero if the amount is null, undefined, empty, or invalid
      }
      return Number(amount) === 0 ? "0.00" : amount;
    };
  
    // Normalize invoice amount and balance
    item.invoiceAmt = normalizeAmount(item.invoiceAmt);
    item.invoiceBal = normalizeAmount(item.invoiceBal);
  
    // Utility function to check for zero balance
    const isZeroBalance = (balance: string): boolean => {
      const numericBalance = Number(balance); 
      return numericBalance <= 0;
    };  

    // Utility function to check for valid invoice number
    const isValidInvoiceNumber = (invoice: string): boolean => {
      if (!invoice) {
        return false; // Early return for null, undefined, or empty string
      }
      // const nonPayablePrefixes = ["OA*", "M"];
      const noOpenInvite = "No Open Inv";
      // const hasNonPayablePrefix = nonPayablePrefixes
      //   .some(prefix => invoice.toLowerCase().startsWith(prefix.toLowerCase()));
      
      return !(invoice.toLowerCase() === noOpenInvite.toLowerCase());
    };
  
    // Determine if the invoice is payable
    const checkInvoiceIsPayable = (item: EmployerGroupInvoiceItem): boolean => {
      if (!isValidInvoiceNumber(item.invoice)) {
        return false;
      }

      // if invoiceBal is zero, it's NOT payable
      if (isZeroBalance(item.invoiceBal)) {
        return false;
      }

      // if invoiceBal is zero, it's NOT payable
      if (isZeroBalance(item.invoiceAmt)) {
        return false;
      }
  
      return true;
    };
  
    const isInvoicePayable = checkInvoiceIsPayable(item);
    item.isInvoicePayable = isInvoicePayable;

    // Set additional properties
    item.uniqueIndexNumber = index;
    item.inputValidationError = "";
  
    return item;
  };

  useEffect(() => {
    const selectedGroupIds: string[] = getLoggedInUsersGroupId();
    setPageLoader("getEmployerGroupARDetail", true);
    getEmployerGroupARDetail({ groupID: selectedGroupIds }).then(
      (responseData) => {
        setPageLoader("getEmployerGroupARDetail", false);

        if (responseData?.data) {
          responseData.data.map(configureInvoiceItem);
          setEmployerGroupARDetailResponseData(responseData.data);
        }
      }
    );
  }, []);

  return (
    <div id="payMonthlyInvoiceContent">
      <PayMonthlyInvoiceHeader />
      {visible === "1" && (
        <PayMonthlyInvoiceDetails
          setVisible={setVisible}
          empGroupARDetailResponseData={employerGroupARDetailResponseData}
          setSelectedRecordsTopLevel={setSelectedRecordsTopLevel}
        />
      )}
      {visible === "2" && (
        <PayMonthlyInvoicePayment
          setVisible={setVisible}
          selectedRecordsTopLevel={selectedRecordsTopLevel}
          setSelectedRecordsTopLevel={setSelectedRecordsTopLevel}
          componentProps={componentProps}
          setComponentProps={setComponentProps}
        />
      )}
      {visible === "3" && (
        <PayMonthlyInvoiceReview
          setVisible={setVisible}
          selectedRecordsTopLevel={selectedRecordsTopLevel}
          componentProps={componentProps}
          setComponentProps={setComponentProps}
          setPaymentConfirmationNumber={setPaymentConfirmationNumber}
        />
      )}
      {visible === "4" && (
        <PayMonthlyInvoiceConformation
          setVisible={setVisible}
          selectedRecordsTopLevel={selectedRecordsTopLevel}
          componentProps={componentProps}
          setComponentProps={setComponentProps}
          paymentConfirmationNumber={paymentConfirmationNumber}
        />
      )}
    </div>
  );
};
export default PayMonthlyInvoice;
