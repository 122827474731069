import { SelectDropdown, InputField } from "@sentaraui/optimahealth_web";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";
import { FormError, GlobalError } from "../../organisms/form";
import EnrollmentHistorySubmit from "./EnrollmentHistorySubmit";
import {
  NameRegex,
  NumbersWithCharacters,
  NumericAsterisks,
  PolicyRegex,
  SSNRegex,
  SubscriberIDRegex,
} from "utils/constants/Regex";
import { VALIDATION_LENGTH } from "utils/constants/Constants";
import { NameChange, ssnOncheck } from "../../utils/helpers/HelperFn";
import { useNavigate } from "react-router";
import { ENROLLMENT_HISTORY } from "routes";
import { useAuth } from "providers";
import { useState } from "react";

interface EnrollmentHistoryFormProps {
  setError: Function;
}
function EnrollmentHistoryForm({ setError }: EnrollmentHistoryFormProps) {
  const { register, reset, resetField, formState, getValues, trigger, setFocus } =
    useFormContext();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedGroupID, setSelectedGroupID] = useState('');

  //For reseting the form
  const handleClearForm = () => {
    reset({
      groupID: "",
      familyID: "",
      memberID: "",
      ssn: "",
      firstName: "",
      lastName: "",
    });
    setError(false);
    navigate(`/app/${ENROLLMENT_HISTORY}`);
    setSelectedGroupID('');
  };
  const searchByValue = () => {
    const { groupID, searchInput, ...rest } = getValues();
    const result: any = [];
    Object.values(rest).forEach((value) => {
      if (value) {
        result.push(value);
      }
    });

    if (!result.length) {
      return t("enrollmentHistoryForm.validation.eitherOneSelected");
    }
  };

  register("searchInput", { validate: searchByValue });
  const validateFirstName = () => {
    if (getValues("firstName") && getValues("lastName") === "") {
      return t("requestMemberIDCard.validationLastname");
    }
  };

  register("lastName", { validate: validateFirstName });

  return (
    <div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="mb-3">
            <GlobalError name="searchInput" tabIndex={-1} />
          </div>
          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="selectAGroup"
              data-testid="lblGroupNumber"
            >
              {t("enrollmentHistoryForm.groupNameLabel")}
            </label>
            <div>
              <SelectDropdown
                dataTestId="optSelectAGroup"
                {...register("groupID", {
                  //required: t("enrollmentHistoryForm.selectOption"),
                })}
                className={
                  formState.errors.groupID?.message
                    ? " form-select error-input-bdr"
                    : "form-select"
                }
                id="selectAGroup"
                value={selectedGroupID}
                onChange={(e: any) => setSelectedGroupID(e.target.value)}
                aria-describedby="select_group_error"
              >
                <option value="">{t("requestMemberIDCard.allgroups")}</option>
                {user?.groups?.map((item) => {
                  return (
                    <option key={`${item.groupID}`} value={item.groupID}>
                      {item.groupID} {item.groupName}
                    </option>
                  );
                })}
              </SelectDropdown>
              <div
                className={formState.errors.groupID?.message ? "" : "d-none"}
              >
                <div
                  id="select_group_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="groupID" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="EnrollmentHistoryPolicyId"
              data-testid="lblPolicyId"
            >
              {t("enrollmentHistoryForm.policyIdLabel")}
            </label>
            <div>
              <InputField
                pattern={NumbersWithCharacters}
                maxLength={VALIDATION_LENGTH.POLICY_ID_MAX_LENGTH}
                {...register("familyID", {
                  pattern: {
                    value: PolicyRegex,
                    message: t("enrollmentHistoryForm.validationPolicyID"),
                  },
                  onChange: () => {
                    trigger("searchInput");
                    resetField("firstName");
                    resetField("lastName");
                    resetField("memberID");
                    resetField("ssn");
                    setError(false);
                  },
                })}
                className={
                  formState.errors.familyID?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("enrollmentHistoryForm.plcHolderPolicyId")}
                dataTestId="inputPolicyId"
                id="EnrollmentHistoryPolicyId"
                aria-describedby="policy_id__error"
              />
              <div
                className={formState.errors.familyID?.message ? "" : "d-none"}
              >
                <div
                  id="policy_id__error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="familyID" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="member_id"
              data-testid="lblMemberId"
            >
              {t("enrollmentHistoryForm.MemberIdLabel")}
            </label>
            <div>
              <InputField
                maxLength={VALIDATION_LENGTH.MEMBER_ID_MAX_LENGTH}
                pattern={NumericAsterisks}
                {...register("memberID", {
                  minLength: {
                    value: VALIDATION_LENGTH.MEMBER_NUMBER_LENGTH,
                    message: t("enrollmentHistoryForm.memberNumberLength"),
                  },
                  pattern: {
                    value: SubscriberIDRegex,
                    message: t("enrollmentHistoryForm.validateMemberID"),
                  },
                  onChange: () => {
                    trigger("searchInput");
                    resetField("firstName");
                    resetField("lastName");
                    resetField("familyID");
                    resetField("ssn");
                    setError(false);
                  },
                })}
                className={
                  formState.errors.memberID?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("enrollmentHistoryForm.plcHolderMemberId")}
                dataTestId="inputMemberId"
                id="member_id"
                aria-describedby="member_id_error"
              />
              <div
                className={formState.errors.memberID?.message ? "" : "d-none"}
              >
                <div id="member_id_error" aria-live="polite" aria-atomic="true">
                  <FormError name="memberID" />
                </div>
              </div>
              <p className="granite-gray-color pt-1">
                <small>{t("enrollmentHistoryForm.includeAsterisk")}</small>
              </p>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="ss_number"
              data-testid="lblSubscriberSSN"
            >
              {t("enrollmentHistoryForm.sssnLabel")}
            </label>
            <div>
              <InputField
                maxLength={VALIDATION_LENGTH.SSN_MIN_LENGTH}
                {...register("ssn", {
                  minLength: {
                    value: VALIDATION_LENGTH.SSN_MIN_LENGTH,
                    message: t("enrollmentHistoryForm.SSNLength"),
                  },
                  setValueAs: (v) => ssnOncheck(v),
                  onChange: (e) => {
                    e.target.value = ssnOncheck(e.target.value);
                    trigger("searchInput");
                    resetField("firstName");
                    resetField("lastName");
                    resetField("familyID");
                    resetField("memberID");
                    setError(false);
                  },
                  pattern: {
                    value: SSNRegex,
                    message: t("enrollmentHistoryForm.SSNLength"),
                  },
                })}
                forceNumber={true}
                className={
                  formState.errors.ssn?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("enrollmentHistoryForm.plcSSSNumber")}
                dataTestId="inputSSN"
                id="ss_number"
                aria-describedby="ss_number_error"
              />
              <div className={formState.errors.ssn?.message ? "" : "d-none"}>
                <div id="ss_number_error" aria-live="polite" aria-atomic="true">
                  <FormError name="ssn" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="member_last_name"
              data-testid="lblMemberLastName"
            >
              {t("enrollmentHistoryForm.lastNameLabel")}
            </label>
            <div>
              <InputField
                pattern={NameRegex}
                maxLength={VALIDATION_LENGTH.NAME_MAX_LENGTH}
                type="text"
                forceChar={true}
                {...register("lastName", {
                  onChange: (e) => {
                    NameChange(e);
                    trigger("lastName");
                    trigger("searchInput");
                    resetField("ssn");
                    resetField("familyID");
                    resetField("memberID");
                    setError(false);
                  },
                })}
                className={
                  formState.errors.lastName?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("enrollmentHistoryForm.plcHolderLastName")}
                dataTestId="inputMemberLastName"
                id="member_last_name"
                aria-describedby="member_last_name_error"
              />
              <div
                className={formState.errors.lastName?.message ? "" : "d-none"}
              >
                <div
                  id="member_last_name_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="lastName" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="member_first_name"
              data-testid="lblMemberFirstName"
            >
              {t("enrollmentHistoryForm.firstNameLabel")}
            </label>
            <div>
              <InputField
                pattern={NameRegex}
                maxLength={VALIDATION_LENGTH.NAME_MAX_LENGTH}
                {...register("firstName", {
                  onChange: (e) => {
                    NameChange(e);
                    trigger("lastName");
                    resetField("familyID");
                    resetField("memberID");
                    resetField("ssn");
                    setError(false);
                  },
                })}
                className={
                  formState.errors.firstName?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("enrollmentHistoryForm.plcHolderFirstName")}
                dataTestId="inputMemberFirstName"
                id="member_first_name"
                aria-describedby="member_first_name_error"
              />
              <div
                className={formState.errors.firstName?.message ? "" : "d-none"}
              >
                <div
                  id="member_first_name_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="firstName" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-0 mb-4" />
      <EnrollmentHistorySubmit
        isFormDirty={
          formState?.dirtyFields["memberID"] ||
          formState?.dirtyFields["lastName"] ||
          formState?.dirtyFields["firstName"] ||
          formState?.dirtyFields["ssn"] ||
          formState?.dirtyFields["familyID"]
        }
        handleClearForm={handleClearForm}
      />
    </div>
  );
}

export default EnrollmentHistoryForm;
