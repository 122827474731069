import { useTranslation } from "react-i18next";
import {
  Headings,
  InputField,
  Label,
  PrimaryButton,
  ErrorMessage,
  AccordionTable,
} from "@sentaraui/optimahealth_web";
import { DeviceTypes, VALIDATION_LENGTH } from "utils/constants/Constants";
import { usePayMonthlyInvoiceConfirmation } from "./hooks/usePayMonthlyInvoiceConfirmation";
import { PaymentProps } from "./interface";
import { maskConfirmationNumber } from "utils/helpers/HelperFn";
import { useEffect } from "react";
import { useGlobalState } from "providers";
import { ModalOverlayPopUp } from "organisms/popup/ModalOverlayPopUp";

const PayMonthlyInvoiceConformation = (props: PaymentProps) => {
  const { t } = useTranslation();
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  const {
    showError,
    errorMessage,
    btnDisabled,
    emailId,
    totalEtfDraftAmount,
    onChangeEmail,
    handleSendEmailManually,
    sendEmailOnLoad,
    emailConfirmationPopup,
    handlePopupClose,
    getLoggedInUsersEmailId,
    updatedEmailId,
    inputRef,
  } = usePayMonthlyInvoiceConfirmation(props);

  useEffect(() => {
    // Send confirmation email on load.
    sendEmailOnLoad();
  }, []);

  return (
    <div>
      <Headings
        className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
        level={2}
        text={t("payMonthlyInvoice.ConfirmationHeader")}
      />
      <p>
        {t("payMonthlyInvoice.deductionText1")}{" "}
        {maskConfirmationNumber(props.componentProps.accountNumber)}{" "}
        {t("payMonthlyInvoice.deductionText2")}
      </p>
      {!isMobile && (
        <div className="table-responsive">
          <table
            className="table table-custom-style align-middle"
            data-testid="tblPayMonthlyInvoiceConfirmation"
          >
            <thead>
              <tr>
                <th scope="col">{t("payMonthlyInvoice.paymentMonth")}</th>
                <th scope="col">{t("payMonthlyInvoice.groupNumber")}</th>
                <th scope="col">{t("payMonthlyInvoice.groupName")}</th>
                <th scope="col">{t("payMonthlyInvoice.invoiceNumber")}</th>
                <th scope="col">{t("payMonthlyInvoice.draftAmount")}</th>
              </tr>
            </thead>
            <tbody>
              {props.selectedRecordsTopLevel?.map((selectedRecord, indx) => (
                <tr key={"row-index-" + indx}>
                  <td>{selectedRecord.invoiceDate}</td>
                  <td>{selectedRecord.groupID}</td>
                  <td>{selectedRecord.groupName}</td>
                  <td>{selectedRecord.invoice}</td>
                  <td>${selectedRecord.etfDraftAmount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  <strong>{t("payMonthlyInvoice.totalDraftAccount")}</strong>
                </td>
                <td>
                  <strong>${totalEtfDraftAmount}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      {isMobile && (
        <div className="mb-4">
          <AccordionTable
            initialActiveIndex={0}
            id="pay_monthly_invoice_payment_confirm_table"
            items={props.selectedRecordsTopLevel?.map(
              (selectedRecord, indx) => ({
                title: (
                  <div>
                    <div>{t("payMonthlyInvoice.paymentMonth")}</div>
                    <div className="redhat_regular">
                      {selectedRecord.invoiceDate
                        ? selectedRecord.invoiceDate
                        : t("payMonthlyInvoice.noOpenInv")}
                    </div>
                  </div>
                ),
                id: "pay_monthly_invoice_payment_confirm_table_data",
                content: (
                  <>
                    <div className="mb-3">
                      <div>
                        <strong>{t("payMonthlyInvoice.groupNumber")}</strong>
                      </div>
                      <div>{selectedRecord.groupID}</div>
                    </div>
                    <div className="mb-3">
                      <div>
                        <strong>{t("payMonthlyInvoice.groupName")}</strong>
                      </div>
                      <div>{selectedRecord.groupName}</div>
                    </div>
                    <div className="mb-3">
                      <div>
                        <strong>{t("payMonthlyInvoice.invoiceNumber")}</strong>
                      </div>
                      <div>{selectedRecord.invoice}</div>
                    </div>
                    <div className="mb-3">
                      <div>
                        <strong>{t("payMonthlyInvoice.draftAmount")}</strong>
                      </div>
                      <div>${selectedRecord.etfDraftAmount}</div>
                    </div>
                  </>
                ),
              })
            )}
          />
          <div className="pt-4">
            <strong>{t("payMonthlyInvoice.totalDraftAccount")}</strong>
          </div>
          <div>${totalEtfDraftAmount}</div>
        </div>
      )}
      <hr className="mt-0 mb-4" />

      <div className="d-flex flex-column flex-lg-row flex-md-row gap-0 gap-lg-3 gap-md-3 mb-4">
        <strong data-testid="txttransactionConfirmationLabel">
          {t("payMonthlyInvoice.transactionConfirmation")}
        </strong>{" "}
        <span data-testid="txttransactionConfirmationValue">
          {props.paymentConfirmationNumber}
        </span>
      </div>
      <hr className="mt-0 mb-4" />
      <p>
        {t("payMonthlyInvoice.printcopynote1")}
        {getLoggedInUsersEmailId()}. {t("payMonthlyInvoice.printcopynote2")}
      </p>

      {/* Email confirmation popup */}
      {emailConfirmationPopup && (
        <ModalOverlayPopUp
          open={emailConfirmationPopup}
          callBack={() => handlePopupClose()}
          title={t("payMonthlyInvoiceConfirmation.modalTitle")}
          displayButton={false}
          content={t("payMonthlyInvoiceConfirmation.modalDescription", {
            emailTo: updatedEmailId,
          })}
        />
      )}

      <div className="row">
        <div className="col-lg-8 col-md-9 col-sm-12 col-12 mb-4">
          <div>
            <Label
              className="pb-1"
              data-testid="lblpayMonthlyInvoiceEmail"
              htmlFor="inputEmail"
            >
              {t("payMonthlyInvoice.emailLabel")}
            </Label>
          </div>
          <div>
            <InputField
              className={showError ? "form-input error-border" : "form-input"}
              type="text"
              maxLength={VALIDATION_LENGTH.EMAIL_MAX_LENGTH}
              id="inputEmail"
              placeholder={t("payMonthlyInvoice.emailPlcHolder")}
              data-testid="inputPayMonthlyInvoiceEmail"
              aria-describedby="pay_monthly_invoice_email_error"
              ref={inputRef}
              onChange={onChangeEmail}
            />
          </div>
          <div className={showError ? "" : "d-none"}>
            <div
              id="pay_monthly_invoice_email_error"
              aria-live="polite"
              aria-atomic="true"
            >
              {showError && (
                <ErrorMessage data-testid="payMonthlyInvoiceEmailError">
                  {errorMessage}
                </ErrorMessage>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <PrimaryButton
          data-testid="btnSendEmail"
          id="primary"
          type="submit"
          className={
            btnDisabled || !emailId
              ? "primary_btn btn_block_mob disabled_btn"
              : "primary_btn btn_block_mob"
          }
          onClick={() => handleSendEmailManually()}
        >
          {t("payMonthlyInvoice.sendEmail")}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default PayMonthlyInvoiceConformation;
