import SSONavigationContent from "organisms/ssoNavigationContent/SSONavigationContent";
import { t } from "i18next";

import { useEffect, useState } from "react";
import {
  useEmployerGroupReport,
  EmployerGroupReportingUserType,
} from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const EmployerGroupReporting = () => {
  const { getEmployerGroupReport } = useEmployerGroupReport();
  const [groupReportSSO, setGroupReportSSO] = useState("");
  const { user } = useAuth();
  useEffect(() => {
    document.title = t("pageTitleADA.groupReporting");
    fetchSSO();
  }, []);
  const fetchSSO = async () => {
    if (user?.loginID && user?.person?.personID) {
      const response = await getEmployerGroupReport({
        loginId: user?.loginID,
        personId: user?.person?.personID,
        userType: EmployerGroupReportingUserType.E,
      });
      if (response?.data) {
        setGroupReportSSO(response?.data?.result?.ssoUrl);
      }
    }
  };

  return (
    <div id="groupReportingContent">
      <SSONavigationContent
        header={t("sso.header.groupReporting")}
        ssoUrl={groupReportSSO}
        dataTestId="ssoLinkGroupReporting"
        targetName="employerPortalEmployerGroupReporting"
      />
    </div>
  );
};
export default EmployerGroupReporting;
