import React, { useState } from "react";
import { PayMonthlyInvoiceProps } from "../interface";

export const usePayMonthlyInvoiceDetails = ({
  setVisible,
  empGroupARDetailResponseData,
  setSelectedRecordsTopLevel,
}: PayMonthlyInvoiceProps) => {
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);
  const [
    isShowMinRecordsNeedsToBeSelectedError,
    setIsShowMinRecordsNeedsToBeSelectedError,
  ] = useState<boolean>(false);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);

  const handleChkSelectAllClick = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSelectAllChecked(evt?.target?.checked);

    if (evt?.target?.checked) {
      const filteredItems = empGroupARDetailResponseData.filter(item => item.isInvoicePayable);
      setSelectedRecords(filteredItems);
    } else {
      setSelectedRecords([]);
    }
  };

  const handleBtnContinueClick = () => {
    if (selectedRecords.length > 0) {
      setIsShowMinRecordsNeedsToBeSelectedError(false);
      const sortedRecords = [...selectedRecords].sort((a, b) =>
        a.groupID.localeCompare(b.groupID)
      );
      setSelectedRecordsTopLevel(sortedRecords);
      setVisible("2");
    } else {
      setIsShowMinRecordsNeedsToBeSelectedError(true);
    }
  };

  return {
    empGroupARDetailResponseData,
    handleBtnContinueClick,
    handleChkSelectAllClick,
    isSelectAllChecked,
    selectedRecords,
    setIsSelectAllChecked,
    setSelectedRecords,
    isShowMinRecordsNeedsToBeSelectedError,
    setIsShowMinRecordsNeedsToBeSelectedError,
  };
};
