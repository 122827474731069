import { useState } from "react";
import {
  EmployerInsuranceGroupFileRequest,
  EmployerInsuranceGroupFileType,
  useEmployerInsuranceGroupFile,
  useBenefitSummaryUSBCFile,
} from "@sentara/sentara-api-hooks-core";
import { useGlobalState } from "providers";
import { pdfConverter } from "utils/helpers/HelperFn";
import { BenefitSummaryUSBCFileProps } from "../interface";
import { t } from "i18next";

export const useGroupInformation = () => {
  const { getEmployerInsuranceGroupFile } = useEmployerInsuranceGroupFile();
  const { getBenefitSummaryUSBCFile } = useBenefitSummaryUSBCFile();
  const { setPageLoader } = useGlobalState();

  const [visibleSOB, setVisibleSOB] = useState(false);
  const [sobFileUrl, setSobFileUrl] = useState("");

  const [groupPolicyInfoFileURL, setGroupPolicyInfoFileURL] =
    useState<string>("");
  const [showBtnGroupPolicyInfoFile, setShowBtnGroupPolicyInfoFile] =
    useState<boolean>(false);

  const [contractAmendmentFileURL, setContractAmendmentFileURL] =
    useState<string>("");
  const [showBtnContractAmendment, setShowBtnContractAmendment] =
    useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<{
    open: boolean;
    content?: string;
    title?: string;
  }>({ open: false, content: "", title: "" });
  const handleViewButtonClick = (
    fileType: EmployerInsuranceGroupFileType,
    fileURL: string
  ) => {
    const req: EmployerInsuranceGroupFileRequest = {
      fileType: fileType,
      fileURL: fileURL,
    };

    setPageLoader("getEmployerInsuranceGroupFile", true);
    getEmployerInsuranceGroupFile(req).then((response) => {
      setPageLoader("getEmployerInsuranceGroupFile", false);
      if (response?.data?.data) {
        pdfConverter(response.data.data);
      }
    });
  };

  const handleBtnBeneficiaryUSBCFileClick = (
    item: BenefitSummaryUSBCFileProps
  ) => {
    const req: BenefitSummaryUSBCFileProps = {
      groupID: item.groupID,
      effDate: item.effDate,
    };

    setPageLoader("getBenefitSummaryUSBCFile", true);

    getBenefitSummaryUSBCFile(req).then((response) => {
      setPageLoader("getBenefitSummaryUSBCFile", false);
      if (response?.data?.data) {
        pdfConverter(response.data.data);
      } else {
        setOpenInfo({
          open: true,
          content: t("featureUnavailable"),
        });
      }
    });
  };

  return {
    handleViewButtonClick,
    handleBtnBeneficiaryUSBCFileClick,
    groupPolicyInfoFileURL,
    setGroupPolicyInfoFileURL,
    showBtnGroupPolicyInfoFile,
    setShowBtnGroupPolicyInfoFile,
    showBtnContractAmendment,
    setShowBtnContractAmendment,
    contractAmendmentFileURL,
    setContractAmendmentFileURL,
    visibleSOB,
    setVisibleSOB,
    sobFileUrl,
    setSobFileUrl,
    openInfo,
    setOpenInfo,
  };
};
