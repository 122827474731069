import { t } from "i18next";
import SearchListItem from "./SearchListItem";
import { MemberList, SelectedDepType } from "utils/models/CommonTypes";

type Props = {
  pageList?: MemberList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
};

const SearchTableDeskTop = ({
  pageList,
  selectedDep,
  page,
  setSelectedDep,
}: Props) => {
  return (
    <div className="table-responsive">
      <table
        className="table table-custom-style align-middle"
        data-testid="tblRequestMemberIdCardSearchResults"
      >
        <thead>
          <tr>
            <th scope="col" className="text-center">
              {t("payMonthlyInvoice.select")}{" "}
            </th>
            <th scope="col">{t("enrollmentHistoryForm.memberId")}</th>
            <th scope="col">{t("enrollmentHistoryForm.groupNumber")}</th>
            <th scope="col">{t("enrollmentHistoryForm.name")}</th>
            <th scope="col">{t("enrollmentHistoryForm.relationship")}</th>
          </tr>
        </thead>
        <tbody>
          {pageList ? (
            <SearchListItem
              {...{
                pageList,
                selectedDep,
                page,
                setSelectedDep,
              }}
            />
          ) : (
            <tr className="text-center">
              <td colSpan={5}>{t("noResult")}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SearchTableDeskTop;
