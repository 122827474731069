import { Headings } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";

export default function RequestMemberIDCardHeader() {
  const { t } = useTranslation();

  return (
    <Headings
      className="fw-600 mb-4"
      level={1}
      text={t("requestMemberIDCard.header")}
      dataTestId="txtRequestMemberIdCardTitle"
    />
  );
}
