import "./assets/styles/Main.scss";
import "bootstrap/dist/js/bootstrap.js";

import { AppRoutes } from "./routes";
import {
  AuthProvider,
  GlobalStateProvider,
  BrandingProvider,
} from "./providers";

function App() {
  return (
    <BrandingProvider>
      <GlobalStateProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </GlobalStateProvider>
    </BrandingProvider>
  );
}

export default App;
