import { PrimaryButton, SecondaryButton } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";

interface EnrollmentHistorySubmitProps {
  handleClearForm: Function;
  isFormDirty: boolean
}

const EnrollmentHistorySubmit = ({ handleClearForm, isFormDirty }: EnrollmentHistorySubmitProps) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-3">
      <PrimaryButton
        type="submit"
        data-testid="btnSearch"
        className={
          isFormDirty
            ? "primary_btn btn_block_mob"
            : "primary_btn disabled_btn btn_block_mob"
        }
        aria-label={isFormDirty ? t("enrollmentHistoryForm.btnSearch") : t("enrollmentHistoryForm.btnSearchDisabled")}
      >
        {t("enrollmentHistoryForm.btnSearch")}
      </PrimaryButton>
      <SecondaryButton
        type="button"
        data-testid="btnClear"
        className={"secondary_btn btn_block_mob"}
        onClick={handleClearForm}
      >
        {t("enrollmentHistoryForm.btnClear")}
      </SecondaryButton>
    </div>
  )
}
export default EnrollmentHistorySubmit;
