import { useEffect, useState } from "react";
import { t } from "i18next";

import { Headings } from "@sentaraui/optimahealth_web";
import {
  MemberSearchResponse,
  useEmployerMemberSearch,
} from "@sentara/sentara-api-hooks-core";

import { Form } from "organisms/form/Form";
import RequestMemberIDCardForm from "./RequestMemberIDCardForm";
import RequestMemberIDCardSearch from "./RequestMemberIDCardSearch";
import RequestMemberIDCardHeader from "./RequestMemberIDCardHeader";
import { useSearchParams } from "react-router-dom";
import { useAuth, useGlobalState } from "providers";
import { listPerTable } from "utils/constants/Constants";
import { ErrorBy } from "organisms/form";

export default function RequestMemberIDCard() {
  const { setPageLoader } = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const { getMemberSearch } = useEmployerMemberSearch();
  const [memberList, setMemberList] = useState<MemberSearchResponse | null>(
    null
  );

  const defaultValues = {
    groupId: searchParams.get("groupId") ?? "",
    memberId: searchParams.get("memberId") ?? "",
    subscriberSecurityNumber:
      searchParams.get("subscriberSecurityNumber") ?? "",
    firstName: searchParams.get("firstName") ?? "",
    lastName: searchParams.get("lastName") ?? "",
    checkboxAllMembers: !!["true", "on"].includes(
      searchParams.get("checkboxAllMembers") ?? ""
    ),
  };
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    document.title = t("pageTitleADA.requestMemberIDCard");
    if (
      searchParams.get("memberId") ||
      searchParams.get("subscriberSecurityNumber") ||
      searchParams.get("lastName") ||
      searchParams.get("checkboxAllMembers")
    ) {
      const params: any = {};
      params["skip"] = 0;
      params["take"] = listPerTable * 10;
      searchParams.forEach((item: string, key: string) => {
        params[key] = item;
      });
      if (!params.groupId) {
        const groups: Array<string> = [];
        user?.groups?.forEach((element) => {
          groups.push(element.groupID.toString());
        });
        params.groupId = groups.join(",");
      }
      if (params.subscriberSecurityNumber) {
        params.subscriberSecurityNumber =
          params.subscriberSecurityNumber.replaceAll("-", "");
      }
      setPageLoader("eligilitySearch", true);
      getMemberSearch(params).then((response: any) => {
        if (response?.data?.Result?.length > 0) {
          setMemberList(response.data);
          setError(false);
        } else {
          setMemberList(null);
          setError(true);
        }
        setIsSubmited(false);
        setPageLoader("eligilitySearch", false);
      });
    } else {
      setIsSubmited(false);
    }
  }, [searchParams]);

  // useEffect(() => {
  //   if (apiStatus === "success") {
  //     const ele = document.querySelector(
  //       memberList?.Result.length ? ".table-responsive" : ".alert-global"
  //     ) as HTMLDivElement;
  //     ele?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [apiStatus]);

  const onSubmit = (formData: any) => {
    const { searchInput, ...rest } = formData;
    const search = { ...rest };
    for (const i in search) {
      if (searchParams.get(i) !== search[i]) {
        setIsSubmited(true);
        setSearchParams(search);
        break;
      }
    }
  };

  return (
    <div id="requestMemberIDCardContent">
      <RequestMemberIDCardHeader />
      {!isSubmited &&
        memberList?.Result.length &&
        (searchParams.get("memberId") ||
          searchParams.get("subscriberSecurityNumber") ||
          searchParams.get("lastName") ||
          searchParams.get("checkboxAllMembers")) ? (
        <RequestMemberIDCardSearch memberList={memberList} />
      ) : (
        <div>
          <Headings
            className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
            level={2}
            text={t("requestMemberIDCard.subHeader")}
            dataTestId="lblRequestMemberIdCardSearchTitle"
          />
          <div className="d-flex flex-column gap-3 p-3 border border-radius10 mb-4 light-grey-bg">
            {t("requestMemberIDCard.subHeaderContent")}
            <p className="mb-0">
              <strong>{t("enrollmentHistoryForm.subHeaderContent1")}</strong>
            </p>

            <p className="mb-0">{t("requestMemberIDCard.subHeaderContent2")}</p>
          </div>
          <ErrorBy
            isShow={!!(!isSubmited && !memberList?.Result.length && error)}
            message={t("unableToLocate")}
          />
          <Form defaultValues={defaultValues} onSubmit={onSubmit}>
            <RequestMemberIDCardForm setError={setError} />
          </Form>
          <p className="mt-4 mb-4">
            {t("enrollmentHistoryForm.matchCriteriaText")}
          </p>
        </div>
      )}
    </div>
  );
}
