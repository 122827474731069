import { useTranslation } from "react-i18next";
import { ButtonLink, Image, MailIcon, Link } from "@sentaraui/optimahealth_web";
import { useNavigate } from "react-router";

import { EMPLOYER_DASHBOARD } from "../../routes";
import { ContactLink } from "../../utils/constants/Navigation";
import { Brands, useAuth, useBranding } from "../../providers";
import LogoImg from "../../assets/images/sentara_logo.svg";
import AvMedLogoImg from "../../assets/images/avmed_logo.svg";
import HamburgerMenu from "../hamburgerMenu/HamburgerMenu";
import { ProfileMenu } from "../profile/ProfileMenu";
import { HeaderTopNav } from "../headerTopNav/HeaderTopNav";

export const AppHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuth();

  return (
    <header className="not-print">
      {user?.person && (
        <div className="border-bottom d-none d-xl-block d-lg-block">
          <div className="container-fluid">
            <HeaderTopNav />
          </div>
        </div>
      )}

      <div className="">
        <div className="container-fluid">
          <div>
            <nav className="d-flex align-items-center justify-content-between pt-3 mb-4 mt-1 pb-2">
              <div>
                <HeaderLogo />
              </div>
              <div>
                <div className="d-flex flex-no-wrap align-items-center">
                  <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                    <div className="dark-gray-color">
                      <ButtonLink
                        isHeader={true}
                        buttonText={t("contactUs")}
                        dataTestId="btnContactUs"
                        icon={
                          <MailIcon
                            width={16}
                            height={16}
                            dataTestId="icoMailIcon"
                          />
                        }
                        onClick={() => {
                          navigate(ContactLink);
                        }}
                        ariaDescribedBy="contactUsContent"
                      />
                    </div>
                  </div>
                  <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                    {user?.person && <ProfileMenu />}
                  </div>
                  <div className="d-lg-none d-md-block d-sm-block">
                    <HamburgerMenu />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <hr className="d-block d-md-none d-lg-block mt-0" />
      </div>
    </header>
  );
};

interface HeaderLogoProps {
  idFromAuth?: boolean;
}

export const HeaderLogo = ({ idFromAuth }: HeaderLogoProps) => {
  const { brandConfig } = useBranding();

  const logoImagePath =
    brandConfig.brandName === Brands.AVMED ? AvMedLogoImg : LogoImg;

  const { t } = useTranslation();
  return (
    <a
      href={idFromAuth ? brandConfig.homeLink : `/app/${EMPLOYER_DASHBOARD}`}
      className="logo-img"
    >
      <Image
        dataTestId={"imgBrokerPortalLogo"}
        path={logoImagePath}
        alttext={t("appLogo")}
        className="img-responsive"
      />
    </a >
  );
};
