import React, { useEffect, useState } from "react";
import { PayMonthlyInvoicePaymentProps } from "../interface";
import BigNumber from "bignumber.js";
import { PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE } from "utils/constants/Constants";

export const usePayMonthlyInvoicePayment = ({
  setVisible,
  selectedRecordsTopLevel,
  setSelectedRecordsTopLevel,
  componentProps,
  setComponentProps,
}: PayMonthlyInvoicePaymentProps) => {
  const [eftAmount, setEftAmount] = useState("0.00");
  const [isInvalidInputErrorExist, setIsInvalidInputErrorExist] =
    useState(false);

  const formatOnBlur = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = evt.target.value || 0;
    if (targetValue === 0 || targetValue === "0") {
      evt.target.value = "0.00";
    }

    if (!isNaN(Number(targetValue))) {
      evt.target.value = new BigNumber(targetValue).toFixed(2);
    }
  };

  const handleInputChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    indx: number
  ) => {
    const targetValue = evt.target.value || "0";

    if (!Number(targetValue) && targetValue !== "0") {
      selectedRecordsTopLevel[indx].inputValidationError =
        PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.INVALID_INPUT;
      setIsInvalidInputErrorExist(true);
    } else if (Number(targetValue) < 0) {
      selectedRecordsTopLevel[indx].inputValidationError =
        PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.VALUE_LESS_THAN_0;
      setIsInvalidInputErrorExist(true);
    } else {
      let roundedValue = new BigNumber(targetValue).toFixed(2);
      let invoiceBalance = new BigNumber(
        selectedRecordsTopLevel[indx].invoiceBal
      ).toFixed(2);

      if (
        new BigNumber(roundedValue).isGreaterThan(new BigNumber(invoiceBalance))
      ) {
        selectedRecordsTopLevel[indx].inputValidationError =
          PAY_MONTHLY_INVOICE_INPUT_ERROR_TYPE.OVER_PAYMENT;
        setIsInvalidInputErrorExist(true);
      } else {
        selectedRecordsTopLevel[indx].inputValidationError = "";
        setIsInvalidInputErrorExist(false);

        let enteredValue = new BigNumber(targetValue);
        selectedRecordsTopLevel[indx].etfDraftAmount = enteredValue.toFixed(2);

        let totalAmount = new BigNumber(0);
        selectedRecordsTopLevel.map((item) => {
          totalAmount = totalAmount.plus(item?.etfDraftAmount || "0");
        });

        setEftAmount(totalAmount.toFixed(2));
      }
    }
    setSelectedRecordsTopLevel([...selectedRecordsTopLevel]);
  };

  useEffect(() => {
    let totalAmount = new BigNumber(0);
    selectedRecordsTopLevel.map((item, indx) => {
      totalAmount = totalAmount.plus(item?.etfDraftAmount || "0");
    });

    setEftAmount(totalAmount.toFixed(2));
  }, [selectedRecordsTopLevel]);

  const defaultValues = {
    bankName: componentProps.bankAccount ?? "",
    routingNumber: componentProps.routingNumber ?? "",
    accountNumber: componentProps.accountNumber ?? "",
    accountType: componentProps.accountType || "Checking",
    totalEFTDraftAmount: componentProps.eftAmmount ?? "",
    note: componentProps.note ?? "",
  };

  const checkIsInputErrors = () => {
    let isErrorExist = false;
    selectedRecordsTopLevel.map((selectedRecord) => {
      if (selectedRecord.inputValidationError) {
        isErrorExist = true;
      }
    });
    return isErrorExist;
  };

  const onSubmit = (formData: any) => {
    if (checkIsInputErrors()) {
      setIsInvalidInputErrorExist(true);
      return false;
    } else {
      setIsInvalidInputErrorExist(false);
      setComponentProps({
        bankAccount: formData.bankName,
        routingNumber: formData.routingNumber,
        accountNumber: formData.accountNumber,
        accountType: formData.accountType,
        eftAmmount: eftAmount,
        note: formData.note,
      });

      // Show review payment screen.
      setVisible("3");
    }
  };

  return {
    defaultValues,
    eftAmount,
    handleInputChange,
    onSubmit,
    isInvalidInputErrorExist,
    formatOnBlur,
    checkIsInputErrors,
  };
};
