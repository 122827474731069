import { Relationship } from "@sentara/sentara-api-hooks-core";
import { EMPLOYER_RELATIONSHIP_CODE } from "utils/constants/Constants";
import { EmailRegex } from "utils/constants/Regex";

export const focusTo = () => {
  setTimeout(() => {
    const element = document.querySelector<HTMLElement>("[role=alert]");
    if (
      element?.classList.contains("alert-global") ||
      element?.classList.contains("global-alert")
    ) {
      element?.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  }, 100);
};

export const NameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();
  let value = "";
  if (e.target.value.startsWith(" ")) {
    value = e.target.value.replace(" ", "");
  } else {
    value = e.target.value.replace(/(?!^)\s+/g, " ").replace(/[^A-Za-z ]/g, "");
  }
  e.target.value = value;
};

export const RemoveSpace = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();
  if (e.target.value.startsWith(" ")) {
    e.target.value = e.target.value.replace(" ", "");
  }
};

export const pincodecheck = (
  e: React.ChangeEvent<HTMLInputElement>,
  key: string
) => {
  let input = e.target as HTMLInputElement;
  let value = input.value;

  // Remove all existing hyphens
  let unformattedValue = value.replace(/-/g, "");
  let cursorPos = input.selectionStart || 0;

  // Reformat the value to include a hyphen if length exceeds 5
  if (unformattedValue.length > 5) {
    value = unformattedValue.slice(0, 5) + "-" + unformattedValue.slice(5);
  } else {
    value = unformattedValue;
  }

  input.value = value;

  // Before the hyphen
  if (cursorPos < 6) {
    input.selectionStart = cursorPos;
    input.selectionEnd = cursorPos;
  } else if (cursorPos === 6) {
    // If its on 6th position ie: 12345-6|
    // Move 1 step ahead
    input.selectionStart = cursorPos + 1;
    input.selectionEnd = cursorPos + 1;
  }

  // If its on 6th position and Deleting ie: 12345-6|
  // If deleting dont move the cursor, keep it as is
  if (key === "Backspace" && cursorPos === 6) {
    input.selectionStart = cursorPos;
    input.selectionEnd = cursorPos;
  }
};

export const phoneCheck = (e: any) => {
  let value = e.target.value;
  value = value.replace(/-/g, "");
  if (value.length > 3) {
    value = value.slice(0, 3) + "-" + value.slice(3);
  }
  if (value.length > 7) {
    value = value.slice(0, 7) + "-" + value.slice(7);
  }
  e.target.value = value;
};

export const ssnOncheck = (v: any) => {
  let value = v;
  value = value.replace(/-/g, "");
  if (value.length > 3) {
    value = value.slice(0, 3) + "-" + value.slice(3);
  }
  if (value.length > 6) {
    value = value.slice(0, 6) + "-" + value.slice(6);
  }
  return value;
};

export const pdfConverter = (data: string, name?: string) => {
  // converting the base64 resposne to pdf
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: "application/pdf",
    endings: "native",
  });
  const pdfUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = pdfUrl;
  if (name) {
    link.download = `${name}.pdf`;
  } else {
    link.target = "_blank";
  }

  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
};

export const isUnAuthorizedUser = (
  employerMasterDetailsResult: any // EmployerMasterDetailsResult
) => {
  function hasValueFour(relationships: Relationship[]) {
    return relationships.some(
      (relationship: Relationship) =>
        relationship.relationshipType === EMPLOYER_RELATIONSHIP_CODE
    );
  }

  // if (
  //   !employerMasterDetailsResult.lobs ||
  //   !employerMasterDetailsResult.lobs.length
  // ) {
  //   return true;
  // }

  if (!hasValueFour(employerMasterDetailsResult.relationships)) {
    return true;
  }

  return false;
};

export const maskConfirmationNumber = (word: string) => {
  if (word.length <= 4) {
    return word;
  } else {
    const masked =
      word.substring(0, word.length - 4).replace(/[a-z\d]/gi, "X") +
      word.substring(word.length - 4, word.length);
    return masked;
  }
};

export const validateEmail = (email: string) => {
  return EmailRegex.test(email);
};
