import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { hasAuthParams } from "react-oidc-context";

import { useAuth, useGlobalState, useOidcAuth } from "providers";
import Sorc from "../auth/Sorc";

export const Login = () => {
  const { setPageLoader } = useGlobalState();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  const {
    user: userOIDC,
    signinRedirect,
    isAuthenticated,
    activeNavigator,
    isLoading,
  } = useOidcAuth();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userOIDC && !user) {
      setPageLoader("userLoginInprogress", false);
      const redirectTo = sessionStorage.getItem("redirectTo");
      navigate(redirectTo ?? "/app");
    }
  }, [userOIDC]);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading &&
      !hasTriedSignin
    ) {
      setPageLoader("userLoginInprogress", true);
      signinRedirect();
      setHasTriedSignin(true);
    }
  }, [isAuthenticated, activeNavigator, isLoading, hasTriedSignin]);
  
  return (
    <div>
      {!user?.isUnAuthorizedUser && (
        <div>
          {userOIDC && user?.loginID && !user?.isSORCurrent && <Sorc />}
        </div>
      )}
    </div>
  );
};
