import { Headings } from "@sentaraui/optimahealth_web";
import { useTranslation } from 'react-i18next';

export default function EnrollmentHistoryHeader() {
  const { t } = useTranslation();
  return (
    <div>
      <Headings
        className="fw-600 mb-4"
        level={1}
        text={t("enrollmentHistoryForm.header")}
        dataTestId="txtMemberEligibilityPageHeading"
      />
    </div>
  )
}
