import { useTranslation } from "react-i18next";

const PayMonthlyInvoiceNotes = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column gap-3 p-3 border border-radius10 mb-4 light-grey-bg">
      {t("payMonthlyInvoice.importantNotes")}
      <ul className="list-disc d-flex flex-column gap-2 m-0 ps-4">
        <li>{t("payMonthlyInvoice.note1")}</li>
        <li>{t("payMonthlyInvoice.note2")}</li>
        <li>{t("payMonthlyInvoice.note3")}</li>
        <li>{t("payMonthlyInvoice.note4")}</li>
        <li>{t("payMonthlyInvoice.note5")}</li>
        <li>{t("payMonthlyInvoice.note6")}</li>
      </ul>
    </div>
  );
};
export default PayMonthlyInvoiceNotes;
