import { IdRequestType } from "utils/models/CommonTypes";

export const getKey = (_requsted: IdRequestType) => {
  let key = "";
  if (
    _requsted.success.length === 1 &&
    !_requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_8";
  } else if (
    !_requsted.success.length &&
    _requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_2";
  } else if (
    !_requsted.success.length &&
    !_requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_3";
  } else if (
    _requsted.success.length === 1 &&
    _requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_4";
  } else if (
    !_requsted.success.length &&
    _requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_6";
  } else if (
    _requsted.success.length === 1 &&
    _requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_7";
  } else if (
    _requsted.success.length > 1 &&
    !_requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_9";
  } else if (
    _requsted.success.length > 1 &&
    !_requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_10";
  } else if (
    _requsted.success.length > 1 &&
    _requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_11";
  } else if (
    _requsted.success.length > 1 &&
    _requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_12";
  } else if (
    _requsted.success.length === 1 &&
    !_requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_13";
  }
  return key;
};
