export const ProfileRegex = /\b(\w)/g;
export const PolicyRegex = /^[0-9*]+$/;
export const NameRegex = /[^A-Za-z ]/g;
export const SSNRegex = /^\d{3}-\d{2}-\d{4}$/;
export const SubscriberIDRegex = /^[0-9*]+$/;
export const EmailRegex = /^[\w.-]+@([\w-]+\.)+[a-zA-Z-]{1,10}$/;
export const PhoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
export const AlphaCommaRegex = /^[a-zA-Z, ]+$/;
export const AlphaNumeriAsterisks = /[^0-9a-zA-Z*]/g;
export const AlphaNumeric = /[^a-zA-Z0-9]/g;
export const NumericAsterisks = /[^0-9*]/g;
export const NumericHyphen = /[^0-9-]/g;
export const Numeric = /\D/g;
export const AlphaNumericComma = /[^a-zA-Z,]/g;
export const AlphaUnderscore = /[^A-Za-z_]/g;
export const AlphaNumericSpace = /[^a-zA-Z0-9\s]/g;
export const Zipcode = /^\d{5}(-\d{4})?$/;
export const NumbersWithCharacters = /[^0-9!@#$%^&*()]/g;
export const NumbersWithDot = /[^0-9.]/g;
export const AcceptNumericNine = /^\d{9}$/;
export const AcceptNumeric = /^\d+$/;
