import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../../organisms/layouts";
import SignOut from "pages/signOut/SignOut";
import { LOGIN, LOGOUT } from "routes/RouteURIs";
import { Login } from "pages/login/Login";

export const NonSecuredRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={LOGIN} element={<Login />} />
        <Route path={LOGOUT} element={<SignOut />} />
        <Route path="*" element={<Login />} />
      </Route>
      <Route path={LOGOUT} element={<SignOut />} />
    </Routes>
  );
};
