import { t } from "i18next";
import { InputField, AccordionTable } from "@sentaraui/optimahealth_web";
import { EmployerGroupInvoiceItem, PayMonthlyInvoiceTableProps } from "./interface";
import { usePayMonthlyInvoiceTable } from "./hooks/usePayMonthlyInvoiceTable";
import { useGlobalState } from "providers";
import { DeviceTypes } from "utils/constants/Constants";

const PayMonthlyInvoiceTable = (props: PayMonthlyInvoiceTableProps) => {
  const {
    handleCheckboxChange,
    isSelectAllChecked,
    selectedRecords,
    totalDueAmount,
    totalOutStandingBalance,
  } = usePayMonthlyInvoiceTable(props);

  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <>
      <div className="table-responsive">
        {!isMobile && (
          <table
            className="table table-custom-style align-middle mb-0"
            data-testid="tblPayMonthlyInvoice"
          >
            <thead>
              <tr>
                <th scope="col">{t("payMonthlyInvoice.select")}</th>
                <th scope="col">{t("payMonthlyInvoice.paymentMonth")}</th>
                <th scope="col">{t("payMonthlyInvoice.groupNumber")}</th>
                <th scope="col">{t("payMonthlyInvoice.groupName")}</th>
                <th scope="col">{t("payMonthlyInvoice.invoiceNumber")}</th>
                <th scope="col">{t("payMonthlyInvoice.totalDue")}</th>
                <th scope="col">{t("payMonthlyInvoice.outstandingBalance")}</th>
              </tr>
            </thead>
            <tbody>
              {props.empGroupARDetailResponseData?.map(
                (
                  employerGroupARDetailResponseItem: EmployerGroupInvoiceItem,
                  indx
                ) => (
                  <tr key={employerGroupARDetailResponseItem.sequenceNo + indx}>
                    <td className="checkbox-only text-center">
                      <InputField
                        id={`chkOrderMemberIdCardTable-${indx}`}
                        className="form-check-input mt-0 p-0"
                        type="checkbox"
                        disabled={
                          !employerGroupARDetailResponseItem.isInvoicePayable || isSelectAllChecked ||
                            selectedRecords.length ===
                            props.empGroupARDetailResponseData?.length
                            ? true
                            : false
                        }
                        data-testid={`chkPayMonthlyInvoiceTableRowSearchData-${indx}`}
                        checked={
                          employerGroupARDetailResponseItem.isInvoicePayable && selectedRecords?.some(
                            (record: EmployerGroupInvoiceItem) =>
                              record.uniqueIndexNumber ===
                              employerGroupARDetailResponseItem.uniqueIndexNumber
                          )
                            ? true
                            : false
                        }
                        onChange={() =>
                          handleCheckboxChange(
                            employerGroupARDetailResponseItem
                          )
                        }
                      />
                    </td>
                    <td>{employerGroupARDetailResponseItem.invoiceDate}</td>
                    <td>{employerGroupARDetailResponseItem.groupID}</td>
                    <td>{employerGroupARDetailResponseItem.groupName}</td>
                    <td>{employerGroupARDetailResponseItem.invoice}</td>
                    <td>
                      {+employerGroupARDetailResponseItem.invoiceAmt < 0
                        ? `($${employerGroupARDetailResponseItem.invoiceAmt.replace("-", "")})`
                        : `$${employerGroupARDetailResponseItem.invoiceAmt}`}
                    </td>
                    <td>
                      {+employerGroupARDetailResponseItem.invoiceBal < 0
                        ? `($${employerGroupARDetailResponseItem.invoiceBal.replace("-", "")})`
                        : `$${employerGroupARDetailResponseItem.invoiceBal}`}
                    </td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>
                  <strong>{t("payMonthlyInvoice.totalDueAllGroups")}</strong>
                </td>
                <td>
                  <strong>${totalDueAmount.toFormat(2)}</strong>
                </td>
                <td>
                  <strong>${totalOutStandingBalance.toFormat(2)}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        )}

        {isMobile && (
          <div className="mb-4">
            <AccordionTable
              initialActiveIndex={0}
              id="enrollment_history_view_detail_table"
              items={props.empGroupARDetailResponseData?.map(
                (employerGroupARDetailResponseItem, indx) => ({
                  title: (
                    <div className="d-flex gap-3">
                      <InputField
                        id={`chkOrderMemberIdCardTable-${indx}`}
                        className="form-check-input mt-0 p-0"
                        type="checkbox"
                        disabled={
                          !employerGroupARDetailResponseItem.isInvoicePayable || isSelectAllChecked ||
                            selectedRecords.length ===
                            props.empGroupARDetailResponseData?.length
                            ? true
                            : false
                        }
                        data-testid={`chkPayMonthlyInvoiceTableRowSearchData-${indx}`}
                        checked={
                          employerGroupARDetailResponseItem.isInvoicePayable && selectedRecords?.some(
                            (record: EmployerGroupInvoiceItem) =>
                              record.uniqueIndexNumber ===
                              employerGroupARDetailResponseItem.uniqueIndexNumber
                          )
                            ? true
                            : false
                        }
                        onChange={() =>
                          handleCheckboxChange(
                            employerGroupARDetailResponseItem
                          )
                        }
                      />
                      <div>
                        <div>{t("payMonthlyInvoice.paymentMonth")}</div>
                        <div className="redhat_regular">
                          {employerGroupARDetailResponseItem.invoiceDate ?
                            employerGroupARDetailResponseItem.invoiceDate : t("payMonthlyInvoice.noOpenInv")
                          }
                        </div>
                      </div>
                    </div>
                  ),
                  id: "enrollment_history_view_detail_table_data",
                  content: (
                    <div className="ms-4 ps-3">
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.groupNumber")}</strong></div>
                        <div>{employerGroupARDetailResponseItem.groupID}</div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.groupName")}</strong></div>
                        <div>{employerGroupARDetailResponseItem.groupName}</div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.invoiceNumber")}</strong></div>
                        <div>{employerGroupARDetailResponseItem.invoice}</div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.totalDue")}</strong></div>
                        <div>
                          {+employerGroupARDetailResponseItem.invoiceAmt < 0
                            ? `($${employerGroupARDetailResponseItem.invoiceAmt.replace("-", "")})`
                            : `$${employerGroupARDetailResponseItem.invoiceAmt}`}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div><strong>{t("payMonthlyInvoice.outstandingBalance")}</strong></div>
                        <div>
                          {+employerGroupARDetailResponseItem.invoiceBal < 0
                            ? `($${employerGroupARDetailResponseItem.invoiceBal.replace("-", "")})`
                            : `$${employerGroupARDetailResponseItem.invoiceBal}`}
                        </div>
                      </div>
                    </div>
                  ),
                })
              )}
            />
            <div className="d-flex flex-column gap-3 pt-4">
              <div className="font-eighteen"><strong>{t("payMonthlyInvoice.totalDueAllGroups")}</strong></div>
              <div>
                <div><strong>{t("payMonthlyInvoice.totalDue")}</strong></div>
                <div>${totalDueAmount.toFormat(2)}</div>
              </div>

              <div>
                <div><strong>{t("payMonthlyInvoice.outstandingBalance")}</strong></div>
                <div>${totalOutStandingBalance.toFormat(2)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PayMonthlyInvoiceTable;
