import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  UserIconWhite,
  DropdownButtonLink,
} from "@sentaraui/optimahealth_web";

import { toPascalCase } from "../../utils/helpers/TextFormaters";
import { useAuth, useBranding } from "../../providers";

// Define the Logout component
export const ProfileMenu = () => {
  // Initialize translation function
  const { t } = useTranslation();
  const { brandConfig } = useBranding();
  const navigate = useNavigate();

  const { user } = useAuth();
  // Convert the user's name to PascalCase
  const capitalizedText = toPascalCase(user?.person?.firstName ?? "");

  return (
    <div className="d-xl-block d-lg-block d-md-block pos-rel">
      <div className="d-flex align-items-center">
        <DropdownButtonLink
          buttonlabel={capitalizedText}
          dataTestId="profile-dropdown-button"
          dropdownlistitems={[]}
          icon={<UserIconWhite />}
        >
          <>
            {Object.keys(brandConfig.profileMenuLinks).map((key: string) => {
              const item = brandConfig.profileMenuLinks[key];
              return (
                <li key={key}>
                  <NavLink
                    key={key}
                    to={item.to}
                    className="dropdown-item text-decoration-none"
                    data-testid={item.id}
                  >
                    <strong>{t(item.title)}</strong>
                  </NavLink>
                </li>
              );
            })}
            <li>
              {/* Button to trigger the signOutHandler function */}
              <Button
                data-testid={"btnSignOut"}
                className={"primary-color dropdown-item"}
                onClick={() => {
                  navigate("/logout");
                }}
              >
                {t("logout")}
              </Button>
            </li>
          </>
        </DropdownButtonLink>
      </div>
    </div>
  );
};
