import React, { useEffect, useState, useRef } from "react";
import BigNumber from "bignumber.js";
import {
  EmployerPaymentConfirmationEmailRequest,
  useEmployerPaymentConfirmationEmail,
} from "@sentara/sentara-api-hooks-core";
import { PaymentProps } from "../interface";
import { useAuth } from "providers";
import { validateEmail } from "utils/helpers/HelperFn";
import { useTranslation } from "react-i18next";

export const usePayMonthlyInvoiceConfirmation = ({
  componentProps,
  selectedRecordsTopLevel,
  paymentConfirmationNumber,
}: PaymentProps) => {
  const { user } = useAuth();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [emailId, setEmailId] = useState<string>("");
  const [updatedEmailId, setUpdatedEmailId] = useState<string>("");
  const [emailToSend, setEmailToSend] = useState<string>("");
  const [isValiditateEmailOnChange, setIsValiditateEmailOnChange] =
    useState<boolean>(false);

  const [totalEtfDraftAmount, setTotalEtfDraftAmount] = useState("0.00");
  const [emailConfirmationPopup, setEmailConfirmationPopup] = useState(false);
  const { getEmployerPaymentConfirmationEmail } =
    useEmployerPaymentConfirmationEmail();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEmailId(user?.emailAddresses?.[0]?.emailAddress ?? "");
  }, [user]);

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email: string = event.target.value.trim();
    setEmailToSend(email);

    if (isValiditateEmailOnChange) {
      if (!email) {
        handleEmailValidationFailure(`${t("payMonthlyInvoice.requiredEmail")}`);
        return;
      }

      if (email.length < 5) {
        handleEmailValidationFailure(
          email.length <= 1
            ? `${t("payMonthlyInvoice.emailOneLength", { value: email.length })}`
            : `${t("payMonthlyInvoice.emailMinLength", { value: email.length })}`
        );
        return;
      }

      if (!validateEmail(email)) {
        handleEmailValidationFailure(
          `${t("payMonthlyInvoice.validationEmail")}`
        );
        return;
      }

      handleEmailValidationSuccess();
    } else {
      // Reset errors if email validation is not enabled
      setShowError(false);
      setErrorMessage("");
      setBtnDisabled(!email);
    }
  };

  const handleEmailValidationFailure = (errorMessage: string) => {
    setShowError(true);
    setErrorMessage(errorMessage);
    setBtnDisabled(true);
  };

  const handleEmailValidationSuccess = () => {
    setShowError(false);
    setErrorMessage("");
    setBtnDisabled(false);
  };

  const handleSendEmailManually = () => {
    if (!emailToSend || emailToSend.length < 5 || !validateEmail(emailToSend)) {
      handleEmailValidationFailure(
        !emailToSend
          ? `${t("payMonthlyInvoice.requiredEmail")}`
          : emailToSend.length === 1
            ? `${t("payMonthlyInvoice.emailOneLength", { value: emailToSend.length })}`
            : emailToSend.length < 5
              ? `${t("payMonthlyInvoice.emailMinLength", { value: emailToSend.length })}`
              : `${t("payMonthlyInvoice.validationEmail")}`
      );
      setIsValiditateEmailOnChange(true);
      return;
    }

    setShowError(false);
    setErrorMessage("");

    const emailData: EmployerPaymentConfirmationEmailRequest = {
      accountNumber: componentProps.accountNumber,
      confirmationNumber: paymentConfirmationNumber,
      totalAmount: "$" + componentProps.eftAmmount,
      emailTo: emailToSend,
    };

    getEmployerPaymentConfirmationEmail(emailData)
      .then((responseData) => {
        if (responseData?.data?.status) {
          setUpdatedEmailId(emailToSend);
          setEmailConfirmationPopup(true);
        } else {
          console.error("Failed to send email:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handlePopupClose = () => {
    setEmailConfirmationPopup(false);
    if (inputRef.current) {
      inputRef.current.value = "";
      setEmailToSend("");
      setBtnDisabled(true);
    }
  };

  const getLoggedInUsersEmailId = (): string => {
    const _emailId: string =
      updatedEmailId || (user?.emailAddresses?.[0]?.emailAddress ?? "");

    return _emailId;
  };

  const sendEmailOnLoad = () => {
    let _emailToSend = emailId || user?.emailAddresses?.[0]?.emailAddress || "";

    const emailData: EmployerPaymentConfirmationEmailRequest = {
      accountNumber: componentProps.accountNumber,
      confirmationNumber: paymentConfirmationNumber,
      totalAmount: "$" + componentProps.eftAmmount,
      emailTo: _emailToSend,
    };

    getEmployerPaymentConfirmationEmail(emailData);
  };

  useEffect(() => {
    let totalEtfDraftAmount = new BigNumber(0);

    selectedRecordsTopLevel?.map((rowData) => {
      const etfDraftAmount = new BigNumber(rowData.etfDraftAmount || 0);
      totalEtfDraftAmount = totalEtfDraftAmount.plus(etfDraftAmount);
    });

    setTotalEtfDraftAmount(totalEtfDraftAmount.toFixed(2));
  }, []);

  return {
    showError,
    errorMessage,
    btnDisabled,
    emailId,
    updatedEmailId,
    totalEtfDraftAmount,
    onChangeEmail,
    handleSendEmailManually,
    sendEmailOnLoad,
    emailConfirmationPopup,
    handlePopupClose,
    getLoggedInUsersEmailId,
    setUpdatedEmailId,
    setEmailId,
    emailToSend,
    setIsValiditateEmailOnChange,
    setErrorMessage,
    setEmailToSend,
    inputRef,
  };
};
