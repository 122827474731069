import {
  Headings,
  SecondaryButton,
  AccordionTable,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { useNavigate } from "react-router";
import { ENROLLMENT_HISTORY } from "routes";
import EnrollmentHistoryHeader from "./EnrollmentHistoryHeader";
import { useGlobalState } from "providers";
import { DeviceTypes } from "utils/models/CommonTypes";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatISODateToMDY } from "utils/helpers/DateFormate";

const EnrollmentHistoryInfo = () => {
  const navigate = useNavigate();
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  const location = useLocation();
  const [enrollmentHistoryDetails, setEnrollmentHistoryDetails] = useState<any>(
    []
  );
  const processDetails = () => {
    const data = location.state.data;

    if (data) {
      const groupedData = data.reduce((acc: any, item: any) => {
        const groupId = item.groupId;
        if (!acc[groupId]) {
          acc[groupId] = {
            groupid: groupId,
            data: [],
          };
        }
        acc[groupId].data.push(item);
        return acc;
      }, {});

      const result = Object.values(groupedData);
      setEnrollmentHistoryDetails(result);
    }
  };

  useEffect(() => {
    processDetails();
  }, []);

  const newSearchBtn = (
    <SecondaryButton
      data-testid="DetailsNewSearchButton"
      className={"secondary_btn btn_block_mob"}
      onClick={() => navigate(`/app/${ENROLLMENT_HISTORY}`)}
    >
      {t("enrollmentHistoryForm.btnNewSearch")}
    </SecondaryButton>
  );

  return (
    <div>
      <EnrollmentHistoryHeader />
      <Headings
        className="redhat_semibold font-twentyfour black-color mb-3"
        level={2}
        text={t("enrollmentHistoryForm.viewDetails")}
        dataTestId="txtEnrollmentHistoryMemberInfoTableTitle"
      />
      {!isMobile && (
        <>
          {enrollmentHistoryDetails.map((item: any, index: number) => (
            <>
              {" "}
              <div
                key={index + item.groupid}
                className="border p-3 border-radius10 light-grey-bg mb-4"
                data-testid="enrollmentHistoryDetails​"
              >
                <strong>{t("enrollmentHistoryForm.groupId")}:</strong>{" "}
                <span>{item.groupid}</span>
              </div>
              <table
                className="table table-custom-style black-color mb-4"
                data-testid="tblViewDetailsTable"
              >
                <thead>
                  <tr>
                    <th>{t("enrollmentHistoryForm.memberName")}</th>
                    <th>{t("enrollmentHistoryForm.memberId")}</th>
                    <th>{t("enrollmentHistoryForm.effectiveDate")}</th>
                    <th>{t("enrollmentHistoryForm.terminationDate")}</th>
                  </tr>
                </thead>
                <tbody>
                  {item.data.map((enrollHistoryMember: any, index: number) => (
                    <tr key={enrollHistoryMember.memberId + index}>
                      <td>{`${enrollHistoryMember.firstName} ${enrollHistoryMember.lastName}`}</td>
                      <td>{enrollHistoryMember.memberId}</td>
                      <td>
                        {formatISODateToMDY(
                          enrollHistoryMember.coverageBeginDate
                        )}
                      </td>
                      <td>
                        {formatISODateToMDY(
                          enrollHistoryMember.coverageEndDate
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ))}
        </>
      )}

      {isMobile && (
        <div className="mb-4">
          {enrollmentHistoryDetails.map((item: any, index: number) => (
            <>
              <div
                key={index + item.groupid}
                className="border p-3 border-radius10 light-grey-bg mb-3 mt-4"
                data-testid="enrollmentHistoryDetails​"
              >
                <strong>{t("enrollmentHistoryForm.groupId")}:</strong>{" "}
                <span>{item.groupid}</span>
              </div>
              <AccordionTable
                initialActiveIndex={0}
                id="enrollment_history_view_detail_table"
                items={item.data?.map(
                  (enrollHistoryMember: any, index: any) => ({
                    title: (
                      <div>{`${enrollHistoryMember.firstName} ${enrollHistoryMember.lastName}`}</div>
                    ),
                    id: "enrollment_history_view_detail_table_data",
                    content: (
                      <>
                        <div className="mb-3">
                          <div>{t("enrollmentHistoryForm.memberId")}</div>
                          <div>{enrollHistoryMember.memberId}</div>
                        </div>
                        <div className="mb-3">
                          <div>{t("enrollmentHistoryForm.effectiveDate")}</div>
                          <div>
                            {formatISODateToMDY(
                              enrollHistoryMember.coverageBeginDate
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div>
                            {t("enrollmentHistoryForm.terminationDate")}
                          </div>
                          <div>
                            {formatISODateToMDY(
                              enrollHistoryMember.coverageEndDate
                            )}
                          </div>
                        </div>
                      </>
                    ),
                  })
                )}
              />
            </>
          ))}
        </div>
      )}
      <div className="mb-4">{newSearchBtn}</div>
    </div>
  );
};
export default EnrollmentHistoryInfo;
