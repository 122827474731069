import { t } from "i18next";
import parse from "html-react-parser";

import {
  Headings,
  PrimaryButton,
  ModalOverlay,
} from "@sentaraui/optimahealth_web";
import React from "react";

export const ModalOverlayPopUp = ({
  title,
  content,
  open,
  displayButton,
  contentStack,
  callBack,
}: {
  title?: string;
  content?: string;
  open: boolean;
  displayButton?: boolean;
  contentStack?: any;
  callBack: (v: boolean, type: string) => void;
}) => {
  return (
    <ModalOverlay
      onClose={() => callBack(false, "close")}
      isOpen={open}
      overlayInfo="Modal information"
      className="modal-w-medium"
    >
      <Headings
        className="redhat_medium font-twenty fw-500 mb-2 me-4"
        level={3}
        text={title}
      />
      {contentStack && contentStack.length > 0 && (
        <>
          <div className="mb-3">{contentStack[0].content}</div>
          {contentStack[0].memberId.map((item: any) => {
            return (
              <div key={item.code} className="mb-3">
                <strong>{item.id}</strong> -
                {t("requestMemberIDCard.request.confirmationNo")} {item.code}
              </div>
            );
          })}
        </>
      )}
      <p className="mb-4" data-testid="sessionModalDescription">
        {parse(content ?? "")}
      </p>
      {displayButton ? (
        <div className="text-center">
          <PrimaryButton
            data-testid={"modalOkButton"}
            className={"primary_btn btn_block_mob"}
            type="submit"
            onClick={() => callBack(false, "ok")}
          >
            {t("session.label.buttonOk")}
          </PrimaryButton>
        </div>
      ) : (
        ""
      )}
    </ModalOverlay>
  );
};
