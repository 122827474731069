import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useEmployerMasterDetails } from "@sentara/sentara-api-hooks-core";
import { useAuth, useGlobalState, useOidcAuth } from "providers";
import { isUnAuthorizedUser } from "utils/helpers/HelperFn";
import { EMPLOYER_DASHBOARD } from "routes";

interface Prop {
  children: React.ReactNode;
  auth?: boolean;
}

export const ProtectedRoute = (prop: Prop) => {
  const { getEmployerMasterDetails } = useEmployerMasterDetails();
  const { setPageLoader } = useGlobalState();
  const { user, updateUser, updateToken, signOut } = useAuth();
  const { user: userODIC, removeUser } = useOidcAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const redirectToLogin = () => {
    const { pathname, search } = location;
    sessionStorage.setItem("redirectTo", `${pathname}${search}`);
    navigate("/");
  };

  useEffect(() => {
    const callApi = async (loginID: string) => {
      setPageLoader("brokerMasterDetails", true);
      const employerDetails = await getEmployerMasterDetails({ loginID });

      setPageLoader("brokerMasterDetails", false);

      if (employerDetails?.data) {
        const _isUnAuthorizedUser = isUnAuthorizedUser(employerDetails.data);
        console.log("_isUnAuthorizedUser", _isUnAuthorizedUser);
        updateUser({
          ...user,
          ...employerDetails.data,
          isUnAuthorizedUser: _isUnAuthorizedUser,
        });

        if (_isUnAuthorizedUser) {
          navigate("/logout");
        } else if (!employerDetails?.data?.isSORCurrent) {
          redirectToLogin();
        }
      } else {
        updateUser({
          ...user,
          isUnAuthorizedUser: true,
        });
        navigate("/logout");
      }
    };

    if (user?.loginID && !user.person && !user.isUnAuthorizedUser) {
      callApi(user?.loginID);
    }
  }, [user]);

  useEffect(() => {
    if (userODIC?.access_token && userODIC?.profile?.upn && !user?.loginID) {
      updateUser({
        ...{
          loginID: (userODIC?.profile?.upn as string).toString().split("@")[0],
          userDetailsFromOidc: userODIC,
        },
      });
      updateToken(userODIC?.access_token);
    } else if (userODIC?.id_token && !user) {
      removeUser();
      signOut("/");
    }
  }, [userODIC]);

  useEffect(() => {
    if (location) {
      let { pathname } = location;
      pathname = sessionStorage.getItem("redirectTo") ?? pathname;
      sessionStorage.removeItem("redirectTo");

      if (pathname === "/app" || pathname === "/app/") {
        navigate(`/app/${EMPLOYER_DASHBOARD}`);
      }
    }
  }, [location]);

  useEffect(() => {
    if (userODIC !== undefined || (!user && userODIC === undefined)) {
      if (!userODIC && !user?.isSORCurrent) {
        redirectToLogin();
      }
    }
  }, [userODIC]);

  return <>{prop.children}</>;
};
