import { useEffect } from "react";
import { t } from "i18next";
import { AccordionFAQ, Link } from "@sentaraui/optimahealth_web";
import { duoSecurity } from "utils/constants/Navigation";
import { DEVICE_MANAGEMENT, DEVICE_MANAGEMENT_PORTAL } from "routes";
import DeviceManagementSupportInfo from "../DeviceManagementSupportInfo";
import { useGlobalState } from "providers";

export const Faq = () => {
    const { setDmTitle } = useGlobalState();
    useEffect(() => {
        document.title = t('pageTitleADA.deviceManagementFAQ');
        setDmTitle(t('deviceManagement.faq.faqHeading'));
    }, [setDmTitle]);

    const question1AnswerContent = (
        <div className="dm-ques1-ans-fontweight">
            <div>{t('deviceManagement.faq.question1Answer')}</div>
            <DeviceManagementSupportInfo />
            <div>{t('deviceManagement.faq.question1Answer2')}</div>
        </div>
    );
    const question8AnswerContent = (
        <div>
            {t('deviceManagement.faq.question8Answer')}{" "}
            <Link to={duoSecurity} className="text-decoration-underline" target="_blank" rel="noopener" aria-label={duoSecurity}>
                {t('deviceManagement.faq.question8Answer2')}</Link>{" "}
            {t('deviceManagement.faq.question8Answer3')}
        </div>
    );
    const question10AnswerContent = (
        <div>
            <div className="pb-3">{t('deviceManagement.faq.question10Answer')}</div>
            <div className="text-danger">
                <span className="text-decoration-underline">{t('deviceManagement.faq.question10Answer2')}</span>{" "}
                {t('deviceManagement.faq.question10Answer3')}
            </div>
        </div>
    );
    const question11AnswerContent = (
        <div className="d-flex flex-column gap-3">
            <div>{t('deviceManagement.faq.question11Answer')}</div>
            <ul className="d-flex flex-column gap-3 ps-4">
                <li>{t('deviceManagement.faq.question11Answer2')}</li>
                <li>{t('deviceManagement.faq.question11Answer3')}</li>
                <li>{t('deviceManagement.faq.question11Answer4')}</li>
                <li>{t('deviceManagement.faq.question11Answer5')}</li>
            </ul>
            <div>{t('deviceManagement.faq.question11Answer6')}</div>
        </div>
    );
    const question12AnswerContent = (
        <div>
            {t('deviceManagement.faq.question12Answer')}{" "}
            <Link data-testid="lnkDeviceManagement" className="text-decoration-underline" to={`/${DEVICE_MANAGEMENT_PORTAL}/${DEVICE_MANAGEMENT}`}>
                {t('deviceManagement.faq.question12Answer2')}</Link>{" "}
            {t('deviceManagement.faq.question12Answer3')}
        </div>
    );
    const question16AnswerContent = (
        <div className="d-flex flex-column gap-3">
            <div>{t('deviceManagement.faq.question16Answer')}</div>
        </div>
    );

    const faqItems = [
        { id: 'device_manage_faq_1', question: t('deviceManagement.faq.question1'), answer: question1AnswerContent },
        { id: 'device_manage_faq_2', question: t('deviceManagement.faq.question2'), answer: t('deviceManagement.faq.question2Answer') },
        { id: 'device_manage_faq_3', question: t('deviceManagement.faq.question3'), answer: t('deviceManagement.faq.question3Answer') },
        { id: 'device_manage_faq_4', question: t('deviceManagement.faq.question4'), answer: t('deviceManagement.faq.question4Answer') },
        { id: 'device_manage_faq_5', question: t('deviceManagement.faq.question5'), answer: t('deviceManagement.faq.question5Answer') },
        { id: 'device_manage_faq_6', question: t('deviceManagement.faq.question6'), answer: t('deviceManagement.faq.question6Answer') },
        { id: 'device_manage_faq_7', question: t('deviceManagement.faq.question7'), answer: t('deviceManagement.faq.question7Answer') },
        { id: 'device_manage_faq_8', question: t('deviceManagement.faq.question8'), answer: question8AnswerContent },
        { id: 'device_manage_faq_9', question: t('deviceManagement.faq.question9'), answer: t('deviceManagement.faq.question9Answer') },
        { id: 'device_manage_faq_10', question: t('deviceManagement.faq.question10'), answer: question10AnswerContent },
        { id: 'device_manage_faq_11', question: t('deviceManagement.faq.question11'), answer: question11AnswerContent },
        { id: 'device_manage_faq_12', question: t('deviceManagement.faq.question12'), answer: question12AnswerContent },
        { id: 'device_manage_faq_13', question: t('deviceManagement.faq.question13'), answer: t('deviceManagement.faq.question13Answer') },
        { id: 'device_manage_faq_14', question: t('deviceManagement.faq.question14'), answer: t('deviceManagement.faq.question14Answer') },
        { id: 'device_manage_faq_15', question: t('deviceManagement.faq.question15'), answer: t('deviceManagement.faq.question15Answer') },
        { id: 'device_manage_faq_16', question: t('deviceManagement.faq.question16'), answer: question16AnswerContent },
        { id: 'device_manage_faq_17', question: t('deviceManagement.faq.question17'), answer: t('deviceManagement.faq.question17Answer') },
        // Add more FAQ items as needed
    ];

    return (
        <div>
            <AccordionFAQ
                id="acc_menu_one"
                items={faqItems.map(item => ({
                    id: item.id,
                    title: item.question,
                    content: <div className="d-flex flex-column gap-3 p-3 pt-0">{item.answer}</div>
                }))}
            />
        </div>
    )
}
export default Faq;