import { useEffect } from "react";
import { t } from "i18next";
import { Image } from "@sentaraui/optimahealth_web";
import { DEVICE_MANAGEMENT_IMAGE } from "utils/constants/Constants";
import { useBranding, useGlobalState } from "providers";
import ScrollToTop from "organisms/scrollTop/ScrollTop";

const WithInDuoTablet = () => {
  const WithInDuoTabletContent1 = (
    <div>
      {t("deviceManagement.enrollment.tabletContent3")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent18")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent19")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent20")}</strong>{" "}
    </div>
  );
  const WithInDuoTabletContent2 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent21")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent18")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent19")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent22")}</strong>{" "}
    </div>
  );
  const WithInDuoTabletList = [
    {
      id: 0,
      WithInDuoTabletContent: WithInDuoTabletContent1,
      WithInDuoTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image19,
      altText: t("deviceManagement.enrollment.signInImg"),
    },
    {
      id: 1,
      WithInDuoTabletContent: WithInDuoTabletContent2,
      WithInDuoTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image28,
      altText: t("deviceManagement.enrollment.startSetUpImg"),
    },
  ];
  return (
    <>
      <div>{t("deviceManagement.enrollment.smartPhoneContent16")}:</div>
      <ol className="list-alpha d-flex flex-column gap-3">
        {WithInDuoTabletList.map((item, index) => (
          <li key={item.id}>
            <div className="pb-1">{item.WithInDuoTabletContent}</div>
            {item.WithInDuoTabletImagePath && (
              <Image
                dataTestId={`imgWithInDuoTablet_${index}`}
                path={item.WithInDuoTabletImagePath}
                alttext={item.altText}
                className="img-fluid"
              />
            )}
          </li>
        ))}
      </ol>
    </>
  );
};

const AutoPushTablet = () => {
  const AutoPushTabletContent1 = (
    <div>{t("deviceManagement.enrollment.tabletContent6")}</div>
  );
  const AutoPushTabletContent2 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent34")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent35")}</strong>,{" "}
      {t("deviceManagement.enrollment.smartPhoneContent36")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent37")}</strong>.
    </div>
  );
  const AutoPushTabletContent3 = (
    <div>
      {t("deviceManagement.overview.content12")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent38")}</strong>
    </div>
  );
  const AutoPushTabletContent4 = (
    <div>
      {t("deviceManagement.overview.content12")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent39")}</strong>.
    </div>
  );
  const AutoPushTabletList = [
    {
      id: 0,
      AutoPushTabletContent: AutoPushTabletContent1,
      AutoPushTabletImagePath: "",
      altText: "",
    },
    {
      id: 1,
      AutoPushTabletContent: AutoPushTabletContent2,
      AutoPushTabletImagePath: "",
      altText: "",
    },
    {
      id: 2,
      AutoPushTabletContent: AutoPushTabletContent3,
      AutoPushTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image31,
      altText: t("deviceManagement.enrollment.startSetUpImg"),
    },
    {
      id: 3,
      AutoPushTabletContent: AutoPushTabletContent4,
      AutoPushTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image34,
      altText: t("deviceManagement.enrollment.startSetUpImg"),
    },
  ];
  return (
    <div className="d-flex flex-column gap-3">
      <div>
        <strong>{t("deviceManagement.enrollment.smartPhoneContent30")}</strong>{" "}
        {t("deviceManagement.enrollment.smartPhoneContent31")}{" "}
        <strong>{t("deviceManagement.enrollment.smartPhoneContent32")}</strong>.
      </div>
      <div className="text-danger">
        <span className="text-decoration-underline">
          {t("deviceManagement.faq.question10Answer2")}
        </span>{" "}
        {t("deviceManagement.faq.question10Answer3")}
      </div>
      <ol className="list-alpha d-flex flex-column gap-3 p-0">
        {AutoPushTabletList.map((item, index) => (
          <li key={item.id}>
            <div className="pb-1">{item.AutoPushTabletContent}</div>
            {item.AutoPushTabletImagePath && (
              <Image
                dataTestId={`imgAutoPushTablet_${index}`}
                path={item.AutoPushTabletImagePath}
                alttext={item.altText}
                className="img-fluid"
              />
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export const EnrollTablet = () => {
  const { setDmTitle } = useGlobalState();
  const { brandConfig } = useBranding();
  useEffect(() => {
    setDmTitle(t("deviceManagement.enrollment.enrollTabletHeading"));
  }, [setDmTitle]);

  const EnrollTabletContent1 = (
    <div>
      {t("deviceManagement.enrollment.tabletContent2")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent4")}</strong>.
    </div>
  );
  const EnrollTabletContent2 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent6")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent7")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent8")}
    </div>
  );
  const EnrollTabletContent3 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent9")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent10")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent11")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent12")}</strong>
    </div>
  );
  const EnrollTabletContent4 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent13")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent14")}</strong>.
    </div>
  );
  const EnrollTabletContent5 = <WithInDuoTablet />;
  const EnrollTabletContent6 = (
    <div>
      <div>
        <strong>{t("deviceManagement.enrollment.smartPhoneContent23")}</strong>{" "}
        {t("deviceManagement.enrollment.tabletContent4")}
      </div>
      <Image
        dataTestId={`imgEnrollTablet_s_four`}
        path={DEVICE_MANAGEMENT_IMAGE.image18}
        alttext={t("deviceManagement.enrollment.startSetUpImg")}
        className="img-fluid"
      />
    </div>
  );
  const EnrollTabletContent7 = (
    <div>
      {t("deviceManagement.enrollment.tabletContent5")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent26")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent27")}
    </div>
  );
  const EnrollTabletContent8 = (
    <div>
      <strong>{t("deviceManagement.enrollment.smartPhoneContent28")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent29")}
    </div>
  );
  const EnrollTabletContent9 = <AutoPushTablet />;
  const EnrollTabletContent10 = (
    <div>
      <span className="redhat_italic">
        {t("deviceManagement.enrollment.tabletContent7")}
      </span>
      , {t("deviceManagement.enrollment.smartPhoneContent41")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent39")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent42")}
    </div>
  );
  const EnrollTabletContent11 = (
    <div>{t("deviceManagement.enrollment.smartPhoneContent43")}</div>
  );
  const EnrollTabletContent12 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent44")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent45")}</strong>{" "}
      {t("deviceManagement.enrollment.tabletContent8")}
    </div>
  );
  const EnrollTabletContent13 = (
    <div>
      {t("deviceManagement.enrollment.smartPhoneContent47")}{" "}
      <strong>{t("deviceManagement.enrollment.smartPhoneContent48")}</strong>{" "}
      {t("deviceManagement.enrollment.smartPhoneContent49")}
    </div>
  );
  const EnrollTabletList = [
    {
      id: 0,
      enrollTabletContent: EnrollTabletContent1,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image30,
      altText: t("deviceManagement.enrollment.startSetUpImg"),
    },
    {
      id: 1,
      enrollTabletContent: EnrollTabletContent2,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image17,
      altText: t("deviceManagement.enrollment.typeOfDeviceImg"),
    },
    {
      id: 2,
      enrollTabletContent: EnrollTabletContent3,
      enrollTabletImagePath: "",
      altText: "",
    },
    {
      id: 3,
      enrollTabletContent: EnrollTabletContent4,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image17,
      altText: t("deviceManagement.enrollment.typeOfDeviceImg"),
    },
    {
      id: 4,
      enrollTabletContent: EnrollTabletContent5,
      enrollTabletImagePath: "",
      altText: "",
    },
    {
      id: 5,
      enrollTabletContent: EnrollTabletContent6,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image20,
      altText: t("deviceManagement.enrollment.typeOfDeviceImg"),
    },
    {
      id: 6,
      enrollTabletContent: EnrollTabletContent7,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image21,
      altText: t("deviceManagement.enrollment.typeOfDeviceImg"),
    },
    {
      id: 7,
      enrollTabletContent: EnrollTabletContent8,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image35,
      altText: t("deviceManagement.enrollment.typeOfDeviceImg"),
    },
    {
      id: 8,
      enrollTabletContent: EnrollTabletContent9,
      enrollTabletImagePath: "",
      altText: "",
    },
    {
      id: 9,
      enrollTabletContent: EnrollTabletContent10,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image24,
      altText: t("deviceManagement.enrollment.typeOfDeviceImg"),
    },
    {
      id: 10,
      enrollTabletContent: EnrollTabletContent11,
      enrollTabletImagePath: "",
      altText: "",
    },
    {
      id: 11,
      enrollTabletContent: EnrollTabletContent12,
      enrollTabletImagePath: DEVICE_MANAGEMENT_IMAGE.image33,
      altText: t("deviceManagement.overview.SendMePushImg"),
    },
    {
      id: 12,
      enrollTabletContent: EnrollTabletContent13,
      enrollTabletImagePath: brandConfig?.image?.DuoSentPushImg,
      altText: t("deviceManagement.overview.SentPushImg"),
    },
  ];
  return (
    <div>
      <ScrollToTop />
      <div className="font-eighteen mb-3">
        <strong>{t("deviceManagement.enrollment.tabletContent1")}</strong>
      </div>
      <ol className="d-flex flex-column gap-3">
        {EnrollTabletList.map((item, index) => (
          <li key={item.id}>
            <div className="pb-1">{item.enrollTabletContent}</div>
            {item.enrollTabletImagePath && (
              <Image
                dataTestId={`imgEnrollTablet_${index}`}
                path={item.enrollTabletImagePath}
                alttext={item.altText}
                className="img-fluid"
              />
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
export default EnrollTablet;
