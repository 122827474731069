export const LOGIN = "/";
export const LOGOUT = "/logout";
export const EMPLOYER_DASHBOARD = "employer-dashboard";
export const GROUP_INFORMATION = "group-information";
export const PAY_MONTHLY_INVOICE = "pay-monthly-invoice";
export const FORGOT_PASSWORD = "/forgot-password";
export const ENROLLMENT_HISTORY = "enrollment-history";
export const ENROLLMENT_HISTORY_DETAILS = "enrollment-history-details";
export const ENROLL_IN_BENEFITS = "enroll-in-benefits";
export const REQUEST_MEMBER_ID_CARD = "request-member-id-card";
export const PASSWORD_HELP = process.env.REACT_APP_PASSWORD_HELP_URL;
export const CHANGE_PASSWORD = "change-password";
export const CHANGE_PASSWORD_UPDATE = "change-password-update";
export const DEVICE_MANAGEMENT_PORTAL = "device-management-portal";
export const CONTACTUS = "contact-us";
export const CONTACTUS_SUCCESS = "contact-us-success";
export const GROUP_REPORTING = "group-reporting";
export const OVERVIEW = "overview";
export const ENROLLMENT = "enrollment";
export const DEVICE_MANAGEMENT = "";
export const DEVICE_MANAGEMENT_FAQ = "faq";
export const ENROLL_SMART_PHONE = "smart-phone";
export const ENROLL_TABLET = "tablet";
export const ENROLL_PHONE = "phone";
export const BILLING_STATEMENTS = "billing-statements";
