import SSONavigationContent from "organisms/ssoNavigationContent/SSONavigationContent";
import { t } from "i18next";

import { useEffect, useState } from "react";
import { useEmployerBillingStatement } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const EmployerBillingStatement = () => {
  const { user } = useAuth();
  const { getEmployerBillingStatement } = useEmployerBillingStatement();
  const [billingStatementSSO, setBillingStatementSSO] = useState("");

  useEffect(() => {
    document.title = t("pageTitleADA.billingStatements");
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID) {
      const response = await getEmployerBillingStatement({
        loginId: user?.loginID,
      });
      if (response?.data) {
        setBillingStatementSSO(response?.data?.Result?.ssoUrl);
      }
    }
  };

  return (
    <div id="billingStatementsContent">
      <SSONavigationContent
        header={t("sso.header.BillingStatements")}
        ssoUrl={billingStatementSSO}
        dataTestId="ssoLinkBillingStatements"
        targetName="employerPortalEmployerBillingStatement"
      />
    </div>
  );
};
export default EmployerBillingStatement;
