import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { t } from "i18next";
import { Loader, GlobalError, Link } from "@sentaraui/optimahealth_web";
import IdleTimeoutContainer from "organisms/IdleTimeout/IdleTimerContainer";
import { useGlobalState, useAuth } from "providers";
import { SideMenu } from "organisms/sidemenu";
import ScrollToTop from "organisms/scrollTop/ScrollTop";
import { AppHeader } from "organisms/appHeader/AppHeader";
import { DeviceTypes } from "utils/models/CommonTypes";
import { AppFooter } from "organisms/appFooter/AppFooter";
import { CloseIconBlock } from "../../organisms/icons";
import { EMPLOYER_DASHBOARD, PASSWORD_HELP } from "routes";

export const AppLayout = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { loader, deviceType } = useGlobalState();
  const location = useLocation();
  const { user } = useAuth();
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set focus on the main content element when the page load
    if (mainContentRef.current) {
      mainContentRef.current.focus();
    }
    if (location.pathname === `/app/${EMPLOYER_DASHBOARD}`) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [location.pathname]);

  return (
    <>
      {loader && <Loader />}
      <ScrollToTop />
      <div
        className="d-flex flex-column h-100 no-focus"
        ref={mainContentRef}
      >
        {/* Skip to Main Content link */}
        <a href="#main-content" className="skip-link" tabIndex={1}>
          {t("accessibility.message.skipToMainContent")}
        </a>
        <div>
          <AppHeader />
          {showAlert && (
            <div className="container-fluid mb-2 pt-2">
              <GlobalError dataTestId="selfServiceAlert">
                <div className="d-flex justify-content-end align-items-center global-error-link">
                  <div>
                    {t("groupInformation.alertcontent1")}{" "}
                    <Link
                      to={PASSWORD_HELP}
                      target="_blank"
                      className="text-decoration-underline redhat_regular"
                      data-testid="linkClickHere"
                      aria-label={t("nav.link.passwordReset")}
                    >
                      {t("groupInformation.linkHere")}
                    </Link>{" "}
                    {t("groupInformation.alertcontent2")}
                  </div>
                  <div className="ps-3">
                    <button
                      type="button"
                      data-testid="hamburgerBtnClose"
                      className="btn-link border-0 bg-transparent text-decoration-none"
                      onClick={() => setShowAlert(false)}
                      aria-label={t("close")}
                    >
                      <CloseIconBlock />
                    </button>
                  </div>
                </div>
              </GlobalError>
            </div>
          )}
          <main id="main-content" className="container-fluid">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-12 d-none d-lg-block mb-4 home_page"
                data-testid="appSidemenu"
              >
                {deviceType === DeviceTypes.DESKTOP && user?.person && (
                  <SideMenu />
                )}
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 col-12 mt-3">
                {user?.person && <Outlet />}
              </div>
            </div>
          </main>
        </div>
        <AppFooter />
      </div>
      <IdleTimeoutContainer></IdleTimeoutContainer>
    </>
  );
};
