import { ProfileRegex } from "../../utils/constants/Regex";

/* first letter interface */
interface FirstLetter {
  firstName: string;
  lastName: string;
}

export const ProfileFirstLetter = ({
  firstName = "",
  lastName = "",
}: FirstLetter) => {
  /* Detemine user first letter */
  const getUserFirstLetter = () => {
    const name = `${firstName} ${lastName}`;
    const matches = name.match(ProfileRegex);
    return matches?.join("") || "";
  };

  return (
    <>
      <div
        data-testid="profileCardInitials"
        className="d-flex rounded-circle mx-auto justify-content-center align-items-center profile-image-small profile-name-text"
      >
        {getUserFirstLetter()}
      </div>
    </>
  );
};
